<template>
  <div class="row admin-page officePage">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 pt-2">
      <button class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton" @click="handleNew">
        Add New Configuration
      </button>
      <div class="row filter">
        <fieldset name="filters" class="mapping__filters">
          <legend>Filter</legend>
          <div>
            <label for="mappings-name-filter">Name</label>
            <input v-model.trim="filters.SettingName" type="text" name="name-filter" id="mappings-name-filter"
              class="form-control">
          </div>
          <div>
            <input v-model="filters.ShowArchived" type="checkbox" name="active-filter" id="mappings-active-filter"
              class="checkbox-input generalCheckBox">
           <label for="mappings-active-filter">Show Archived</label>
          </div>
        </fieldset>

      </div>
      <div class="filter form-group row">
        <div class="list col-md-12">
          <div v-for="(item, index) in filteredMappings" :key="item.Id" @click="() => handleListSelection(item.Id)"
            class="office item row" :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]">
            <span>
              <i class="fa fa-cog" aria-hidden="true"></i>
              <span style="margin-left: 8px">{{ item.SettingName }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
      <UploadDataSettingsForm v-if="isEditing" :key="selectedMappingId" :currentMapping="selectedMapping"
        @save="handleSave" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import types from './types';
import UploadDataSettingsForm from './UploadDataSettingsForm.vue';
import utilitiesMixin from '@/mixins/utilitiesMixin';

export default {
  name: "UploadDataManagement",
  components: { UploadDataSettingsForm },
  mixins: [utilitiesMixin],
  data() {
    return {
      selectedMappingId: null,
      isEditing: false,
      filters: {
        SettingName: "",
        ShowArchived: false,
      }
    };
  },
  computed: {
    ...mapState({
      mappings: state => state.uploadDataSettings.mappings
    }),
    filteredMappings() {
      return this.mappings.filter(mapping => {
        if (mapping.IsActive || this.filters.ShowArchived) {
          return mapping.SettingName.toLowerCase().indexOf(this.filters.SettingName.toLowerCase()) !== -1
        }

        return false
      }).sort((a, b) => a.SettingName.localeCompare(b.SettingName));
    },
    selectedMapping() {
      return this.mappings.find(mapping => mapping.Id === this.selectedMappingId)
    }
  },
  async mounted() {
    if (this.mappings.length === 0) {
      const [err, result] = await this.$store.dispatch(
        types.GET_ALL_SETTINGS
      );

      if (err) {
        const status = err?.response?.status;

        if (status == 401) {
          this.$swal(
            "Warning",
            "You are not authorized to see data upload settings",
            "warning"
          );
        } 
        this.isLoading = false;
        return;
      }
    }
  },
  methods: {
    async handleNew() {
      this.isEditing = true;
      this.selectedMappingId = null
    },
    async handleSave(form) {
      if (!this.selectedMappingId) {
        await this.create(form)
      } else {
        this.edit()
      }
    },
    async edit() {
      this.$swal("Warning!", "Couldn't edit this setting", "warning")
    },
    async create(form) {
      const [err, result] = await this.$store.dispatch(types.ADD_DATA_UPLOAD_SETTING, form)

      if (err) {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      } else {
        this.$swal("Success!", "Data Upload Settings created successfully", "success")
        this.selectedMappingId = result.Data?.Id
      }
    },
    async handleListSelection(mappingId) {
      this.isEditing = true;
      this.selectedMappingId = mappingId;
    },
  },
}
</script>

<style scoped>
.mapping__filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>