<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'ASLegalProductReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @refreshReport="refreshReport"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredLegalProductReport"
        :paginated="isPaginated && filteredLegalProductReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Client ID"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(props.row.CustomerId, props.row.CustomerName)
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Address" label="Address" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AddressFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Address }}
          </template>
        </o-table-column>

        <o-table-column field="City" label="City" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CityFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.City }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column field="ZipCode" label="Zip Code" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ZipCodeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.ZipCode }}
          </template>
        </o-table-column>

        <o-table-column
          field="PhoneNumber"
          label="Phone Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PhoneNumberFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{
              props.row.PhoneNumber && !isNaN(props.row.PhoneNumber)
                ? tryFormatPhone(props.row.PhoneNumber)
                : ""
            }}
          </template>
        </o-table-column>

        <o-table-column field="Email" label="Email" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EmailFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Email }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="PreviousStatus"
          label="Previous Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PreviousStatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.PreviousStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentStatus"
          label="Current Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentStatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CurrentStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field="StatusUpdatedDate"
          label="Status Updated Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StatusUpdatedDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.StatusUpdatedDate }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatShortDate(props.row.StatusUpdatedDate) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDateInProduct"
          label="Enrolled Date in Product"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateInProductFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ formatShortDate(props.row.EnrolledDateInProduct) }}
          </template>
        </o-table-column>

        <o-table-column field="ViewNotes" label="View Notes" width="10">
          <template v-slot="props">
            <div class="text-center">
              <button
                class="btn btn-xs"
                style="background-color: #f7b84b"
                @click="openNotes(props.row.CustomerId, props.row.CreditorId)"
                v-if="!props.row.IsSummary"
              >
                <i class="ri-file-edit-line"></i>
              </button>
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredLegalProductReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "ASLegalProductReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      legalProductReportInitSummary: {
        CustomerId: "",
        CustomerName: "",
        Address: "",
        City: "",
        State: "",
        ZipCode: "",
        PhoneNumber: "",
        Email: "",
        PreviousStatus: "",
        CurrentStatus: "",
        StatusUpdatedDate: "",
        EnrolledDateInProduct: "",
        EnrolledDate: "",
      },

      CustomerIdFilter: "",
      CustomerNameFilter: "",
      AddressFilter: "",
      CityFilter: "",
      StateFilter: "",
      ZipCodeFilter: "",
      PhoneNumberFilter: "",
      EmailFilter: "",
      PreviousStatusFilter: "",
      CurrentStatusFilter: "",
      StatusUpdatedDateFilter: "",
      EnrolledDateInProductFilter: "",
      EnrolledDateFilter: "",
    };
  },
  computed: mapState({
    legalProductList: (state) => state.reports.legalProductList,
    filteredLegalProductReport() {
      let rtn = [];

      let tmpList =
        this.legalProductList.length > 0
          ? this.legalProductList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(item.Address, this.AddressFilter) &&
                this.filterString(item.City, this.CityFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.ZipCode, this.ZipCodeFilter) &&
                this.filterString(item.PhoneNumber, this.PhoneNumberFilter) &&
                this.filterString(item.Email, this.EmailFilter) &&
                this.filterString(
                  item.PreviousStatus,
                  this.PreviousStatusFilter
                ) &&
                this.filterString(
                  item.CurrentStatus,
                  this.CurrentStatusFilter
                ) &&
                new Date(item.StatusUpdatedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.StatusUpdatedDateFilter) > -1 &&
                new Date(item.EnrolledDateInProduct)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.EnrolledDateInProductFilter) > -1 &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.EnrolledDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.legalProductReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.legalProductReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.legalProductReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      userInfo != null &&
      userInfo.customerIdPrefix != null &&
      userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    openNotes(customerId, creditorId) {
      this.$Emitter.emit("viewNotePanel", {
        CustomerId: customerId,
        CreditorId: creditorId,
      });
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async refreshReport() {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_LEGAL_PRODUCT_REPORT);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
