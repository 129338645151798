<!--
 * @file EvvoPopup.vue
 * @description This component is created for displaying the form for creating an early loan request for EVVO provider.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 * 
 * @section Usage
 * This component can be used to display EVVO early loan request.
 *
 * @section Props
 * - `data` (Object): The Object containing all the customer related data.
 -->
<template>
  <LoanApplicationPopup
    @close="emit('close')"
    @submit="submit"
    title="Request Early Loan (EVVO)"
    :data="{ ...data, ...fieldValues, AgentEmail: agentEmail }"
    ref="loanPopup"
  >
    <template #otherFields>
      <template v-for="(_value, fieldKey) in fieldValues" :key="fieldKey">
        <div v-if="selectOptions[fieldKey]" class="col-md-4">
          <label class="generalLabel">{{ startCase(fieldKey) }}</label>
          <select class="form-control" v-model="fieldValues[fieldKey]">
            <option
              v-for="(value, key) in selectOptions[fieldKey]"
              :value="key"
              :key="key"
            >
              {{ value }}
            </option>
          </select>
        </div>
      </template>
      <div class="col-md-4">
        <label class="generalLabel">Agent Email</label>
        <input class="form-control" :value="agentEmail" disabled />
      </div>
    </template>
    <template #phoneNumber>
      <div class="col-md-4">
        <label class="generalLabel">Home Phone</label>
        <input
          class="form-control"
          v-model="fieldValues.HomePhone"
          v-myMask="{ mask: ['(999) 999-9999'] }"
          :disabled="data.HomePhone"
        />
      </div>
      <div class="col-md-4">
        <label class="generalLabel">Cell Phone</label>
        <input
          class="form-control"
          v-model="fieldValues.CellPhone"
          v-myMask="{ mask: ['(999) 999-9999'] }"
          :disabled="data.CellPhone"
        />
      </div>
    </template>
  </LoanApplicationPopup>
</template>

<script setup>
import placeholderTypes from "@/components/Placeholders/types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import clientTypes from "@/views/agent/EditClient/types";
import { startCase } from "lodash";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import { useStore } from "vuex";
import LoanApplicationPopup from "./LoanApplicationPopup.vue";
import dropdownOptions from "./dropdownOptions.json";

const { proxy } = getCurrentInstance();
const utilMixin = defineComponent(utilitiesMixin).methods;

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});

const selectOptions = { ...dropdownOptions.Evvo };

const emit = defineEmits(["close"]);

const submit = async (data) => {
  const LoanData = data.LoanData;
  let linkStr = "https://askevvofinancial.com/api/incoming-leads?";
  linkStr +=
    "firstname=" +
    LoanData.FirstName +
    "&lastname=" +
    LoanData.LastName +
    "&email=" +
    LoanData.Email;

  if (LoanData.HomePhone) {
    linkStr += "&home_phone=" + LoanData.HomePhone;
  }
  if (LoanData.CellPhone) {
    linkStr += "&cell_phone=" + LoanData.CellPhone;
  }

  linkStr +=
    "&dob=" +
    LoanData.DateOfBirth +
    "&address=" +
    LoanData.Address +
    "&ssn=" +
    LoanData.Ssn +
    "&city=" +
    LoanData.City +
    "&state=" +
    LoanData.State +
    "&zip=" +
    LoanData.Zip +
    "&country=United States" +
    "&loan_purpose=" +
    LoanData.LoanPurpose +
    "&loan_amount=" +
    LoanData.DebtAmount +
    "&provided_credit_rating=" +
    LoanData.CreditRating +
    "&employment_status=" +
    LoanData.EmploymentStatus +
    "&pay_frequency=" +
    LoanData.PayFrequency +
    "&annual_income=" +
    LoanData.AnnualIncome +
    "&education_level=" +
    LoanData.EducationLevel +
    "&property_status=" +
    LoanData.PropertyStatus +
    "&evvo_agent_email=" +
    LoanData.AgentEmail +
    "&source=Zenith CRM";

  let link = document.createElement("a");
  link.href = linkStr;
  link.target = "_blank";
  link.click();

  let [err, result] = await store.dispatch(clientTypes.SUBMIT_EARLY_LOAN, {
    Data: LoanData,
    EarlyLoanProvider: "EVVO",
    CampaignId: data.CampaignId,
  });

  if (result) {
    proxy.$swal("Success!", "Loan Application submitted", "success");
    emit("close");
  } else {
    let errMsg = utilMixin.getApiErrorMessage(err);
    proxy.$swal("Error!", errMsg, "error");
  }
};

const agentEmail = ref();

const getFirstKey = (options) => Object.keys(options)[0];

const fieldValues = ref({
  LoanPurpose: getFirstKey(selectOptions.LoanPurpose),
  CreditRating: getFirstKey(selectOptions.CreditRating),
  EmploymentStatus: getFirstKey(selectOptions.EmploymentStatus),
  PayFrequency: getFirstKey(selectOptions.PayFrequency),
  EducationLevel: getFirstKey(selectOptions.EducationLevel),
  PropertyStatus: getFirstKey(selectOptions.PropertyStatus),
  HomePhone: props.data.HomePhone,
  CellPhone: props.data.CellPhone,
});

const store = useStore();
const loanPopup = ref(null);
const userInfo = computed(() => JSON.parse(sessionStorage.getItem("userInfo")));

onMounted(async () => {
  let [err, result] = await store.dispatch(
    placeholderTypes.PROCESS_PLACEHOLDERS,
    {
      UserId: userInfo.value.userId,
      CustomerId: props.data.CustomerId,
      Text: "##AGENT_EMAIL##",
    }
  );

  if (result) {
    agentEmail.value = result.Data.Text;
  } else {
    let errMsg = utilMixin.getApiErrorMessage(err);
    proxy.$swal("Error!", errMsg, "error");
  }
});
</script>
