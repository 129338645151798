import axios from "axios";

const API_URL =
  process.env.VUE_APP_IS_DEVELOPMENT == "true"
    ? process.env.VUE_APP_API_DEV
    : process.env.VUE_APP_API;

const instance = axios.create({
  baseURL: "",
  timeout: 120000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.userInfo).token;

    if (token !== null && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
export default {
  getDosEquitiesReport: (data) => {
    return instance.post(`${API_URL}/api/DosEquitiesReport`, data);
  },

  getLegalProductReport: (data) => {
    return instance.post(`${API_URL}/api/LegalProductReport`, data);
  },

  getDataUploadSettingById: (data) => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "getDataUploadSettingById",
      Data: data,
    });
  },
  getDataUploadTemplateDetailById: (data) => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "getDataUploadTemplateDetailById",
      Data: { Id: data },
    });
  },
  getAllSettings: (data) => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "getAllSettings",
      Data: data,
    });
  },
  getAllSettingsWithoutDetails: (data) => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "nfindividualsalesdashboard",
      Data: data,
    });
  },
  addDataUploadSetting: (data) => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "addDataUploadSetting",
      Data: data,
    });
  },
  csvConfiguration: (data) => {
    return instance.post(`${API_URL}/api/Files`, {
      Type: "csvConfiguration",
      Data: data,
    });
  },
  getAllEntity: (data) => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "getAllEntity",
      Data: data,
    });
  },

  getScheduledClearedFeeReportController: (data) => {
    return instance.post(`${API_URL}/api/ScheduledClearedFeeReport`, data);
  },

  getPurchaseClientInformationReport: (data) => {
    return instance.post(
      `${API_URL}/api/PurchaseClientInformationReport`,
      data
    );
  },

  getGrtPurchasableCustomersReport: (data) => {
    return instance.post(`${API_URL}/api/GrtPurchasableCustomers`, data);
  },

  getVoidedSettlementReport: (data) => {
    return instance.post(`${API_URL}/api/VoidSettlementsReport`, data);
  },
  assignCustomerFromPhonePanel: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "assignCustomer",
      Data: data,
    });
  },
  getSettlementRequestReport: (data) => {
    return instance.post(`${API_URL}/api/SettlementRequestReport`, data);
  },

  getNegotiationAutomationSetting: (data) => {
    return instance.post(`${API_URL}/api/NegotiationTaskAutomationSetting`, {
      Type: "getsetting",
    });
  },
  insertNegotiationAutomationSetting: (data) => {
    return instance.post(`${API_URL}/api/NegotiationTaskAutomationSetting`, {
      Type: "create",
      Data: data,
    });
  },
  updateNegotiationAutomationSetting: (data) => {
    return instance.post(`${API_URL}/api/NegotiationTaskAutomationSetting`, {
      Type: "update",
      Data: data,
    });
  },
  getUnsettledCreditorReport: (data) => {
    return instance.post(`${API_URL}/api/UnsettledCreditorReport`, data);
  },

  getNewLeadReport: (data) => {
    return instance.post(`${API_URL}/api/NewLeadReport`, data);
  },

  getContractStatusesReport: (data) => {
    return instance.post(`${API_URL}/api/ContractStatusesReport`, data);
  },

  get3rdPartyList: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "Get3rdPartyList",
    });
  },
  getCsrTaskDistributionQueueReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskDistributionQueueReport`, data);
  },
  getCustomerDispositionSettingsReport: (data) => {
    return instance.post(
      `${API_URL}/api/CustomerDispositionSettingsReport`,
      data
    );
  },

  getUserActivityReport: (data) => {
    return instance.post(`${API_URL}/api/UserActivity`, {
      Type: "get",
      Data: data,
    });
  },

  getCsrTaskSummaryReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskSummaryReport`, data);
  },

  getSettlementsFeeReport: (data) => {
    return instance.post(`${API_URL}/api/SettlementsFeeReport`, data);
  },

  getEnrolledCreditorsReport: (data) => {
    return instance.post(`${API_URL}/api/EnrolledCreditorsReport`, data);
  },

  getDebtChangesPostEnrollmentReport: (data) => {
    return instance.post(`${API_URL}/api/CustomerTotalDebtLog`, data);
  },

  getDocumentAuditReport: (data) => {
    return instance.post(`${API_URL}/api/DocumentAuditReport`, data);
  },

  getCsrTaskTriggerLogStatus: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrtasktriggerlogstatus",
    });
  },

  getCsrTaskTriggerType: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrtasktriggertype",
    });
  },

  getSpeechCoicebySpeechText: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskType`, {
      Type: "getspeechvoicebyspeechtext",
      Data: data,
    });
  },

  getDispositionOptionsForAdmin: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcompanydispositionsforadmin",
    });
  },
  getCompanySettingStates: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcompanysettingstates",
    });
  },

  uploadCampaignData: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "uploaddata",
      Data: data,
    });
  },
  getAllUiSettings: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getalluisettings",
    });
  },
  getCompanyUiSettings: (data) => {
    return instance.post(`${API_URL}/api/CompanyUISetting`, {
      Type: "getcompanyuisetting",
      Data: data,
    });
  },
  insertCompanyUiSetting: (data) => {
    return instance.post(`${API_URL}/api/CompanyUISetting`, {
      Type: "create",
      Data: data,
    });
  },
  updateCompanyUiSetting: (data) => {
    return instance.post(`${API_URL}/api/CompanyUISetting`, {
      Type: "update",
      Data: data,
    });
  },
  getAllCreditServices: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallcreditpullsettings",
    });
  },

  getAttorneyAndState: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "GetAttorneyAndState",
    });
  },
  flgEnhancedTrackingDashboard: (data) => {
    return instance.post(`${API_URL}/api/FLGEnhancedTrackingDashboard`, {
      Data: data,
    });
  },

  getAllCreditPullSettings: () => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "getall",
    });
  },
  getServiceDefaultColumns: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "getcolumns",
      Data: data,
    });
  },
  insertCreditPullSettings: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "create",
      Data: data,
    });
  },

  updateCreditPullSettings: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "update",
      Data: data,
    });
  },
  getOffices: () => {
    return instance.get(`${API_URL}/api/Office`);
  },
  saveOffice: (data) => {
    return instance.post(`${API_URL}/api/Office`, data);
  },
  updateOffice: (data) => {
    return instance.put(`${API_URL}/api/Office`, data);
  },
  deleteOffice: (id) => {
    return instance.delete(`${API_URL}/api/Office/${id}`);
  },
  getEmployees: (isRetiredIncluded, officeId, roleId) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "getUsers",
      Data: {
        OfficeId: officeId,
        RoleId: roleId,
        IsRetiredIncluded: isRetiredIncluded,
      },
    });
  },
  getEmployeeById: (id) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "getUser",
      Data: { Id: id },
    });
  },
  getEmployeeByTimeZone: (id, timezoneId) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "getByTimeZone",
      Id: id,
      timezoneId: timezoneId,
    });
  },
  saveEmployee: (data) => {
    return instance.post(`${API_URL}/api/User`, { Type: "insert", Data: data });
  },
  updateEmployee: (data) => {
    return instance.post(`${API_URL}/api/User`, { Type: "update", Data: data });
  },
  updateRetired: (id, isRetired) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "RetireUser",
      Data: { UserId: id, IsRetired: isRetired },
    });
  },
  deleteEmployee: (id) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "Delete",
      Data: { Id: id },
    });
  },
  deleteAllVmTemplates: (id) => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "deleteallbyuserid",
      Data: id,
    });
  },
  getRoles: () => {
    return instance.get(`${API_URL}/api/Role`);
  },
  getRoleById: (roleId) => {
    return instance.get(`${API_URL}/api/Role/${roleId}`);
  },
  getModules: () => {
    return instance.get(`${API_URL}/api/Module`);
  },
  saveRole: (data) => {
    return instance.post(`${API_URL}/api/Role`, data);
  },
  updateRole: (data) => {
    return instance.put(`${API_URL}/api/Role`, data);
  },
  deleteRole: (id) => {
    return instance.delete(`${API_URL}/api/Role/${id}`);
  },
  getGenders: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getgendertypes",
    });
  },
  getStates: () => {
    return instance.get(`${API_URL}/api/State`);
  },
  getTimezones: () => {
    return instance.get(`${API_URL}/api/TimeZone`);
  },
  reportError: () => {
    return instance.post(`${API_URL}/api/Exception`);
  },
  getStatusNames: () => {
    return instance.post(`${API_URL}/api/Reference`, { Type: "getstatuses" });
  },
  getCustomerStatuses: () => {
    return instance.get(`${API_URL}/api/CustomerStatus`);
  },
  insertCustomerStatus: (data) => {
    return instance.post(`${API_URL}/api/CustomerStatus`, data);
  },
  updateCustomerStatus: (data) => {
    return instance.put(`${API_URL}/api/CustomerStatus`, data);
  },
  deleteCustomerStatus: (id) => {
    return instance.delete(`${API_URL}/api/CustomerStatus/${id}`);
  },
  getDispositionOptions: () => {
    return instance.get(`${API_URL}/api/CustomerDisposition`);
  },
  getDispositionForManagement: () => {
    return instance.post(`${API_URL}/api/DispositionManagement`, {
      Type: "get",
    });
  },
  insertDispositionOption: (data) => {
    return instance.post(`${API_URL}/api/DispositionManagement`, {
      Type: "post",
      Data: data,
    });
  },
  updateDispositionOption: (data) => {
    return instance.post(`${API_URL}/api/DispositionManagement`, {
      Type: "put",
      Data: data,
    });
  },

  createEndpoint: (userId) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "CreateEndpoint",
      Data: {
        UserId: userId,
      },
    });
  },
  deleteEndpoint: (userId) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DeleteEndpoint",
      Data: { UserId: userId },
    });
  },
  getCompanyDids: (assignTypeId) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "CompanyDid",
      Data: { Id: assignTypeId },
    });
  },
  updateDid: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateDid",
      Data: data,
    });
  },
  updateDidAssignType: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DidAssignType",
      Data: data,
    });
  },
  getAssignTypes: () => {
    return instance.get(`${API_URL}/api/CompanyDidAssignType`);
  },
  getAssignableUsers: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DidAssignableUsers",
    });
  },
  getPhoneNumbers: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "AvailablePhoneNumbers",
      Data: { Pattern: data },
    });
  },
  buyPhoneNumbers: (phone) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "BuyPhoneNumber",
      Data: {
        PhoneNumber: phone,
      },
    });
  },
  getEndPoint: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetEndpoint",
      Data: { Id: 0 },
    });
  },
  getEndPointList: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetEndpoint",
      Data: { Id: 1 },
    });
  },
  getCallerIdEndPoint: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetCallerInEndpointCalls",
    });
  },
  getDetailsByPhoneNumber: async (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetDetailsByPhoneNumber",
      Data: data,
    });
  },
  holdCall: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "HoldCall",
      Data: data,
    });
  },
  grabCall: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GrabCall",
      Data: data,
    });
  },
  transferToAgent: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToAgent",
      Data: data,
    });
  },
  transferToDid: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToDid",
      Data: data,
    });
  },
  getVMDrops: () => {
    return instance.get(`${API_URL}/api/VMDrop`);
  },
  getAvailableVMDrops: (data) => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "getavailabledrops",
      Data: data,
    });
  },
  transferToVmDrop: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToVmDrop",
      Data: data,
    });
  },
  transferToVmBox: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToVmBox",
      Data: data,
    });
  },
  transferToIngroup: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToInGroup",
      Data: data,
    });
  },
  getVMRecordings: () => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "getvoicemailtemplates",
    });
  },
  saveVMRecording: (data) => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "saverecording",
      Data: data,
    });
  },
  deleteVMRecording: (data) => {
    return instance.post(`${API_URL}/api/VoicemailTemplateUser`, {
      Type: "deletebytemplateid",
      Data: data,
    });
  },
  getCampaigns: () => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "getall",
    });
  },
  getCampaignTypes: () => {
    return instance.get(`${API_URL}/api/CampaignType`);
  },
  insertCampaign: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "create",
      Data: data,
    });
  },
  insertCampaignV2: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "createV2",
      Data: data,
    });
  },
  updateCampaign: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "update",
      Data: data,
    });
  },
  updateCampaignV2: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "updateV2",
      Data: data,
    });
  },
  getVMTemplates: () => {
    return instance.get(`${API_URL}/api/VoicemailTemplate`);
  },
  insertVMTemplate: (data) => {
    return instance.post(`${API_URL}/api/VoicemailTemplate`, data);
  },
  updateVMTemplate: (data) => {
    return instance.put(`${API_URL}/api/VoicemailTemplate`, data);
  },
  getLeadDistroSettings: () => {
    return instance.post(`${API_URL}/api/LeadDistroSettings`, {
      Type: "getleaddistrosettings",
    });
  },
  updateLeadDistroSettings: (data) => {
    return instance.post(`${API_URL}/api/LeadDistroSettings`, {
      Type: "updateleaddistrosettings",
      Data: data,
    });
  },
  getAgentTiers() {
    return instance.post(`${API_URL}/api/LeadDistroSettings`, {
      Type: "getusertiers",
    });
  },
  updateAgentTiers: (data) => {
    return instance.post(
      `${API_URL}/api/LeadDistroSettings/UpdateTierSettings`,
      {
        Type: "updatetiersettings",
        Data: data,
      }
    );
  },

  hotLeadAccepted: (data) => {
    return instance.post(`${API_URL}/api/HotLead/AcceptLead`, data);
  },
  insertMandatoryDispo: (data) => {
    return instance.post(`${API_URL}/api/MandatoryDisposition/Add`, data);
  },
  getMandatoryDispo: (data) => {
    return instance.get(`${API_URL}/api/MandatoryDisposition/Pending`, data);
  },
  getAssignableCampaigns: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DidAssignableCampaigns",
    });
  },
  updateDidCampaign: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateCampaignDid",
      Data: data,
    });
  },
  getLiveCalls: () => {
    return instance.get(`${API_URL}/api/LiveCallReport`);
  },
  resetIdle: () => {
    return instance.post(`${API_URL}/api/UserPauseReasonLog`, {
      Type: "updatelastcalltime",
    });
  },
  getPlaceholders() {
    return instance.get(`${API_URL}/api/Placeholder/GetAll`);
  },
  getQuickSms() {
    return instance.post(`${API_URL}/api/QuickSms`, { Type: "GetAll" });
  },
  insertQuickSms: (data) => {
    return instance.post(`${API_URL}/api/QuickSms`, {
      Type: "Save",
      Data: data,
    });
  },
  updateQuickSms: (data) => {
    return instance.post(`${API_URL}/api/QuickSms`, {
      Type: "Update",
      Data: data,
    });
  },
  getSmsTemplateNames() {
    return instance.post(`${API_URL}/api/QuickSms`, {
      Type: "GetAllForDropdown",
    });
  },
  processPlaceholder: (data) => {
    return instance.post(`${API_URL}/api/Placeholder/Process`, data);
  },
  getDripEmail() {
    return instance.post(`${API_URL}/api/DripEmailTemplate`, {
      Type: "GetAll",
    });
  },
  getDripEmailFromTypes() {
    return instance.get(`${API_URL}/api/DripEmailFromType`);
  },
  insertDripEmail: (data) => {
    return instance.post(`${API_URL}/api/DripEmailTemplate`, {
      Type: "Insert",
      Data: data,
    });
  },
  updateDripEmail: (data) => {
    return instance.post(`${API_URL}/api/DripEmailTemplate`, {
      Type: "Update",
      Data: data,
    });
  },
  deleteDripEmailTrigger: (triggerId) => {
    return instance.post(`${API_URL}/api/DripEmailTemplate`, {
      Type: "DeleteTrigger",
      Data: { Id: triggerId },
    });
  },
  getCampaignAgents() {
    return instance.get(`${API_URL}/api/Agent/CanTakeLeads`);
  },
  unsubscribeEmail: (data) => {
    return instance.post(`${API_URL}/api/Unsubscribe`, { Data: data });
  },
  getPauseReasonTypes() {
    return instance.get(`${API_URL}/api/PauseReason`);
  },
  insertPauseReasonType: (data) => {
    return instance.post(`${API_URL}/api/PauseReason`, data);
  },
  updatePauseReasonType: (data) => {
    return instance.put(`${API_URL}/api/PauseReason`, data);
  },
  deletePauseReasonType: (id) => {
    return instance.delete(`${API_URL}/api/PauseReason`, { data: { Id: id } });
  },
  insertPauseLog: (id, isClosingSession) => {
    return instance.post(`${API_URL}/api/UserPauseReasonLog/`, {
      Type: "updatepausereasonlog",
      PauseReasonId: id,
      isClosingSession: isClosingSession,
    });
  },
  getUserLastCallTime() {
    return instance.post(`${API_URL}/api/UserPauseReasonLog`, {
      Type: "getlastcalltime",
    });
  },
  updateUserLastCallTime: (data) => {
    return instance.post(`${API_URL}/api/UserPauseReasonLog/`, {
      Type: "updatelastcalltime",
    });
  },

  getCampaignLeads: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "getunassignedcustomersnew",
      Data: data,
    });
  },
  getCampaignLeadsV2: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "campaignsearch",
      Data: data,
    });
  },
  convertMailerDump: (data) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "converttocustomer",
      Data: data,
    });
  },
  getCustomerTagReport: (data) => {
    return instance.post(`${API_URL}/api/CustomerTagReport`, data);
  },

  getPurchaseCreditorInsuranceReport: (data) => {
    return instance.post(
      `${API_URL}/api/PurchaseCreditorInsuranceReport`,
      data
    );
  },

  getCsrTaskContactScheduleReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskContactScheduleReport`, data);
  },

  getCreditorReport: (data) => {
    return instance.post(`${API_URL}/api/CreditorReport`, data);
  },

  getActiveEnrollmentsReport: (data) => {
    return instance.post(`${API_URL}/api/ActiveEnrollments`, data);
  },
  getSettlementsReport: (data) => {
    return instance.post(`${API_URL}/api/SettlementsReport`, data);
  },
  getSNFEnrollmentReport: (data) => {
    return instance.post(`${API_URL}/api/SNFEnrollmentReport`, data);
  },

  getSNFFirstDraftReport: (data) => {
    return instance.post(`${API_URL}/api/SNFFirstDraftReport`, data);
  },
  getSnfDncReport: (data) => {
    return instance.post(`${API_URL}/api/SNFDncReport`, data);
  },
  getSnfEGReport: (data) => {
    return instance.post(`${API_URL}/api/SNFEGReport`, data);
  },
  getAgentPerformanceReport: (data) => {
    return instance.post(`${API_URL}/api/AgentPerformanceReport`, data);
  },

  getAgentPerformanceNewReport: (data) => {
    return instance.post(`${API_URL}/api/AgentPerformanceNewReport`, data);
  },

  getScrubReport: (data) => {
    return instance.post(`${API_URL}/api/ScrubReport`, data);
  },

  getCallLogReport: (data) => {
    return instance.post(`${API_URL}/api/CallLogsReport`, {
      Type: "getReport",
      Data: data,
    });
  },

  getCallLogDetail: (data) => {
    return instance.post(`${API_URL}/api/CallLogsReport`, {
      Type: "getCallLogDetail",
      Data: data,
    });
  },
  getAgentStatusReport: (data) => {
    return instance.post(`${API_URL}/api/AgentStatusReport`, data);
  },

  getCustomerStatusReport: (data) => {
    return instance.post(`${API_URL}/api/CustomerStatusReport`, data);
  },
  getMissedCallReport: (data) => {
    return instance.post(`${API_URL}/api/MissedCallReport`, data);
  },
  getSmsHistoryReport: (data) => {
    return instance.post(`${API_URL}/api/SmsHistoryReport`, data);
  },
  getRemindersReport: (data) => {
    return instance.post(`${API_URL}/api/RemindersReport`, data);
  },
  getCsrTaskPerformanceReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskPerformanceReport`, data);
  },
  getInboundStatisticsReport: (data) => {
    return instance.post(`${API_URL}/api/InboundStatistics`, data);
  },
  getLiveCallReport: (data) => {
    return instance.post(`${API_URL}/api/LiveCallReport`, data);
  },
  getPendingChangesReport: (data) => {
    return instance.post(`${API_URL}/api/PendingChangesReport`, data);
  },

  getAgentDropRateReport: (data) => {
    return instance.post(`${API_URL}/api/AgentDropRateReport`, data);
  },

  getHotLeadAndXchangeReport: (data) => {
    return instance.post(`${API_URL}/api/HotLeadReport`, data);
  },

  getDroppedCustomersReport: (data) => {
    return instance.post(`${API_URL}/api/DroppedReport`, data);
  },

  getCampaignPerformanceReport: (data) => {
    return instance.post(`${API_URL}/api/CampaignPerformanceReport`, data);
  },
  getCreditPullReport: (data) => {
    return instance.post(`${API_URL}/api/CreditPullLogReport`, data);
  },
  getClearDraftReport: (data) => {
    return instance.post(`${API_URL}/api/ClearDraftReport`, data);
  },
  getFirstClearDraftReport: (data) => {
    return instance.post(`${API_URL}/api/FirstClearDraftReport`, data);
  },
  getPendingDispositionReport: (data) => {
    return instance.post(`${API_URL}/api/PendingDispositionReport`, data);
  },

  getSettlementCandidatesReport: (data) => {
    return instance.post(`${API_URL}/api/SettlementCandidatesReport`, {
      Data: data,
    });
  },

  getDbSalesCompensationReport: (data) => {
    return instance.post(`${API_URL}/api/SalesCompensationReport`, data);
  },

  getUserScript() {
    return instance.get(`${API_URL}/api/UserScript/GetAll`);
  },
  insertScript: (data) => {
    return instance.post(`${API_URL}/api/UserScript/Insert`, data);
  },
  updateScript: (data) => {
    return instance.put(`${API_URL}/api/UserScript/Update`, data);
  },
  deleteScript: (id) => {
    return instance.delete(`${API_URL}/api/UserScript/Delete`, {
      data: { Id: id },
    });
  },
  getSNFDAAEnrollmentReport: (data) => {
    return instance.post(`${API_URL}/api/SNFDAAEnrollmentReport`, data);
  },
  getNewLineReport: (data) => {
    return instance.post(`${API_URL}/api/NewLineReport`, data);
  },
  getCsrRealtimeTasksReport: () => {
    return instance.post(`${API_URL}/api/CsrRealtimeTasksReport`);
  },
  getCsrTaskReport: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskReport`, data);
  },
  getNsfReport: (data) => {
    return instance.post(`${API_URL}/api/NsfReport`, data);
  },

  getSnfRespondersReport: (data) => {
    return instance.post(`${API_URL}/api/SnfRespondersReport`, data);
  },

  getNextGenReport: (data) => {
    return instance.post(`${API_URL}/api/NextGenReport`, data);
  },
  getEnrollmentReport: (data) => {
    return instance.post(`${API_URL}/api/EnrollmentReport`, data);
  },
  getLeadAssignReport: (data) => {
    return instance.post(`${API_URL}/api/LeadAssignReport`, data);
  },
  getIncomingVmReport: (data) => {
    return instance.post(`${API_URL}/api/IncomingVMReport`, data);
  },
  getDocusignEnvelopeHistoryReport: (data) => {
    return instance.post(`${API_URL}/api/DocusignEnvelopeHistoryReport`, data);
  },
  getNegotiatorAssignReport: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorAssignReport`, data);
  },
  getEnrollmentStatusChangeReport: (data) => {
    return instance.post(`${API_URL}/api/EnrollmentStatusChangeReport`, data);
  },

  getNsfGlobalReport: (data) => {
    return instance.post(`${API_URL}/api/NsfGlobalReport`, data);
  },

  getEnrolledWithoutSmsOptInReport: (data) => {
    return instance.post(`${API_URL}/api/EnrolledWithoutSmsOptInReport`, data);
  },

  transferBothSideToConference: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferBothSidesToConference",
      Data: data,
    });
  },
  kickMemberFromConference: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "ConferenceKickMember",
      Data: data,
    });
  },

  getCallMetricsReport: (data) => {
    return instance.post(`${API_URL}/api/ServiceBusLog`, {
      Type: "report",
      Data: data,
    });
  },

  getAnnounceCallLog: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetByPhoneNumberAnnounceCallLog",
      Data: data,
    });
  },
  insertAnnounceCallLog: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "AddNewAnnounceCallLog",
      Data: data,
    });
  },
  updateAnnounceCallLog: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateAnnounceCallLog",
      Data: data,
    });
  },
  deleteConference: (confName) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DeleteConference",
      Data: { ConferenceName: confName },
    });
  },
  getLiveConferenceNames(data) {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetLiveConferenceNames",
      Data: data,
    });
  },
  transferToConference: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferToConference",
      Data: data,
    });
  },
  deleteCreditorContact(id) {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "delete",
      Data: { Id: id },
    });
  },
  updateCreditorContact(data) {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "update",
      Data: data,
    });
  },

  searchCreditorContact(data) {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "search",
      Data: data,
    });
  },
  getAllCreditorContact(data) {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "getall",
      Data: data,
    });
  },
  sendFax(data) {
    return instance.post(`${API_URL}/api/SendEfax`, {
      Type: "sendFaxViaSmtp",
      Data: data,
    });
  },

  createCreditorContact(data) {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "create",
      Data: data,
    });
  },
  ////////
  updatePeerId: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdatePeerId",
      Data: data,
    });
  },
  kick3rdParty: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "ConferenceKick3rdParties",
      Data: data,
    });
  },
  transferToNewConference: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "TransferConferenceToConference",
      Data: data,
    });
  },
  getAnnounceCallDepartments: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetAnnounceCallDepartments",
    });
  },
  getInboundStats: (data) => {
    return instance.post(`${API_URL}/api/InboundStatistics`, data);
  },
  logError: (data) => {
    return instance.post(`${API_URL}/api/Exception`, data);
  },
  sendIncomingCallLog: (data) => {
    return instance.post(`${API_URL}/api/UserIncomingCallStatusLog`, data);
  },
  getCompanyDocumentTemplates: (data) => {
    return instance.post(`${API_URL}/api/CompanyDocumentTemplate`, {
      Type: "getall",
    });
  },

  deleteDocumentTemplate: (id) => {
    return instance.post(`${API_URL}/api/CompanyDocumentTemplate`, {
      Type: "delete",
      Id: id,
    });
  },
  insertDocumentTemplate: (data) => {
    return instance.post(`${API_URL}/api/CompanyDocumentTemplate`, data);
  },
  getQuickEmailTypes: () => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "GetQuickEmailTypes",
    });
  },
  getQuickEmails: () => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "GetQuickEmails",
    });
  },
  saveQuickEmails: (data) => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "Create",
      Data: data,
    });
  },
  updateQuickEmails: (data) => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "Update",
      Data: data,
    });
  },
  deleteQuickEmails: (id) => {
    return instance.post(`${API_URL}/api/QuickEmail`, {
      Type: "Delete",
      Data: { Id: id },
    });
  },
  getQuickNotes: () => {
    return instance.post(`${API_URL}/api/QuickNote`, { Type: "GetQuickNotes" });
  },
  saveQuickNotes: (data) => {
    return instance.post(`${API_URL}/api/QuickNote`, {
      Type: "Create",
      Data: data,
    });
  },
  updateQuickNotes: (data) => {
    return instance.post(`${API_URL}/api/QuickNote`, {
      Type: "Update",
      Data: data,
    });
  },
  deleteQuickNotes: (id) => {
    return instance.post(`${API_URL}/api/QuickNote`, {
      Type: "Delete",
      Data: { Id: id },
    });
  },
  getCsrTaskActions: () => {
    return instance.post(`${API_URL}/api/CsrTaskAction`, { Type: "getall" });
  },
  saveCsrTaskActions: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskAction`, {
      Type: "insert",
      Data: data,
    });
  },
  updateCsrTaskActions: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskAction`, {
      Type: "update",
      Data: data,
    });
  },
  getCsrTaskStatus: () => {
    return instance.post(`${API_URL}/api/CsrTaskStatus`, { Type: "getall" });
  },
  getCsrTaskTypes: () => {
    return instance.post(`${API_URL}/api/CsrTaskType`, { Type: "getall" });
  },
  saveCsrTaskType: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskType`, {
      Type: "addnew",
      Data: data,
    });
  },
  updateCsrTaskType: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskType`, {
      Type: "update",
      Data: data,
    });
  },

  // Docusign Settings
  getCompanySettingsList: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "companysettings.get",
      Data: data,
    });
  },

  getAllDocusignSettings: () => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.officesetting.getall",
    });
  },

  // Docusign Template
  getDocusignTemplate: () => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.templates.getall",
    });
  },
  searchDocusignTemplate: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.templates.search",
      Data: data,
    });
  },
  insertorUpdateDocusignTemplate: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.templates.insertorupdate",
      Data: data,
    });
  },
  // Docusign Template Type

  getDocusignPackageAll: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.package.getall",
      Data: data,
    });
  },

  getDocusignTemplateType: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.templatetype.getall",
      Data: data,
    });
  },

  insertDocusignPackage: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.package.insert",
      Data: data,
    });
  },

  updateDocusignPackage: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.package.update",
      Data: data,
    });
  },
  deleteDocusignPackage: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.package.delete",
      Data: data,
    });
  },

  insertDocusignTemplateType: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.templatetype.insert",
      Data: data,
    });
  },

  updateDocusignTemplateType: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.templatetype.update",
      Data: data,
    });
  },

  deleteDocusignTemplate: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.templates.delete",
      Data: data,
    });
  },

  addDocusignSetting: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.officesetting.insertorupdate",
      Data: data,
    });
  },
  getDocusignPlaceHolder: () => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.availableplaceholders",
    });
  },

  deleteDocusignSetting: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.officesetting.delete",
      Data: data,
    });
  },

  addDocusignSettingsNewDate: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.officesetting.addneworupdate",
      Data: data,
    });
  },

  checkRouteNumber: (payload) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "routingnumbervalidation",
      Data: payload,
    });
  },

  getOfficeNames: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getofficenames",
    });
  },

  getSalesAgents: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getsalesagents",
    });
  },
  getNegotiators: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getnegotiators",
    });
  },

  getCSRs: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrs",
    });
  },

  getAllActiveEmployees: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallusers",
    });
  },

  getTaskTypesNames: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrtasktypes",
    });
  },

  getTaskActionNames: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrtaskactions",
    });
  },

  sendEmailMg: (data) => {
    return instance.post(`${API_URL}/api/EmailSender`, data);
  },

  initializeDashboard: () => {
    return instance.post(`${API_URL}/api/AgentDashboard`, {
      Type: "init",
    });
  },
  getDbDashboardData: (data) => {
    return instance.post(`${API_URL}/api/IndividualSalesDashboard`, data);
  },
  getCsrTaskTypesSimplified: () => {
    return instance.post(`${API_URL}/api/CsrTaskType`, {
      Type: "gettasktypes",
    });
  },

  getInboundCallSettings() {
    return instance.post(`${API_URL}/api/OfficePhoneSetting`, {
      Type: "getall",
    });
  },
  updateInboundCallSettings: (data) => {
    return instance.post(`${API_URL}/api/OfficePhoneSetting`, {
      Type: "update",
      Data: data,
    });
  },

  insertInboundCallSettings: (data) => {
    return instance.post(`${API_URL}/api/OfficePhoneSetting`, {
      Type: "create",
      Data: data,
    });
  },

  getCompanyCallSettings() {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "getinboundsettings",
    });
  },
  updateCompanyCallSettings: (data) => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "updateinboundsettings",
      Data: data,
    });
  },
  getIncomingCallBlock() {
    return instance.post(`${API_URL}/api/IncomingCallBlock`, {
      Type: "get",
    });
  },
  insertIncomingCallBlock(data) {
    return instance.post(`${API_URL}/api/IncomingCallBlock`, {
      Type: "post",
      Data: data,
    });
  },
  deleteIncomingCallBlock(data) {
    return instance.post(`${API_URL}/api/IncomingCallBlock`, {
      Type: "delete",
      Data: data,
    });
  },
  getDidassignTypes: () => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "getdidassigntypes",
    });
  },
  updateOutboundCallMethod: (id) => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "updatedidassigntype",
      Data: { Id: id },
    });
  },
  updateInboundRules: (data) => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "updateinboundrules",
      Data: data,
    });
  },
  getInboundRules: (data) => {
    return instance.post(`${API_URL}/api/CallSettings`, {
      Type: "getinboundrules",
      Data: data,
    });
  },

  getCallerId: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetCallerIdV2",
      Data: data,
    });
  },

  getDidAssignableOffices: () => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "DidAssignableOffices",
    });
  },
  updateOfficeDid: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateOfficeDid",
      Data: data,
    });
  },
  updateCompanyDid: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateCompanyDid",
      Data: data,
    });
  },
  getPendingChangeStatuses: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getpendingchangestatus",
    });
  },
  getPendingChangeTypes: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getpendingchangetype",
    });
  },
  getAllPolicyGroups: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallpolicygroups",
    });
  },

  getPolicyGroupsByCompany: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "policygroups.getbycompany",
      Data: data,
    });
  },
  getCompanyGlobalSettings: () => {
    return instance.post(`${API_URL}/api/CompanyGlobalSetting`, {
      Type: "get",
    });
  },
  insertCompanyGlobalSettings: (data) => {
    return instance.post(`${API_URL}/api/CompanyGlobalSetting`, {
      Type: "insert",
      Data: data,
    });
  },
  updateCompanyGlobalSettings: (data) => {
    return instance.post(`${API_URL}/api/CompanyGlobalSetting`, {
      Type: "update",
      Data: data,
    });
  },
  deleteCompanyGlobalSettings: (data) => {
    return instance.post(`${API_URL}/api/CompanyGlobalSetting`, {
      Type: "delete",
      Data: data,
    });
  },
  getEquifaxApiSettings: () => {
    return instance.post(`${API_URL}/api/CompanyArraySetting`, {
      Type: "getProductConfigurationByCompany",
    });
  },
  getCompanyArraySettings: (data) => {
    return instance.post(`${API_URL}/api/CompanyArraySetting`, {
      Type: "get",
    });
  },
  updateCompanyArraySettings: (data) => {
    return instance.post(`${API_URL}/api/CompanyArraySetting`, {
      Type: "save",
      Data: data,
    });
  },
  getCompanyPolicyGroupIds: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcompanypolicygroups",
      Data: data,
    });
  },
  getOfficeFilteredPolicyGroups: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcompanypolicygroups_bypaymentconfiguration",
      Data: data,
    });
  },

  createTagManagement(data) {
    return instance.post(`${API_URL}/api/CustomerTagType`, {
      Type: "create",
      Data: data,
    });
  },
  updateTagManagement: (data) => {
    return instance.post(`${API_URL}/api/CustomerTagType`, {
      Type: "update",
      Data: data,
    });
  },
  deleteTagManagement: (data) => {
    return instance.post(`${API_URL}/api/CustomerTagType`, {
      Type: "delete",
      Data: data,
    });
  },
  getAllTagManagement: () => {
    return instance.post(`${API_URL}/api/CustomerTagType`, {
      Type: "getall",
    });
  },
  getDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "nfdashboard",
      Data: data,
    });
  },
  getWelcomeCallDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "snfdashboard.welcomecall",
      Data: data,
    });
  },

  getlivecallmonitoringDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "snfdashboard.livecallmonitoring",
      Data: data,
    });
  },

  updateVmBoxEnabled: (data) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "updateUserVmBoxEnabled",
      Data: data,
    });
  },

  getSnfDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "snfdashboardnew",
      Data: data,
    });
  },

  getSnfDashboardCustomerStatus: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "snfdashboard.customerstatus",
      Data: data,
    });
  },

  getAffilateEnrollmentDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "affiliateenrollmentdashboard",
      Data: data,
    });
  },

  getIndividualSalesDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "nfindividualsalesdashboard",
      Data: data,
    });
  },

  getOperationsHourDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "snfdashboard.operationhours",
      Data: data,
    });
  },

  getAffiliateLeadershipDashboard: (data) => {
    return instance.post(`${API_URL}/api/AffiliateLeadershipDashboard`, data);
  },

  getCSRDashboardReport: (data) => {
    return instance.post(`${API_URL}/api/CSRDashboardReport`, data);
  },

  getPurchaseCreditorReport: (data) => {
    return instance.post(`${API_URL}/api/PurchaseCreditorReport`, data);
  },

  getSnfPayoutReport: (data) => {
    return instance.post(`${API_URL}/api/SnfPayoutReport`, data);
  },

  getCreditorSettings: (data) => {
    return instance.post(`${API_URL}/api/CreditorAverageSettlementPercentage`, {
      Type: "Get",
      Data: data,
    });
  },
  createCreditorSettings: (data) => {
    return instance.post(`${API_URL}/api/CreditorAverageSettlementPercentage`, {
      Type: "Create",
      Data: data,
    });
  },
  updateCreditorSettings: (data) => {
    return instance.post(`${API_URL}/api/CreditorAverageSettlementPercentage`, {
      Type: "Update",
      Data: data,
    });
  },
  deleteCreditorSettings: (data) => {
    return instance.post(`${API_URL}/api/CreditorAverageSettlementPercentage`, {
      Type: "Delete",
      Data: data,
    });
  },
  //
  suggestCreditorByPattern: (data) => {
    return instance.post(`${API_URL}/api/CreditorAverageSettlementPercentage`, {
      Type: "GetNameList",
      Data: data,
    });
  },

  getCreditorAlias: (data) => {
    return instance.post(`${API_URL}/api/CreditorAlias`, {
      Type: "Get",
      Data: data,
    });
  },

  createCreditorAlias: (data) => {
    return instance.post(`${API_URL}/api/CreditorAlias`, {
      Type: "Create",
      Data: data,
    });
  },

  updateCreditorAlias: (data) => {
    return instance.post(`${API_URL}/api/CreditorAlias`, {
      Type: "Update",
      Data: data,
    });
  },

  deleteCreditorAlias: (id) => {
    return instance.post(`${API_URL}/api/CreditorAlias`, {
      Type: "Delete",
      Data: { Id: id },
    });
  },
  getAllCreditorAndAliases: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallcreditorandaliases",
    });
  },

  insertOrUpdateDashboardConfig: (data) => {
    return instance.post(`${API_URL}/api/DashboardConfiguration`, {
      Type: "InsertOrUpdate",
      Data: data,
    });
  },
  removeDashboardConfig: (data) => {
    return instance.post(`${API_URL}/api/DashboardConfiguration`, {
      Type: "Remove",
      Data: data,
    });
  },
  getUsersDashboardConfig: () => {
    return instance.post(`${API_URL}/api/DashboardConfiguration`, {
      Type: "GetUsers",
    });
  },
  getByUserIdDashboardConfig: (data) => {
    return instance.post(`${API_URL}/api/DashboardConfiguration`, {
      Type: "GetDashboardConfigurationByUserId",
      Data: data,
    });
  },
  attorneyList: (data) => {
    return instance.post(`${API_URL}/api/Attorney`, {
      Type: "getAttorneyList",
      Data: data,
    });
  },

  attorneySettingSave: (data) => {
    return instance.post(`${API_URL}/api/Attorney`, {
      Type: "saveAttorneyPerEmployee",
      Data: data,
    });
  },

  getCreditorContact: (data) => {
    return instance.post(`${API_URL}/api/PoaSettings`, {
      Type: "GetCreditorsContact",
      Data: data,
    });
  },

  updateCreditorContactManagement: (data) => {
    return instance.post(`${API_URL}/api/PoaSettings`, {
      Type: "UpdateCreditorContact",
      Data: data,
    });
  },

  deleteCreditorContactManagement: (data) => {
    return instance.post(`${API_URL}/api/PoaSettings`, {
      Type: "DeleteCreditorContact",
      Data: data,
    });
  },

  insertCreditorContactManagement: (data) => {
    return instance.post(`${API_URL}/api/PoaSettings`, {
      Type: "AddCreditorContact",
      Data: data,
    });
  },

  getForCustomerStatusManagement: (data) => {
    return instance.post(`${API_URL}/api/DispositionManagement`, {
      Type: "getforcustomerstatusmanagement",
      Data: { StatusId: data },
    });
  },

  addMonthlyDashboardConfigManagement: (data) => {
    return instance.post(`${API_URL}/api/MonthlyDashboardConfig`, {
      Type: "Add",
      Data: data,
    });
  },
  updateMonthlyDashboardConfigManagement: (data) => {
    return instance.post(`${API_URL}/api/MonthlyDashboardConfig`, {
      Type: "Update",
      Data: data,
    });
  },
  getMonthlyDashboardConfigManagement: (data) => {
    return instance.post(`${API_URL}/api/MonthlyDashboardConfig`, {
      Type: "Get",
      Data: data,
    });
  },

  getXChangeManagement: (data) => {
    return instance.post(`${API_URL}/api/XChange`, {
      Type: "admin.get",
      Data: data,
    });
  },
  addXChangeManagement: (data) => {
    return instance.post(`${API_URL}/api/XChange`, {
      Type: "admin.insert",
      Data: data,
    });
  },
  updateXChangeManagement: (data) => {
    return instance.post(`${API_URL}/api/XChange`, {
      Type: "admin.update",
      Data: data,
    });
  },

  getSalesMonthlyOverviewData: (data) => {
    return instance.post(`${API_URL}/api/MonthlyDashboard`, {
      Type: "GetMothlyDashboard",
      Data: data,
    });
  },

  getSmsBrands: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "GetCompanySmsBrands",
      Data: data,
    });
  },
  getAllDocumentTypes() {
    return instance.post(`${API_URL}/api/DocumentType`, {
      Type: "getAllDocumentTypes",
    });
  },

  getAllDocumentTypesStatuses() {
    return instance.post(`${API_URL}/api/DocumentType`, {
      Type: "getAllDocumentTypesStatuses",
    });
  },

  insertDocumentType: (data) => {
    return instance.post(`${API_URL}/api/DocumentType`, {
      Type: "createDocumentType",
      Data: data,
    });
  },

  getSettlementAssistReport: (data) => {
    return instance.post(`${API_URL}/api/SettlementAssistReport`, data);
  },

  getCustomerCancelationReason: (data) => {
    return instance.post(`${API_URL}/api/CustomerCancelationReason`, {
      Type: "getall",
      Data: { IsActive: data },
    });
  },

  addCustomerCancelationReason: (data) => {
    return instance.post(`${API_URL}/api/CustomerCancelationReason`, {
      Type: "add",
      Data: data,
    });
  },

  updateCustomerCancelationReason: (data) => {
    return instance.post(`${API_URL}/api/CustomerCancelationReason`, {
      Type: "update",
      Data: data,
    });
  },

  updateEditDocusignTemplate: (data) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.templates.update",
      Data: data,
    });
  },
  getAuditMatrixConfigs: (data) => {
    return instance.post(`${API_URL}/api/AuditMatrixConfigurations`, {
      Type: "getAuditMatrixConfigs",
      Data: data,
    });
  },
  saveAuditMatrixConfigs: (data) => {
    return instance.post(`${API_URL}/api/AuditMatrixConfigurations`, {
      Type: "saveAuditMatrixConfigs",
      Data: data,
    });
  },

  getAllBlockedCreditTypes: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "getAllBlockedCreditTypes",
    });
  },

  addBlockedCreditType: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "addBlockedCreditType",
      Data: data,
    });
  },

  updateBlockedCreditType: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "updateBlockedCreditType",
      Data: data,
    });
  },

  deleteBlockedCreditType: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "deleteBlockedCreditType",
      Data: data,
    });
  },

  getAllBlockedCreditors: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "getAllBlockedCreditors",
    });
  },

  addBlockedCreditor: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "addBlockedCreditor",
      Data: data,
    });
  },

  updateBlockedCreditor: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "updateBlockedCreditor",
      Data: data,
    });
  },

  deleteBlockedCreditor: (data) => {
    return instance.post(`${API_URL}/api/CreditPullSetting`, {
      Type: "deleteBlockedCreditor",
      Data: data,
    });
  },

  changePassword: (data) => {
    return instance.post(`${API_URL}/api/ChangePassword`, data);
  },

  mfaVerificationSend: (data) => {
    return instance.post(`${API_URL}/api/MfaVerification`, {
      Type: "send",
      Data: data,
    });
  },
  mfaVerificationVerify: (data) => {
    return instance.post(`${API_URL}/api/MfaVerification`, {
      Type: "verify",
      Data: data,
    });
  },

  getSnfAttorneyActivityReport: (data) => {
    return instance.post(`${API_URL}/api/SnfAttorneyActivityReport`, data);
  },
  getPaymentsAndFeesReport: (data) => {
    return instance.post(`${API_URL}/api/PaymentsAndFeesReport`, data);
  },
  getDpgPaymentsAndFeesReport: (data) => {
    return instance.post(`${API_URL}/api/DpgPaymentsAndFeesReport`, data);
  },
  getGlobalDraftsReport: (data) => {
    return instance.post(`${API_URL}/api/GlobalDraftsReport`, data);
  },

  getLeadTracEnrollmentsReport: (data) => {
    return instance.post(`${API_URL}/api/LeadTrac`, {
      Type: "CheckLTEnrollments",
      Data: data,
    });
  },
  getConnectGlobalAccount: (data) => {
    return instance.post(`${API_URL}/api/LeadTrac`, {
      Type: "ConnectGlobalAccount",
      Data: data,
    });
  },

  getSNFSettlementsDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "snfdashboard.settlements",
      Data: data,
    });
  },

  getTotalUnsettleDebt: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "snfdashboard.totalUnsettledDebt",
      Data: data,
    });
  },

  getNegotiatorsDashboard: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorDashboard`, data);
  },

  getSNFSettlementsFeeDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "snfdashboard.settlementsfee",
      Data: data,
    });
  },

  getNegotiatorStatisticsDashboard: (data) => {
    return instance.post(`${API_URL}/api/Dashboard`, {
      Type: "snfdashboard.adminnegotiator",
      Data: data,
    });
  },

  knowledgeOwl: (data) => {
    return instance.post(`${API_URL}/api/KnowledgeOwl`, data);
  },

  getAllPayoutConfiguration: (data) => {
    return instance.post(`${API_URL}/api/SnfPayoutConfiguration`, {
      Type: "getallpayoutconfiguration",
      Data: data,
    });
  },

  getDefaultSettlementtier: (data) => {
    return instance.post(`${API_URL}/api/SnfPayoutConfiguration`, {
      Type: "getdefaultsettlementtier",
      Data: data,
    });
  },

  getOfficesWithConfig: (data) => {
    return instance.post(`${API_URL}/api/SnfPayoutConfiguration`, {
      Type: "getofficeswithconfig",
      Data: data,
    });
  },

  updatePayoutConfiguration: (data) => {
    return instance.post(`${API_URL}/api/SnfPayoutConfiguration`, {
      Type: "updatepayoutconfiguration",
      Data: data,
    });
  },

  updateDefaultSettlementtier: (data) => {
    return instance.post(`${API_URL}/api/SnfPayoutConfiguration`, {
      Type: "updatedefaultsettlementtier",
      Data: data,
    });
  },

  addPayoutConfiguration: (data) => {
    return instance.post(`${API_URL}/api/SnfPayoutConfiguration`, {
      Type: "addpayoutconfiguration",
      Data: data,
    });
  },

  deletePayoutConfiguration: (data) => {
    return instance.post(`${API_URL}/api/SnfPayoutConfiguration`, {
      Type: "deletepayoutconfiguration",
      Data: data,
    });
  },

  getCreditorAndPoaReport: (data) => {
    return instance.post(`${API_URL}/api/UserActivity`, {
      Type: "getcreditorandpoareport",
      Data: data,
    });
  },

  getFirstLeadAssignmentReport: (data) => {
    return instance.post(`${API_URL}/api/FirstLeadAssignmentReport`, data);
  },

  getEarlyLoanLogReport: (data) => {
    return instance.post(`${API_URL}/api/EarlyLoanList`, data);
  },

  getClearedSettlementFeeReport: (data) => {
    return instance.post(`${API_URL}/api/ClearedSettlementFeeReport`, data);
  },

  getDataUploadReport: (data) => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "getMigrationReport",
      Data: data,
    });
  },

  getSettingsMigrationReport: () => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "getAllSettingsWithoutDetails",
    });
  },

  getMigrationRecordReportDetail: (data) => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "generateMigrationReportDetail",
      Data: data,
    });
  },

  getDataTemplate: () => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "dataTemplate",
    });
  },

  getFileUploadSettings: () => {
    return instance.post(`${API_URL}/api/Migration`, {
      Type: "getAllSettings",
    });
  },

  uploadCSVFile: (payload) => {
    return instance.post(
      `${API_URL}/api/Files`,
      {
        ...payload,
      },
      {
        timeout: 300000,
      }
    );
  },

  triggerPipeline: (payload) => {
    return instance.post(`${API_URL}/api/AzureMigration`, {
      ...payload,
    });
  },
};
