<template>
    <div
      class="col-md-12 newPanelWrapper noMargin"
      style="background-color: #ffffff; z-index: 1000"
    >
      <div
        style="display: flex; justify-content: space-between"
        v-on:click.prevent.stop="closePanel"
      >
        <label>Agent Login Logs </label>
  
        <i class="fas fa-window-close closeButtonBulk"></i>
      </div>
      <div class="row">
        <perfect-scrollbar
          style="width: 100%; max-height: 600px; overflow-x: hidden !important"
        >
          <div class="col-md-12 mt-2" v-if="loginLogs && loginLogs.length > 0">
            <table
              class="custom-table"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>User</th>
                  <th>Log Date</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="item.Id" v-for="(item, index) in loginLogs">
                  <td>
                    <strong> {{ index + 1 }}. </strong>
                  </td>
                  <td>{{ agentName }}</td>
                  <td>{{ formatGridDate(item.InsertedDate) }}</td>
                </tr>
              </tbody>
            </table>
      
          </div>
          <div v-if="!loginLogs || loginLogs.length == 0" class="col-md-12 noDataLabel">
            There is no log data.
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </template>
  
  <script>
  import formatMixin from "@/mixins/formatMixin";
  import utilitiesMixin from "@/mixins/utilitiesMixin";
  export default {
    name: "AgentLoginLogPopup",
    mixins: [formatMixin, utilitiesMixin],
    props: {
    loginLogs: {
      type: Array,
      default: () => [],
    },
    agentName: {
      type: String,
      default: () => "",
    },
  },
    data() {
      return {
      };
    },
    async mounted() {
     
    },
    methods: {
      closePanel() {
        this.$emit("closeAgentLoginLogPopup");
      },
    },
  };
  </script>
  
  <style>
  .closeButton {
    position: absolute;
    top: -5px;
    right: 15px;
    z-index: 1000;
    font-size: 30px;
    cursor: pointer;
  }
  .closeButton:hover {
    color: #ff3860;
  }
  .noDataLabel {
    min-height: 150px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-around;
  }
  @media screen and (max-height: 390px) {
    .dcp-mediaScreen {
      max-height: 200px !important;
      overflow-y: scroll;
    }
  }
  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1000;
  }
  </style>
  