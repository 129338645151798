<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'CSRTaskReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredCSRTaskReport"
        :paginated="isPaginated && filteredCSRTaskReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.CustomerId, props.row.CustomerFirstName, props.row.CustomerLastName)"
              :class="getTagReportHyperLinkClass(props.row.CustomerId && props.row.CustomerFirstName && props.row.CustomerLastName)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="CustomerFirstName"
          label="First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerFirstNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerFirstName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerFirstName }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="CustomerLastName"
          label="Last Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerLastNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerLastName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerLastName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(3714)"
          field="PhoneNumber"
          label="Phone Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="phoneNumberFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{
              props.row.PhoneNumber == null
                ? " "
                : formatPhone(props.row.PhoneNumber)
            }}
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(3714)"
          field="Email"
          label="Email"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="emailFilter"
                placeholder=""
                />
              </div>
            </template>
            
            <template v-slot="props">
              {{ props.row.Email }}
            </template>
          </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                style="width: 60%"
                type="text"
                class="datagrid-table creditorTable"
                v-model="stateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerStatus"
          label="Customer Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerStatus }}
          </template>
        </o-table-column>

        <o-table-column field="TaskType" label="Task Type" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="taskTypeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.TaskType }}
          </template>
        </o-table-column>

        <o-table-column
          field="TaskStatus"
          label="Task Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="taskStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.TaskStatus }}
          </template>
        </o-table-column>

        <o-table-column field="DueDate" label="Due Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="dueDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatGridDate(props.row.DueDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreationDate"
          label="Creation Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreationDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ formatGridDate(props.row.CreationDate) }}
          </template>
        </o-table-column>

        <o-table-column field="Notes" label="Notes" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="notesFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Notes }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreatedBy"
          label="Created By"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreatedByFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CreatedBy }}
          </template>
        </o-table-column>

        <o-table-column
          field="CompletedBy"
          label="Completed By"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CompletedByFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CompletedBy }}
          </template>
        </o-table-column>

        <o-table-column field="ClientLanguage" label="Client Language" sortable searchable>
            <template v-slot:searchable>
              <div>
                <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="languageFilter"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ mapLanguageIdToLanguageString(props.row.CustomerLanguageId) }}
          </template>
        </o-table-column>

        <o-table-column field="ClientStartDate" label="Client Start Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
              type="text"
              class="datagrid-table creditorTable"
              v-model="startDateFilter"
            />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerStartDate  }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.CustomerId,
                props.row.CustomerFirstName,
                props.row.CustomerLastName
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredCSRTaskReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "CSRTaskReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      CSRTaskReportInitSummary: {
        CustomerFirstName: "",
        CustomerLastName: "",
        TaskType: "",
        DueDate: null,
        Notes: "",
        State: "",
        PolicyGroup: "",
        Office: "",
        CreatedBy: "",
        CompletedBy: "",
        PhoneNumber: "",
        Email: "",
        CreationDate: "",
        CustomerStatus: "",
      },
      customerIdFilter: "",
      CustomerFirstNameFilter: "",
      CustomerLastNameFilter: "",
      taskTypeFilter: "",
      taskStatusFilter: "",
      dueDateFilter: "",
      notesFilter: "",
      stateFilter: "",
      PolicyGroupFilter: "",
      OfficeFilter: "",
      CreatedByFilter: "",
      CompletedByFilter: "",
      phoneNumberFilter: "",
      emailFilter: "",
      CreationDateFilter: "",
      CustomerStatusFilter: "",
      languageFilter: "",
      startDateFilter: "",
    };
  },
  computed: mapState({
    documentsCsrTasksList: (state) => state.reports.csrTasksList,

    filteredCSRTaskReport() {
      let rtn = [];

      let tmpList =
        this.documentsCsrTasksList.length > 0
          ? this.documentsCsrTasksList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.customerIdFilter) &&
                this.filterString(
                  item.CustomerFirstName,
                  this.CustomerFirstNameFilter
                ) &&
                this.filterString(
                  item.CustomerLastName,
                  this.CustomerLastNameFilter
                ) &&
                this.filterString(item.State, this.stateFilter) &&
                new Date(item.CreationDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.CreationDateFilter) > -1 &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(item.Office, this.OfficeFilter) &&
                this.filterString(item.Notes, this.notesFilter) &&
                this.filterString(
                  item.CustomerStatus,
                  this.CustomerStatusFilter
                ) &&
                this.filterString(item.TaskType, this.taskTypeFilter) &&
                this.filterString(item.TaskStatus, this.taskStatusFilter) &&
                new Date(item.DueDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.dueDateFilter) > -1 &&
                this.filterString(item.CreatedBy, this.CreatedByFilter) &&
                this.filterString(item.CompletedBy, this.CompletedByFilter) &&
                this.filterString(item.Email, this.emailFilter) &&
                this.filterString(item.PhoneNumber, this.phoneNumberFilter) &&
                this.filterString(item.CustomerStartDate, this.startDateFilter) &&
                this.filterString(this.mapLanguageIdToLanguageString(item.CustomerLanguageId), this.languageFilter) 
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalRecord = tmpList.length.toString();

      this.CSRTaskReportInitSummary.CustomerId = "Total: " + totalRecord;
      this.recordCount = tmpList.length;

      this.CSRTaskReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.CSRTaskReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },

    setPagination(data) {
      this.isPaginated = data;
    },

    async setPayload(payload) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_CSR_TASK_REPORT, payload);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, firstName, lastName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: firstName + lastName,
      });
    },
  },
};
</script>

<style scoped></style>
