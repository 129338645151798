/**
 * @file dateHelpers.js
 * @description This file contains helper functions for date manipulation and formatting.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 */

/**
 * Formats a given date into a specified string representation.
 *
 * @param {Date} [date=new Date()] - The date to format. If no date is provided, the current date is used.
 * @param {string} [format="MM/DD/YYYY"] - The format to return the date in. Supported formats are "MM/DD/YYYY" and "YYYY-MM-DD".
 * @returns {string} A string representing the formatted date in the specified format.
 */
export const dateFormat = ({
  date = new Date(),
  format = "MM/DD/YYYY",
} = {}) => {
  const dateFormatted = new Date(date);
  switch (format) {
    case "YYYY-MM-DD": {
      const isoString = dateFormatted.toISOString();
      const formattedDate = isoString.split("T")[0];
      return formattedDate;
    }

    case "MM/DD/YYYY":
      return dateFormatted.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

    default:
      return date;
  }
};
