<template>
  <div
    class="col-md-12 newPanelWrapper noMargin dcp-mediaScreen"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div style="display: flex; justify-content: space-between">
      <label>Full Account Numbers</label>
      <i
        class="fas fa-window-close closeButtonBulk"
        v-on:click.prevent.stop="closePanel"
      ></i>
    </div>

    <div
      class="col-md-12 noPadding mt-3"
      style="display: flex; flex-direction: column"
    >
      <div class="matchedWith">
        <label style="font-size: 14px; font-weight: 600; color: #333"
          >Match With</label
        >
        <label style="font-size: 13px; color: #555"
          >Creditor Name: {{ creditorName }}</label
        >
        <label style="font-size: 13px; color: #555"
          >Account Number: {{ accountNumber }}</label
        >
      </div>
      <table class="datagrid-table" style="cursor: pointer">
        <thead>
          <tr>
            <th>Bank Name</th>
            <th>Card Number</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in cardNumberList"
            :key="index"
            @click="selectCard(item)"
          >
            <td>{{ item.BankName }}</td>
            <td>{{ item.CardNumber }}</td>
          </tr>
        </tbody>
      </table>

      <div v-if="selectedCardNumber" class="mt-3">
        <strong>Selected Card Number:</strong> {{ selectedCardNumber }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AccountNumbersPopup",
  props: {
    cardNumberList: {
      type: Array,
      default: () => [],
    },
    creditorName: {
      type: String,
      default: () => "",
    },
    accountNumber: {
      type: String,
      default: () => "",
    },
    selectedCreditorId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      selectedCardNumber: null,
    };
  },
  async mounted() {},
  computed: mapState({}),
  methods: {
    closePanel() {
      this.$emit("closeAccountNumberPopup");
    },
    selectCard(item) {
      let val = {
        CreditorId: this.selectedCreditorId,
        AccountNumber: item.CardNumber,
      };
      this.$emit("accountNumberSelected", val);
      this.closePanel();
    },
  },
};
</script>

<style scoped>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px 12px;
  text-align: left;
  border: 1px solid #ddd;
  cursor: pointer;
}

.table tr:hover {
  background-color: #f1f1f1;
}
.matchedWith {
  display: flex;
  flex-direction: column;
  background: linear-gradient(331deg, #e6f1ff, #c2e1ff);
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
