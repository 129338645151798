<template>
  <div class="container-fluid h-100 w-75">
    <div class="row d-flex flex-sm-row justify-content-center w-100 mb-3">
      <h2>Upload Data</h2>
    </div>
    <div class="row d-flex flex-row w-100 justify-content-center align-items-center mb-3">
      <label for="csvFileUploadSelected">Setting</label>
      <select v-model="selectedSettingId" class="custom-select w-100" name="csvFileUploadSelected">
        <option :value="null" disabled>
          Select a setting
        </option>
        <option v-for="setting in settings" :key="setting.Id" :value="setting.Id">
          {{ setting.SettingName }}
        </option>
      </select>
    </div>
    <div v-if="selectedSettingId && selectedSetting" class="row d-flex flex-column w-100 mb-3">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              File Name
            </th>
            <th scope="col">
              Columns
            </th>
            <th scope="col">
              File Info
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in selectedSetting.DataUploadSettingDetailList" :key="index" scope="row">
            <FileUploadTableRow :row="row" @file-uploaded="file => uploadFile(file, row.FileName)" />
          </tr>
        </tbody>
      </table>
      <div class="d-flex">
        <button class="btn btn-success mr-2" :disabled="disableUploadButton" @click="processFiles">
          Upload data
        </button>
        <button class="btn btn-danger" @click="cancelFileUpload">
          Cancel
        </button>
      </div>
      <div class="col md-12 d-flex flex" style="gap: 10px">
        <button class="btn btn-success" v-if="uploadedFile.documentName" @click="triggerPipeline"> Trigger Pipeline </button>
        <button class="btn btn-danger" v-if="uploadedFile.documentName" @click="downloadCSV"> Download File </button>
      </div>
    
    </div>

  </div>
</template>

<script>
import FileUploadTableRow from '@/components/CampaignManagement/FileUploadTableRow.vue';
import { mapState } from 'vuex';
import types from './types';
import api from '@/services/admin/api';
import to from "@/helpers/to";

export default {
  name: "UploadFile",
  components: {
    FileUploadTableRow
  },
  data: () => ({
    filesToUpload: [],
    selectedSettingId: null,
    uploadingData: false
  }),
  computed: {
    selectedSetting() {
      return this.settings.find(setting => setting.Id === this.selectedSettingId);
    },
    disableUploadButton() {
      return this.uploadingData || !this.selectedSetting.DataUploadSettingDetailList.every(file => !!file.file);
    },
    ...mapState({
      uploadedFile: (state) => state.uploadDataSettings.uploadedFile,
      settings: (state) => state.uploadDataSettings.fileUploadSettings
        .sort((a, b) => a.SettingName.localeCompare(b.SettingName))
    })
  },
  async created() {
    await this.$store.dispatch(types.GET_FILE_UPLOAD_SETTINGS);
  },
  methods: {
    cancelFileUpload() {
      this.selectedSettingId = null;
      this.uploadedFile = null;
      this.filesToUpload = [];
    },
    uploadFile(file, fileName) {
      this.$store.commit(types.ADD_FILE_TO_UPLOAD, {
        settingId: this.selectedSettingId,
        fileName,
        file
      });
    },
    async processFiles() {
      console.log("uploading")
      //this.uploadingData = true;
      const dataPromises = this.selectedSetting.DataUploadSettingDetailList.map(async file => {
        const toBase64 = fileToConvert => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(fileToConvert);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        });

        const base64File = await toBase64(file.file);

        return {
          DataFile: base64File,
          Name: file.FileName
        }
      });

      try {
        const data = await Promise.all(dataPromises);
        const payload = {
          Type: "generateNewFileAsync",
          DataUploadSettingId: this.selectedSettingId,
          Data: data
        }

        const result = await this.$store.dispatch(types.PROCESS_CSV, payload)
        const status = result?.status;
        const response = result?.response;

        if (status && response) {
          this.$swal("Error!",`${response?.Errors[0]}`, "error");
        } else {
          this.$swal("Success!", "File was uploaded successfully", "success")
        }

      } catch (error) {
        console.error(error)
        this.$swal("Error!", "There was an error while uploading the file", "error");
      }
    },
    async triggerPipeline() {
      const templateNamesEnum = {
        '1': 'CustomerFile',
        '2': 'CreditorFile',
        '3': 'CoCustomerFile',
        '4': 'CustomerNoteFile',
      };

      const { DataUploadTemplateId } = this.selectedSetting;


      let payload = {
        "Type": "ExecuteAzurePipeline",
        "CampaignId": 1,
      };

      Object.keys(templateNamesEnum).forEach(id => {

        const fileNameKey = `${templateNamesEnum[id]}`;
        if (DataUploadTemplateId == id) {
          payload[fileNameKey] = this.uploadedFile.documentName ? this.uploadedFile.documentName : '';
        }
      });

      const [err, result] = await this.$store.dispatch(types.TRIGGER_PIPELINE, payload);
      if(!err || result?.Errors.length === 0) {
        this.$swal("Pipeline triggered","", "success" )
      } else {
        this.$swal("Pipeline failed triggered","", "error" )
      }

    },
    downloadCSV() {
      if (!this.uploadedFile.Base64Response && !this.uploadedFile.documentName) {
        this.$swal("Error!", "The filename couldn't be fetched, can't initialize the pipeline", "error");
        return
      }
      const {Base64Response: data, documentName} = this.uploadedFile;

      const byteArray = Buffer.from(data, 'base64');

      const blob = new Blob([byteArray], { type: 'text/csv' });

      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = documentName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
  }
}
</script>

<style>
.upload-file__table-button::before {
  color: blue;
}
</style>
