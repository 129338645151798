/**
 * @file popupValidations.js
 * @description This file contains helper functions for validating popups before opening them.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 */

import utilMixin from "@/mixins/utilitiesMixin.js";
import store from "@/store/modules/globals.js";
import { orderBy } from "lodash";
import { EARLY_LOAN_PROVIDER } from "../views/agent/EditClient/constants";
import { isOnlyAlphaChars } from "./textHelper";

const utilitiesMixin = {
  methods: { ...utilMixin.methods },
};

/**
 * Gets the necessary warnings for the loanApplication popups.
 *
 * @param {object} customer - The object containing customer info.
 * @param {string} provider - The provider of which the warnings are being created.
 * @returns {string}  A message warning about missing or wrong values.
 */
export const getLoanApplicationPopupWarning = async ({
  customer = {},
  provider = "",
}) => {
  let warningMsg = "";

  // Checks if debt amount is selected and valid
  const totalDebtAmount = getTotalDebtAmount(customer);

  if (totalDebtAmount < 500 || totalDebtAmount > 100000) {
    warningMsg = buildWarningMessage(
      warningMsg,
      "Debt Amount (Needs to be btw $500, $100,000)"
    );
  }

  // Checks if SSN is selected and valid
  const ssn = await utilitiesMixin.methods.AES256_GCM_decrypt(
    customer.CustomerProfile.SsnEncrypted
  );
  if (!ssn.length) {
    warningMsg = buildWarningMessage(warningMsg, "SSN");
  }

  // According to provider, checks if full name is valid
  if (provider === EARLY_LOAN_PROVIDER.EVVO) {
    if (
      !isOnlyAlphaChars(customer.CustomerProfile.FirstName) ||
      !isOnlyAlphaChars(customer.CustomerProfile.LastName)
    ) {
      warningMsg = buildWarningMessage(
        warningMsg,
        "First Name and Last Name fields only accept alphabetic characters"
      );
    }
  }
  if (customer.CustomerProfile.LastName.length < 2) {
    warningMsg = buildWarningMessage(warningMsg, "Last Name");
  }

  if (customer.CustomerProfile.CustomerEmails.length == 0) {
    warningMsg = buildWarningMessage(warningMsg, "Email Address");
  }

  if (customer.CustomerProfile.CustomerPhones.length == 0) {
    warningMsg = buildWarningMessage(warningMsg, "Phone Number");
  }

  // Checks if birth date is selected and valid
  if (
    !customer.CustomerProfile.DateOfBirth ||
    (customer.CustomerProfile.DateOfBirth &&
      customer.CustomerProfile.DateOfBirth.length == 0)
  ) {
    warningMsg = buildWarningMessage(warningMsg, "Date Of Birth");
  }

  // Checks if annual income is valid
  const annualIncome = getCustomerAnnualIncome(customer);
  if (annualIncome == 0) {
    warningMsg = buildWarningMessage(warningMsg, "Annual Income");
  }

  // Checks if address is selected and valid
  if (
    !customer.CustomerProfile.Address?.Line1 ||
    (customer.CustomerProfile.Address?.Line1 &&
      customer.CustomerProfile.Address?.Line1.length == 0)
  ) {
    warningMsg = buildWarningMessage(warningMsg, "Address");
  }

  // Checks if city is selected and valid
  if (
    !customer.CustomerProfile.Address?.City ||
    (customer.CustomerProfile.Address?.City &&
      customer.CustomerProfile.Address?.City.length == 0)
  ) {
    warningMsg = buildWarningMessage(warningMsg, "City");
  }

  // Checks if state is selected and valid

  let shortState = "";
  if (
    customer.CustomerProfile.Address?.StateId &&
    parseInt(customer.CustomerProfile.Address?.StateId) > 0
  ) {
    shortState = getStateShort(customer.CustomerProfile.Address?.StateId);
  }
  if (shortState.length == 0) {
    warningMsg = buildWarningMessage(warningMsg, "State");
  }

  // Checks if zip code is selected and valid
  if (
    !customer.CustomerProfile.Address?.Zip ||
    (customer.CustomerProfile.Address?.Zip &&
      customer.CustomerProfile.Address?.Zip.length == 0)
  ) {
    warningMsg = buildWarningMessage(warningMsg, "Zip Code");
  }

  return warningMsg;
};

/**
 * Retrieves the short code for a given state based on its state ID.
 *
 * @param {number|string} stateId - The ID of the state for which to retrieve the short code.
 * @returns {string} Returns the short code of the state if found; otherwise, returns an empty string.
 */
const getStateShort = (stateId) => {
  const states = store.state.states;
  let stateShort = "";
  let tmpStates = states.filter((x) => x.Id == stateId);
  if (tmpStates.length > 0) {
    stateShort = tmpStates[0].ShortCode;
  }

  return stateShort;
};

/**
 * Constructs a warning message by combining an old message with a new message.
 *
 * @param {string} oldMsg - The existing warning message to which the new message will be appended.
 * @param {string} newMsg - The new message to add to the existing warning message.
 * @returns {string} Returns the combined warning message. If the old message is not empty, a comma is added before the new message.
 */
const buildWarningMessage = (oldMsg, newMsg) => {
  let rtn = oldMsg;
  if (rtn.length > 0) {
    rtn = rtn + ", ";
  }
  rtn = rtn + newMsg;
  return rtn;
};

/**
 * Checks coCustomer and customer income to get Annual Income.
 *
 * @param {object} customer - The whole customer object.
 * @returns {number} Returns the total annual income for customer.
 */
const getCustomerAnnualIncome = (customer) => {
  let annualIncome = 0;
  let totalCoCustomerIncome = 0;

  if (customer.CoCustomerProfiles.length) {
    let includedCoCustomers = customer.CoCustomerProfiles.filter(
      (x) => !x.IsExcluded
    );

    for (let item of includedCoCustomers) {
      totalCoCustomerIncome += parseFloat(item.Income);
    }
  }

  annualIncome = totalCoCustomerIncome;

  if (
    customer.CustomerProfile.Income &&
    parseFloat(customer.CustomerProfile.Income) > 0
  ) {
    annualIncome += parseFloat(customer.CustomerProfile.Income);
  }

  annualIncome = annualIncome * 12;

  return annualIncome;
};

/**
 * Checks customer total debt amount.
 *
 * @param {object} customer - The whole customer object.
 * @returns {number} Returns the debt amount for customer.
 */
const getTotalDebtAmount = (customer) => {
  let totalAmount = 0;
  if (
    customer.CustomerProfile.DebtAmount &&
    parseInt(customer.CustomerProfile.DebtAmount) > 0
  ) {
    totalAmount = parseFloat(customer.CustomerProfile.DebtAmount);
  } else if (
    customer.CreditorTotalBalance &&
    parseInt(customer.CreditorTotalBalance) > 0
  ) {
    totalAmount = parseFloat(customer.CreditorTotalBalance);
  }

  return totalAmount;
};

/**
 * Sets all the necessary loan values for the early loan popup.
 *
 * @param {Object} customer - The customer object containing relevant information for the loan application.
 * @returns {Promise<void>} Returns a promise that resolves when the loan application popup values have been set.
 */
export const setLoanApplicationPopupValues = async ({
  customer = {},
  provider = "",
}) => {
  const data = {};

  const noPhoneNumberProvider = [
    EARLY_LOAN_PROVIDER.EVVO,
    EARLY_LOAN_PROVIDER.PREMLO,
  ];

  data.CustomerId = customer.Id;
  data.FirstName = customer.CustomerProfile.FirstName;
  data.LastName = customer.CustomerProfile.LastName;

  let emails = orderBy(
    customer.CustomerProfile.CustomerEmails,
    "Priority",
    "asc"
  );
  if (emails.length > 0) data.Email = emails[0].Email;

  let phones = orderBy(
    customer.CustomerProfile.CustomerPhones,
    "Priority",
    "asc"
  );

  if (phones.length > 0) {
    if (noPhoneNumberProvider.includes(provider)) {
      data.HomePhone = phones.find(
        (phone) => phone.PhoneTypeId === 2
      )?.PhoneNumber;
      data.CellPhone = phones.find(
        (phone) => phone.PhoneTypeId === 1
      )?.PhoneNumber;
    } else {
      data.PhoneNumber = phones[0].PhoneNumber;
    }
  }

  data.DateOfBirth = customer.CustomerProfile.DateOfBirth;
  data.TotalAmount = getTotalDebtAmount(customer);
  data.AnnualIncome = getCustomerAnnualIncome(customer);
  data.Address = `${customer.CustomerProfile.Address.Line1} ${
    customer.CustomerProfile.Address?.Line2 ?? ""
  }`;
  data.City = customer.CustomerProfile.Address.City;
  data.State = getStateShort(customer.CustomerProfile.Address.StateId);
  data.Zip = customer.CustomerProfile.Address.Zip;
  data.Ssn = await utilitiesMixin.methods.AES256_GCM_decrypt(
    customer.CustomerProfile.SsnEncrypted
  );
  data.DebtAmount = customer.CustomerProfile.DebtAmount;
  data.OwnHome = customer.CustomerProfile.ResidenceOwnershipId == 1;

  return data;
};
