/**
 * @file textHelpers.js
 * @description This file contains helper functions for text validation and manipulation.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 */

/**
 * Checks if a given string contains only alphabetic characters (A-Z, a-z).
 *
 * @param {string} str - The string to check.
 * @returns {boolean} Returns true if the string contains only alphabetic characters; otherwise, false.
 */
export const isOnlyAlphaChars = (str) => {
  const regex = /^[a-zA-Z ]*$/;
  return regex.test(str);
};
