import "@/assets/style/bootstrap.scss";
import "@/assets/style/custom.scss";
import "@/assets/style/datepicker.css";
import "@/assets/style/fullcalendar.min.css";
import "@/assets/style/icons.min.css";
import "@/assets/style/icons.scss";
import "@/assets/style/loader.css";
import "@/assets/style/main.scss";
import "@/assets/style/timeline.min.css";
import koPaths from "@/common/koPaths.json";
import Oruga from "@oruga-ui/oruga-next";
import "@oruga-ui/oruga-next/dist/oruga-full.css";
import "animate.css";
import axios from "axios";
import vClickOutside from "click-outside-vue3";
import datejs from "datejs";
import inputmask from "inputmask";
import mitt from "mitt";
import PrimeVue from "primevue/config";
import InputText from "primevue/inputtext";
import "primevue/resources/themes/aura-light-blue/theme.css";
import "sweetalert2/dist/sweetalert2.min.css";
import { createApp } from "vue";
import "vue-swatches/dist/vue-swatches.css";
import vueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue3-apexcharts";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import Vue3TouchEvents from "vue3-touch-events";
import "vue3-ts-picker/dist/vue3-ts-picker.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueApexCharts)
  .use(PrimeVue)
  .use(Vue3TouchEvents)
  .use(PerfectScrollbar)
  .use(vClickOutside)
  .use(Oruga, {
    table: {
      override: true,
      mobileCards: false,
      wrapperClass: "b-table table-wrapper",
      tableClass: "table",
      borderedClass: "is-bordered",
      stripedClass: "is-striped",
      narrowedClass: "is-narrow",
      stickyHeaderClass: "is-sticky-header",
      hoverableClass: "is-hoverable",
      emptyClass: "is-empty",
      detailedClass: "detail",
      footerClass: "table-footer",
      scrollableClass: "is-scrollable",
      trSelectedClass: "is-selected",
      thSortableClass: "is-sortable",
    },
  })
  .use(vueSweetalert2)
  .component("InputText", InputText)
  .use(datejs);

app.provide("emitter", mitt());

app.config.globalProperties.$loginLogo = `${
  process.env.VUE_APP_IS_DEVELOPMENT == "true"
    ? process.env.VUE_APP_API_DEV
    : process.env.VUE_APP_API
}/api/DomainLogo/`;
app.config.globalProperties.$logo = "";
app.config.globalProperties.$MaxAutoAcceptCallIndex = 14;
app.config.globalProperties.$Emitter = mitt();
app.config.globalProperties.$CsmVersion = process.env.VUE_APP_CRM_VERSION;
window.MyPeer = null;

app.config.globalProperties.$ServiceBus = null;
app.config.globalProperties.$ServiceBusAdmin = null;
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith("array-");
};

app.config.globalProperties.$koPaths = koPaths;

axios
  .get(
    `${
      process.env.VUE_APP_IS_DEVELOPMENT == "true"
        ? process.env.VUE_APP_API_DEV
        : process.env.VUE_APP_API
    }/api/DomainLogo/`
  )
  .then((res) => {
    app.config.globalProperties.$loginLogo = res.request.responseURL;
    app.config.globalProperties.$logo =
      app.config.globalProperties.$loginLogo.replace(
        /(\.[\w\d_-]+)$/i,
        "-light$1"
      );
  })
  .catch((err) => {});

app.mount("#app");

app.directive("showIfAuthorized", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (!arrPermission.includes(parseInt(binding.value))) {
        //vnode.elm.parentElement.removeChild(vnode.elm);
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });

        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;
        //vnode.data.directives = null;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("enableIfAuthorized", {
  beforeMount(el, binding) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (!arrPermission.includes(parseInt(binding.value))) {
        if (el.tagName === "INPUT" && el.type === "text") {
          el.setAttribute("readonly", "");
          el.style.opacity = 0.5;
        }
        el.setAttribute("disabled", "'disabled'");
        el.style.opacity = 0.5;
      }
    }
  },
});
app.directive("myMask", {
  mounted(el, binding) {
    let maskOpts = binding.value;
    maskOpts.showMaskOnHover = false;
    maskOpts.autoUnmask = true;
    inputmask(maskOpts).mask(el);
  },
  unmounted() {},
});
app.directive("showAdminText", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrAdmin = [
        1453, 9542, 5634, 5146, 3432, 8913, 8914, 5377, 6580, 952, 8543, 1580,
        1496, 3841, 1662, 2774, 8361, 3891, 3901, 1588, 9977, 64326, 3908, 3917,
        3922, 240, 273, 645644, 921085, 81451, 10032, 1595, 81548, 3923, 921195,
        58089,
      ];

      for (let value of arrAdmin) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showClientSettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [
        1096, 952, 5146, 3432, 1580, 5634, 1588, 64326, 55840, 1595,
      ];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showEmployeeSettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [1662, 9542, 1496, 6580, 6581, 3841];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showCompanySettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [
        1453, 2774, 8361, 3891, 9977, 645644, 921085, 921195, 58089,
      ];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showDashboardSettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [81451];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showDocusignSettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [8543, 645643];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showTasksModule", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [7470, 9127, 8593, 10022, 12400, 200010];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showBulkOperations", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [7525, 7535];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showReports", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [
        3590, 9327, 8113, 8263, 7261, 3715, 5366, 3591, 5578, 8162, 3821, 6180,
        7452, 6582, 7567, 9547, 9677, 9713, 8272, 8275, 54345, 64325, 45324,
        46354, 4721, 97695, 61346, 33453, 810245, 55002, 55003, 81547, 55005,
        55006, 55008, 70002, 70006, 3594, 5819, 5820, 3712, 97696, 55417, 54000,
        54001, 54002, 54003, 54004, 54005, 8234, 3995, 48214, 7453, 55836,
        55840, 55009, 9714, 55010, 55419, 55418, 3881, 81401, 55057, 33454,
        54985, 54993, 54989, 54348, 54350, 54006, 54007, 54008, 54009, 54010,
        54021, 54015, 55021, 54147, 966234, 54999, 67088,
      ];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
app.directive("showPhoneSettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [3908, 3917, 3922, 240, 273, 54984];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});

app.directive("showDocumentTypeSettings", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [1456];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});

app.directive("showDataUpload", {
  mounted(el, binding, vnode) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      let showText = false;
      let arrShow = [311,312,313];

      for (let value of arrShow) {
        if (arrPermission.includes(value)) {
          showText = true;
          break;
        }
      }

      if (!showText) {
        const comment = document.createComment(" ");
        Object.defineProperty(comment, "setAttribute", {
          value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = " ";
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;

        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
          el.parentNode.replaceChild(comment, el);
        }
      }
    }
  },
});
