<template>
  <div class="col-md-12 noPadding" style="background-color: #ffffff">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 275px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>

    <div
      v-if="hoveredCreditorsId > 0"
      class="hoverPanel"
      :style="
        infoHoverPanelPosition + ' height:' + (infoHoverPanelHeight + 2) + 'px;'
      "
    >
      <ClientCreditorsHoverPopup
        v-if="creditorInfoReady"
        :infoList="CreditorModifiedListAfterEnrollment(hoveredCreditorsId)"
      />
    </div>

    <div
      class="backgroundLock"
      v-if="isInfoPopUp && creditorInfoReady && clickedCreditorId > 0"
    >
      <div
        style="
          position: relative;
          margin: 2% auto;
          min-height: 520px;
          max-height: 90%;
          overflow: auto;
        "
        class="offset-lg-12 col-xl-5 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <ClientCreditorsInfoPopup
          :infoList="CreditorModifiedListAfterEnrollment(clickedCreditorId)"
          :dateAdded="clickedDateAdded"
          :creditorName="clickedCreditorName"
          :creditorId="clickedCreditorId"
          @closeInfoPopup="closeInfoPopup"
        ></ClientCreditorsInfoPopup>
      </div>
    </div>

    <div class="form-group row mt-2">
      <div class="col-md-12" style="display: flex; align-items: center">
        <h4 class="generalLabel">Creditors</h4>
        <div
          v-if="showLawSuitIndicator()"
          class="ml-2"
          style="display: flex; align-items: center"
        >
          <div
            style="
              width: 14px;
              height: 14px;
              background-color: #f1bfbf;
              margin-left: 20px;
              border-radius: 2px;
            "
          ></div>
          <h5 class="generalLabel ml-1">Lawsuit</h5>
        </div>
        <div
          v-if="showSettledIndicator()"
          style="display: flex; align-items: center"
        >
          <div
            style="
              width: 14px;
              height: 14px;
              background-color: #63d294;
              margin-left: 20px;
              border-radius: 2px;
            "
          ></div>
          <h5 class="generalLabel ml-1">Settled</h5>
        </div>
        <div
          v-if="showEgAttorneyIndicator()"
          class="ml-2"
          style="display: flex; align-items: center"
        >
          <div
            style="
              width: 14px;
              height: 14px;
              background-color: #f1bfbf;
              margin-left: 20px;
              border-radius: 2px;
            "
          ></div>
          <h5 class="generalLabel ml-1">EG Attorney</h5>
        </div>
        <div
          v-if="showSummonsIndicator()"
          style="display: flex; align-items: center"
        >
          <div
            style="
              width: 14px;
              height: 14px;
              background-color: #ffbc52;
              margin-left: 20px;
              border-radius: 2px;
            "
          ></div>
          <h5 class="generalLabel ml-1">Summons</h5>
        </div>
        <div
          v-if="showEGandLawIndicator()"
          class="ml-2"
          style="display: flex; align-items: center"
        >
          <div
            style="
              width: 14px;
              height: 14px;
              background-color: #f1bfbf;
              margin-left: 20px;
              border-radius: 2px;
            "
          ></div>
          <h5 class="generalLabel ml-1">Lawsuit/EG Attorney</h5>
        </div>

        <div
          v-if="show1099CIndicator()"
          class="ml-2"
          style="display: flex; align-items: center"
        >
          <div
            style="
              width: 14px;
              height: 14px;
              background-color: #9fa8da;
              margin-left: 20px;
              border-radius: 2px;
            "
          ></div>
          <h5 class="generalLabel ml-1">1099C</h5>
        </div>

        <div
          v-if="settlementVoidedIndicator()"
          class="ml-2"
          style="display: flex; align-items: center"
        >
          <div
            style="
              width: 14px;
              height: 14px;
              background-color: #f1556c;
              margin-left: 20px;
              border-radius: 2px;
            "
          ></div>
          <h5 class="generalLabel ml-1">Voided</h5>
        </div>
      </div>
    </div>
    <div
      v-if="editSpinner"
      class="col-md-12"
      style="position: absolute; left: 45%; top: 100px; z-index: 3"
    >
      <i class="spinner-border avatar-lg text-primary"></i>
    </div>
    <div
      class="table-responsive newPanelWrapper"
      style="
        box-shadow: 0 0 1px 1px #d1d1d1 !important;
        padding: 1px !important;
        font-size: 12px !important;
      "
    >
      <form style="height: 50vh" v-on:keydown.esc.prevent="resetEdit(null)">
        <table
          v-if="!isLoading"
          v-click-outside="outsideOfNewRow"
          class="datagrid-table creditorTable pb-4"
          id="btn-editable-cc"
          style="
            width: 220%;
            border: none !important;
            background-color: rgb(255, 255, 255);
          "
        >
          <thead>
            <tr>
              <th width="1%" class="stickyColumnsLeft" style="z-index: 201">
                #
              </th>
              <th width="8%" class="stickyColumnsRight" style="z-index: 201">
                Creditor Name
              </th>
              <th width="7%">Curr. Cr. Name</th>
              <th @click="toggleSortOrder()" width="7%" style="cursor: pointer">
                Original Balance
                <i v-if="sortByBalance == null" class="fas fa-sort"></i>
                <i v-if="sortByBalance" class="fas fa-sort-numeric-down"></i>
                <i
                  v-if="!sortByBalance && sortByBalance != null"
                  class="fas fa-sort-numeric-down-alt"
                ></i>
              </th>
              <th width="7%">Curr. Cr. Balance</th>
              <th width="6%">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                  "
                >
                  <label>Account#</label>
                  <i
                    v-if="
                      instaTouchEnabled &&
                      sortedCreditors &&
                      sortedCreditors.length > 0
                    "
                    type="button"
                    title="Get Full Card Numbers"
                    class="btn"
                    style="color: rgb(59, 175, 218)"
                    @click="OpenInstaTouchPopUp()"
                  >
                    <i class="fas fa-credit-card" style="font-size: 15px"></i>
                  </i>
                </div>
              </th>
              <th width="7%">Curr. Cr. Acct#</th>
              <th width="7%">Account Holder</th>
              <th width="7%">Account Type</th>
              <th width="6%">Status</th>
              <th width="7%">Negotiator</th>
              <th width="6%">Monthly Payment</th>
              <th width="7%">Neg. Phase</th>
              <th width="12%">Last Payment Date</th>
              <th width="6%">Date Added</th>
              <th v-if="hasTermSettlementStatus" width="6%">
                Term Sett. Start
              </th>
              <th v-if="hasTermSettlementStatus" width="6%">Term Sett. End</th>

              <th width="4%">Offer</th>
              <th width="4%">%</th>
              <th width="4%">Fees</th>
              <th width="8%" v-if="checkAuth(10048)">POA Sent Date</th>
              <th width="4%" v-if="checkAuth(10048)">Disable POA Automation</th>
              <th width="4%">Late 3 Yrs?</th>
              <th width="8%">Settled By</th>
              <th v-if="checkAuth(53345) || checkAuth(54332)">Attorney Name</th>
              <th v-if="checkAuth(53345) || checkAuth(54332)">EG Attorney</th>

              <th width="1%"></th>

              <th
                class="stickyColumns"
                v-if="!blockMakingChange && sortedCreditors.length > 0"
              >
                <span title="Info" v-if="!isNewRowActive && checkAuth(8270)"
                  >Info</span
                >
                <span title="Payments" v-if="!isNewRowActive"> Pmts</span>
                <span v-if="!isNewRowActive && checkAuth(7193)"> Settle</span>
                <span v-if="!isNewRowActive && checkAuth(325657)"> Notes</span>
                <span v-if="!isNewRowActive && checkAuth(10030)">
                  &nbsp;eFax &nbsp;&nbsp;</span
                >
              </th>
            </tr>

            <tr v-if="isNewRowActive">
              <th class="thPosition"></th>
              <th class="thPosition">
                <!-- <input
                      v-if="!checkAuth(55004)"
                    class="generalTextBox form-control"
                    id="NewNameRowInp"
                    type="text"
                    name="col1"
                    v-model="selectedRow.Name"
                    :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
                    @blur="v$.selectedRow.Name.$touch"
                    v-on:keydown.enter.prevent="handleNewRowSave"
                    autocomplete="off"
                  /> -->

                <input
                  class="generalTextBox form-control"
                  id="NewNameRowInp"
                  type="text"
                  name="col1"
                  v-model="selectedRow.Name"
                  @keydown="openSuggestBox"
                  @blur="
                    suggestBoxBlur(selectedRow.Name) ||
                      checkBlockedCreditorNames(selectedRow) ||
                      v$.selectedRow.Name.$touch
                  "
                  autocomplete="off"
                  :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
                  v-on:keydown.enter.prevent="handleNewRowSave"
                />

                <div
                  v-if="suggestBoxOpen"
                  v-click-outside="closeSuggestBoxPopup"
                  class="datagrid-table"
                  style="
                    position: absolute;
                    top: 58px;
                    left: 15px;
                    z-index: 1000;
                  "
                >
                  <SuggestBox
                    :list="suggestCreditorNames"
                    :searchParam="selectedRow.Name"
                    @assignCreditorName="assignCreditorName"
                  >
                  </SuggestBox>
                </div>
              </th>
              <th class="thPosition">
                <input
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.CurrentCreditorName = '';
                    }
                  "
                  class="generalTextBox form-control"
                  id="NewCurCreditorNameRowInp"
                  type="text"
                  v-model="selectedRow.CurrentCreditorName"
                  v-on:keydown.enter.prevent="handleNewRowSave"
                  @keydown="openCCNSuggestBox"
                  @blur="suggestBoxBlur(selectedRow.CurrentCreditorName)"
                  autocomplete="off"
                />
                <div
                  v-if="ccnSuggestBoxOpen"
                  class="datagrid-table"
                  style="position: absolute; top: 58px; z-index: 1000"
                >
                  <SuggestBox
                    :list="suggestCreditorNames"
                    :searchParam="selectedRow.CurrentCreditorName"
                    @assignCreditorName="assignCCN"
                  >
                  </SuggestBox>
                </div>
              </th>
              <th class="thPosition">
                <input
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.OriginalBalance = 0;
                    }
                  "
                  class="generalTextBox form-control"
                  id="NewBalanceRowInp"
                  type="text"
                  name="col3"
                  v-model="selectedRow.OriginalBalance"
                  :class="
                    v$.selectedRow.OriginalBalance.$error ? 'has-error' : ''
                  "
                  @blur="v$.selectedRow.OriginalBalance.$touch"
                  v-on:keydown.enter.prevent="handleNewRowSave"
                />
              </th>
              <th class="thPosition">
                <input
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.CurrentCreditorBalance = 0;
                    }
                  "
                  class="generalTextBox form-control"
                  id="NewCurrBalanceRowInp"
                  type="text"
                  v-model="selectedRow.CurrentCreditorBalance"
                  v-on:keydown.enter.prevent="handleNewRowSave"
                />
              </th>
              <th class="thPosition">
                <input
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.AccountNumber = '';
                    }
                  "
                  class="generalTextBox form-control"
                  id="NewAccNumRowInp"
                  type="text"
                  name="col2"
                  v-model="selectedRow.AccountNumber"
                  v-on:keydown.enter.prevent="handleNewRowSave"
                />
              </th>
              <th class="thPosition">
                <input
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.CurrentCreditorAccountNumber = '';
                    }
                  "
                  class="generalTextBox form-control"
                  id="NewCurrAcctRowInp"
                  type="text"
                  v-model="selectedRow.CurrentCreditorAccountNumber"
                  v-on:keydown.enter.prevent="handleNewRowSave"
                />
              </th>

              <th class="thPosition">
                <select
                  id="NewAcctHoldRowInp"
                  v-model="selectedRow.AccountHolderId"
                  class="form-control generalSelectBox"
                  :class="
                    v$.selectedRow.AccountHolderId.$error ? 'has-error' : ''
                  "
                  @blur="v$.selectedRow.AccountHolderId.$touch"
                >
                  <option value="0">Select</option>
                  <option
                    v-for="item in accountHolders"
                    :value="item.Id"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </th>
              <th class="thPosition">
                <select
                  id="NewAcctTypeRowInp"
                  v-model="selectedRow.AccountTypeId"
                  class="form-control generalSelectBox"
                >
                  <option value="0">Select</option>
                  <option
                    v-for="item in accountTypes"
                    :value="item.Id"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </th>
              <th class="thPosition">
                <select
                  id="NewStatusRowInp"
                  v-model="selectedRow.CreditorStatusId"
                  class="form-control generalSelectBox"
                  :disabled="!checkAuth(3443)"
                >
                  <option value="0">Select</option>
                  <option
                    v-for="item in creditorStatuses"
                    :disabled="!isLimitedStatus(item.Id)"
                    :class="!isLimitedStatus(item.Id) ? 'readonlyInput' : ''"
                    :value="item.Id"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </th>

              <th class="thPosition">
                <select
                  id="assignedNegotiator"
                  v-model="selectedRow.NegotiatorId"
                  :disabled="!checkAuth(2347)"
                  class="form-control generalSelectBox"
                >
                  <option :value="null">Select</option>
                  <option
                    v-for="item in filteredNegotiators"
                    :value="item.Id"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </th>
              <th class="thPosition">
                <input
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.MonthlyPayment = 0;
                    }
                  "
                  class="generalTextBox form-control"
                  id="NewMonPayRowInp"
                  type="text"
                  name="col3"
                  v-model="selectedRow.MonthlyPayment"
                  v-on:keydown.enter.prevent="handleNewRowSave"
                />
              </th>
              <th class="thPosition">
                <span>{{ selectedRow.NegotiationPhase.Name }}</span>
              </th>
              <th class="thPosition">
                <DatePicker
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.LastPaymentDate = null;
                    }
                  "
                  mode="date"
                  :locale="'en-US'"
                  :available-dates="datepickerConfig"
                  v-model="selectedRow.LastPaymentDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="DateOfBirthOfTaskPopup"
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </DatePicker>
                {{ getLastPaymentDay(selectedRow.LastPaymentDate) }}
              </th>
              <th class="thPosition"></th>
              <th class="thPosition" v-if="hasTermSettlementStatus"></th>
              <th class="thPosition" v-if="hasTermSettlementStatus"></th>

              <th class="thPosition"></th>
              <th class="thPosition"></th>
              <th class="thPosition"></th>
              <th class="thPosition" v-if="checkAuth(10048)">
                <DatePicker
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.PoaSentDate = null;
                    }
                  "
                  mode="date"
                  :locale="'en-US'"
                  :available-dates="{ start: new Date() }"
                  v-model="selectedRow.PoaSentDate"
                  style="min-width: 90px !important"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </DatePicker>
              </th>
              <th class="thPosition" v-if="checkAuth(10048)">
                <input
                  class="generalTextBox form-control"
                  type="checkbox"
                  v-model="selectedRow.PoaAutomation"
                />
              </th>
              <th class="thPosition">
                <input
                  class="generalTextBox form-control"
                  id="NewLast3YrsRowInp"
                  type="checkbox"
                  v-model="selectedRow.Delinquent3Years"
                />
              </th>
              <th></th>
              <th
                v-if="checkAuth(53345) || checkAuth(54332)"
                class="thPosition"
              ></th>

              <th
                v-if="checkAuth(53345) || checkAuth(54332)"
                class="thPosition"
              ></th>
              <th></th>
              <th colspan="2" class="stickyColumns">
                <div style="display: flex; gap: 10px">
                  <button
                    :disabled="v$.$invalid || myInvalid"
                    type="button"
                    class="btn float-right"
                    style="background-color: #24d15f"
                    v-on:click.prevent="handleNewRowSave()"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button
                    type="button"
                    title="Cancel"
                    class="btn float-right"
                    style="background-color: #ed3030"
                    v-on:click.prevent.stop="() => handleCancelNewRow()"
                  >
                    <i class="mdi mdi-cancel"></i>
                  </button>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-if="!blockMakingChange && !isNewRowActive"
              style="background-color: #ffffff !important"
            >
              <td colspan="4" class="col-md-12">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <button
                    v-if="checkAuth(6355)"
                    type="button"
                    title="Delete"
                    class="addNewButton"
                    :disabled="saving"
                    v-on:click.prevent.stop="() => startNewRow()"
                  >
                    <i style="font-size: 18px" class="fe-plus-circle"></i>
                    Add New Row
                  </button>
                </div>
              </td>

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td v-if="hasTermSettlementStatus"></td>
              <td v-if="hasTermSettlementStatus"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td v-if="checkAuth(10048)"></td>
              <td v-if="checkAuth(10048)"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr
              v-for="(item, index) in sortedCreditors"
              :key="item.Id"
              :class="{
                'highlighted-row':
                  (item.CreditorStatusId == 4 || item.CreditorStatusId == 18) &&
                  !item.isEditing,
              }"
            >
              <td
                style="
                  padding-left: 30px;
                  padding-right: 10px;
                  white-space: nowrap;
                  width: 1%;
                "
                class="stickyColumnsLeft"
              >
                <span class="tabledit-span tabledit-identifier">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      height: 100%;
                      position: absolute;
                      left: 0;
                      top: 0;
                      padding: 5px 0;
                    "
                  >
                    <div
                      v-if="
                        item.IsLawSuit ||
                        (item.EgAttorney &&
                          (checkAuth(53345) || checkAuth(54332)))
                      "
                      style="
                        width: 25px;
                        height: 32%;
                        display: block;
                        background-color: #f1bfbf;
                        margin-bottom: 1px;
                        border-radius: 2px;
                        flex: auto;
                      "
                    ></div>
                    <div
                      v-if="item.CreditorStatusId == 2"
                      style="
                        width: 25px;
                        height: 32%;
                        display: block;
                        background-color: #63d294;
                        margin-bottom: 1px;
                        border-radius: 2px;
                        flex: auto;
                      "
                    ></div>
                    <div
                      v-if="
                        item.CreditorStatusId == 5 ||
                        item.CreditorStatusId == 8 ||
                        item.CreditorStatusId == 11 ||
                        item.CreditorStatusId == 9 ||
                        item.CreditorStatusId == 6
                      "
                      style="
                        width: 25px;
                        height: 32%;
                        display: block;
                        background-color: #ffbc52;
                        margin-bottom: 1px;
                        border-radius: 2px;
                        flex: auto;
                      "
                    ></div>
                    <div
                      v-if="item.CreditorStatusId == 10"
                      style="
                        width: 25px;
                        height: 32%;
                        display: block;
                        background-color: #9fa8da;
                        margin-bottom: 1px;
                        border-radius: 2px;
                        flex: auto;
                      "
                    ></div>

                    <div
                      v-if="item.CreditorStatusId == 19"
                      style="
                        width: 25px;
                        height: 32%;
                        display: block;
                        background-color: #f1556c;
                        margin-bottom: 1px;
                        border-radius: 2px;
                        flex: auto;
                      "
                    ></div>
                  </div>

                  {{ index + 1 }}.
                </span>
              </td>
              <td class="stickyColumnsRight" style="min-width: 130px">
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  <span>
                    {{ item.Name }}
                  </span>
                </span>
                <div
                  v-if="
                    !(
                      item.ClearedFeePerc == null &&
                      item.ClearedPaymentPerc == null
                    ) &&
                    !(item.ClearedFeePerc == 0 && item.ClearedPaymentPerc == 0)
                  "
                >
                  <div
                    v-if="!item.isEditing"
                    class="status-container"
                    style="margin-top: 5px"
                  >
                    <div
                      class="status-bar"
                      style="background-color: #638889"
                      :style="getStatusStyle(item.ClearedPaymentPerc)"
                    ></div>
                    <span class="percentage-label" style="position: absolute"
                      >Sett. Pmts
                    </span>
                    <span
                      class="percentage-label"
                      style="position: absolute; right: 2px"
                      >{{
                        item.ClearedPaymentPerc ? item.ClearedPaymentPerc : 0
                      }}%</span
                    >
                  </div>

                  <div
                    v-if="!item.isEditing"
                    class="status-container"
                    style="margin-top: 5px"
                  >
                    <div
                      class="status-bar"
                      style="background-color: #9dbc98"
                      :style="getStatusStyle(item.ClearedFeePerc)"
                    ></div>
                    <span class="percentage-label" style="position: absolute"
                      >Sett. Fees</span
                    >
                    <span
                      class="percentage-label"
                      style="position: absolute; right: 2px"
                      >{{
                        item.ClearedFeePerc ? item.ClearedFeePerc : 0
                      }}%</span
                    >
                  </div>
                </div>

                <!-- <input
                    class="generalTextBox form-control"
                    type="text"
                    v-model="selectedRow.Name"
                    v-show="item.isEditing && !checkAuth(55004)"
                    :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
                    @blur="v$.selectedRow.Name.$touch"
                    v-on:keydown.enter.prevent="handleUpdate(item)"
                  /> -->
                <input
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.Name = '';
                    }
                  "
                  class="generalTextBox form-control"
                  v-show="item.isEditing"
                  type="text"
                  v-model="selectedRow.Name"
                  @keydown="openSuggestBox"
                  v-on:keydown.enter.prevent="handleUpdate(item)"
                  @blur="
                    suggestBoxBlur(selectedRow.Name) ||
                      v$.selectedRow.Name.$touch
                  "
                  :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
                  autocomplete="off"
                  :readonly="!canEdit"
                />
                <div
                  v-if="suggestBoxOpen && item.isEditing && canEdit"
                  class="suggest-box-arrow"
                ></div>
                <div
                  v-if="suggestBoxOpen && item.isEditing && canEdit"
                  v-click-outside="closeSuggestBoxPopup"
                  class="suggest-box"
                >
                  <SuggestBox
                    :list="suggestCreditorNames"
                    :searchParam="selectedRow.Name"
                    @assignCreditorName="assignCreditorName"
                  >
                  </SuggestBox>
                </div>
              </td>

              <td style="position: relative">
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  {{ item.CurrentCreditorName }}
                </span>
                <!-- <input
                    class="generalTextBox form-control"
                    type="text"
                    v-model="selectedRow.CurrentCreditorName"
                    v-show="item.isEditing"
                    v-on:keydown.enter.prevent="handleUpdate(item)"
                  /> -->

                <input
                  v-on:keydown.esc.prevent="
                    () => {
                      selectedRow.CurrentCreditorName = '';
                    }
                  "
                  class="generalTextBox form-control"
                  v-show="item.isEditing"
                  type="text"
                  v-model="selectedRow.CurrentCreditorName"
                  @keydown="openCCNSuggestBox"
                  @blur="suggestBoxBlur(selectedRow.CurrentCreditorName)"
                  autocomplete="off"
                  :readonly="!canEdit"
                />

                <div
                  v-if="ccnSuggestBoxOpen && item.isEditing && canEdit"
                  v-click-outside="closeCCNSuggestBoxPopup"
                  class="datagrid-table"
                  :style="getSuggestBoxPosition(index)"
                >
                  <SuggestBox
                    :list="suggestCreditorNames"
                    :searchParam="selectedRow.CurrentCreditorName"
                    @assignCreditorName="assignCCN"
                  >
                  </SuggestBox>
                </div>
              </td>
              <td>
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  {{ formatMoney(item.OriginalBalance) }}
                </span>
                <input
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  :disabled="!canEditOriginalBalance()"
                  class="generalTextBox form-control"
                  type="text"
                  v-model="selectedRow.OriginalBalance"
                  v-show="item.isEditing"
                  v-on:keydown.enter.prevent="handleUpdate(item)"
                  :class="
                    v$.selectedRow.OriginalBalance.$error ? 'has-error' : ''
                  "
                  @blur="v$.selectedRow.OriginalBalance.$touch"
                  :readonly="!canEdit"
                />
              </td>
              <td>
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  {{ this.formatMoney(item.CurrentCreditorBalance) }}
                </span>
                <input
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  class="generalTextBox form-control"
                  type="text"
                  :disabled="!canEditCurrentCreditorBalance()"
                  v-model="selectedRow.CurrentCreditorBalance"
                  v-show="item.isEditing"
                  v-on:keydown.enter.prevent="handleUpdate(item)"
                  :readonly="!canEdit"
                />
              </td>
              <td>
                <div style="display: flex; align-items: center">
                  <span
                    class="tabledit-span tabledit-identifier"
                    v-show="!item.isEditing"
                  >
                    {{ item.AccountNumber }}
                  </span>
                  <input
                    class="generalTextBox form-control"
                    type="text"
                    v-model="selectedRow.AccountNumber"
                    v-show="item.isEditing"
                    v-on:keydown.enter.prevent="handleUpdate(item)"
                    :readonly="!canEdit && !canOnlyEditAcc"
                  />
                  <i
                    v-if="
                      instaTouchEnabled &&
                      CanUpdateAccountNumber() &&
                      cardNumberList &&
                      cardNumberList.length > 0
                      && !item.isEditing
                    "
                    type="button"
                    title=""
                    class="btn"
                    style="color: rgb(59, 175, 218)"
                    v-on:click.prevent.stop="accountNumberPopupOpen(item)"
                  >
                    <i
                      class="mdi mdi-playlist-edit"
                      style="font-size: 20px"
                    ></i>
                  </i>
                </div>
              </td>
              <td>
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  {{ item.CurrentCreditorAccountNumber }}
                </span>

                <input
                  class="generalTextBox form-control"
                  type="text"
                  v-model="selectedRow.CurrentCreditorAccountNumber"
                  v-show="item.isEditing"
                  v-on:keydown.enter.prevent="handleUpdate(item)"
                  :readonly="!canEdit && !canOnlyEditAcc"
                />
              </td>
              <td>
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  {{ this.getAcctHolderNameById(item.AccountHolderId) }}
                </span>
                <select
                  v-show="item.isEditing"
                  v-model="selectedRow.AccountHolderId"
                  class="form-control generalSelectBox"
                  :class="
                    v$.selectedRow.AccountHolderId.$error ? 'has-error' : ''
                  "
                  @blur="v$.selectedRow.AccountHolderId.$touch"
                  :disabled="!canEdit"
                >
                  <option value="0">Select</option>
                  <option
                    v-for="item in accountHolders"
                    :value="item.Id"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </td>
              <td>
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  {{ this.getAcctTypeNameById(item.AccountTypeId) }}
                </span>
                <select
                  v-show="item.isEditing"
                  v-model="selectedRow.AccountTypeId"
                  class="form-control generalSelectBox"
                  :disabled="!canEdit"
                >
                  <option value="0">Select</option>
                  <option
                    v-for="item in accountTypes"
                    :value="item.Id"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </td>
              <td>
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  {{ this.getCreditorStatusNameById(item.CreditorStatusId) }}
                </span>
                <select
                  v-show="item.isEditing"
                  v-model="selectedRow.CreditorStatusId"
                  class="form-control generalSelectBox"
                  :disabled="
                    !canEdit ||
                    !checkAuth(3443) ||
                    !isLimitedStatus(selectedRow.CreditorStatusId)
                  "
                >
                  <option
                    :disabled="!isLimitedStatus(0)"
                    :class="!isLimitedStatus(0) ? 'readonlyInput' : ''"
                    value="0"
                  >
                    Select
                  </option>
                  <option
                    v-for="item in creditorStatuses"
                    :disabled="!isLimitedStatus(item.Id)"
                    :class="!isLimitedStatus(item.Id) ? 'readonlyInput' : ''"
                    :value="item.Id"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </td>

              <td>
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  {{ this.getNegotiatorNameById(item.NegotiatorId) }}
                </span>
                <select
                  v-show="item.isEditing"
                  v-model="selectedRow.NegotiatorId"
                  :disabled="!(checkAuth(2347) && canEdit)"
                  class="form-control generalSelectBox"
                >
                  <option :value="null">Select</option>
                  <option
                    v-for="item in filteredNegotiators"
                    :value="item.Id"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </td>
              <td>
                <span
                  class="tabledit-span tabledit-identifier"
                  v-show="!item.isEditing"
                >
                  {{ this.formatMoney(item.MonthlyPayment) }}
                </span>
                <input
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  class="generalTextBox form-control"
                  type="text"
                  v-model="selectedRow.MonthlyPayment"
                  v-show="item.isEditing"
                  v-on:keydown.enter.prevent="handleUpdate(item)"
                  :readonly="!canEdit"
                />
              </td>
              <td>
                <span>{{ item.NegotiationPhase.Name }}</span>
              </td>
              <td>
                <span
                  v-if="!item.isEditing || (item.isEditing && !canEdit)"
                  class="tabledit-span tabledit-identifier"
                >
                  {{ formatShortDate(item.LastPaymentDate) }}
                  {{ getLastPaymentDay(item.LastPaymentDate) }}
                </span>
                <span v-if="item.isEditing && canEdit">
                  <DatePicker
                    mode="date"
                    :locale="'en-US'"
                    :available-dates="datepickerConfig"
                    v-model="selectedRow.LastPaymentDate"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        id="DateOfBirthOfTaskPopup"
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                  {{ getLastPaymentDay(selectedRow.LastPaymentDate) }}
                </span>
              </td>
              <td>
                <span class="tabledit-span tabledit-identifier">
                  {{ new Date(item.DateAdded).toLocaleDateString("en-US") }}
                </span>
              </td>
              <td v-if="hasTermSettlementStatus">
                <span
                  v-if="item.TermSettlement.IsTermSettlement"
                  class="tabledit-span tabledit-identifier"
                >
                  {{
                    new Date(
                      item.TermSettlement.TermSettlementStartDate
                    ).toLocaleDateString("en-US")
                  }}
                </span>
              </td>
              <td v-if="hasTermSettlementStatus">
                <span
                  v-if="item.TermSettlement.IsTermSettlement"
                  class="tabledit-span tabledit-identifier"
                >
                  {{
                    new Date(
                      item.TermSettlement.TermSettlementEndDate
                    ).toLocaleDateString("en-US")
                  }}
                </span>
              </td>

              <td>
                {{ item.Offer != null ? formatMoney(item.Offer) : 0.0 }}
              </td>
              <td>{{ getSettlementPercentage(item) }}</td>
              <td>{{ getFees(item) }}</td>
              <td v-if="checkAuth(10048)">
                <span
                  v-if="!item.isEditing || (item.isEditing && !canEdit)"
                  class="tabledit-span tabledit-identifier"
                >
                  {{ formatShortDate(item.PoaSentDate) }}
                </span>
                <span v-if="item.isEditing && canEdit">
                  <DatePicker
                    mode="date"
                    :locale="'en-US'"
                    :available-dates="{ start: new Date() }"
                    v-model="selectedRow.PoaSentDate"
                    style="min-width: 90px !important"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </span>
              </td>
              <td v-if="checkAuth(10048)">
                <input
                  disabled
                  class="generalTextBox form-control"
                  type="checkbox"
                  v-model="item.PoaAutomation"
                  v-show="!item.isEditing"
                />

                <input
                  class="generalTextBox form-control"
                  type="checkbox"
                  v-model="selectedRow.PoaAutomation"
                  v-show="item.isEditing"
                  :disabled="!canEdit"
                />
              </td>
              <td>
                <input
                  disabled
                  class="generalTextBox form-control"
                  type="checkbox"
                  v-model="item.Delinquent3Years"
                  v-show="!item.isEditing"
                />

                <input
                  class="generalTextBox form-control"
                  type="checkbox"
                  v-model="selectedRow.Delinquent3Years"
                  v-show="item.isEditing"
                  :disabled="!canEdit"
                />
              </td>
              <td>
                <span class="tabledit-span tabledit-identifier">
                  {{ item.SettlementRequestedUser.Name }}
                </span>
              </td>

              <td v-if="checkAuth(53345) || checkAuth(54332)">
                <span
                  v-show="!item.isEditing"
                  class="tabledit-span tabledit-identifier"
                >
                  {{ item.AttorneyName }}
                </span>
                <input
                  class="generalTextBox form-control"
                  type="text"
                  v-model="selectedRow.AttorneyName"
                  v-show="item.isEditing"
                  v-on:keydown.enter.prevent="handleUpdate(item)"
                  :readonly="!canEdit && !canOnlyEditAcc"
                />
              </td>
              <td v-if="checkAuth(53345) || checkAuth(54332)">
                <input
                  disabled
                  class="generalTextBox form-control"
                  type="checkbox"
                  v-model="item.EgAttorney"
                  v-show="!item.isEditing"
                />

                <input
                  class="generalTextBox form-control"
                  type="checkbox"
                  v-model="selectedRow.EgAttorney"
                  v-show="item.isEditing"
                  :disabled="!canEdit"
                />
              </td>

              <td></td>

              <td
                v-if="!blockMakingChange"
                class="stickyColumns"
                style="white-space: nowrap; width: 1%"
                @mouseleave="hoverPopupLeave()"
              >
                <button
                  v-if="checkAuth(8270)"
                  type="button"
                  class="btn"
                  style="
                    background-color: #3bafda;
                    margin-right: 16px !important;
                  "
                  :style="{
                    backgroundColor:
                      CreditorModifiedListAfterEnrollment(item.Id).length > 0
                        ? '#f1c40f'
                        : '#58a56b',

                    marginRight: '16px',
                  }"
                  @mouseover="creditorsInfoButtonHover(item.Id, $event)"
                  v-on:click.prevent.stop="
                    () => openInfoPopup(item.Id, item.DateAdded, item.Name)
                  "
                >
                  <i class="fas fa-info-circle"></i>
                </button>

                <button
                  @click="showPaymentSetupPopup(item)"
                  type="button"
                  title="Payment Setup"
                  class="btn"
                  style="
                    background-color: #3bafda;
                    margin-right: 16px !important;
                  "
                  :disabled="item.SettlementStatusId != 3"
                >
                  <i class="ri-currency-fill"></i>
                </button>

                <button
                  v-if="checkAuth(7193)"
                  type="button"
                  :title="
                    item.SettlementStatusId == 1 ? 'Go to Task' : 'Settle'
                  "
                  class="btn"
                  style="
                    background-color: #3bafda;
                    margin-right: 16px !important;
                  "
                  :disabled="
                    !(
                      item.SettlementStatusId == 0 ||
                      item.SettlementStatusId == 1 ||
                      (item.SettlementStatusId == 3 && checkAuth(325656)) ||
                      (item.SettlementRequestDate != null &&
                        new Date(item.SettlementRequestDate) >= new Date())
                    ) ||
                    item.CreditorStatusId == 4 ||
                    item.CreditorStatusId == 18 ||
                    this.checkAuth(572922)
                  "
                  @click="settleCreditor(item)"
                >
                  <i
                    :class="
                      item.SettlementStatusId == 1
                        ? 'ri-list-check-2'
                        : 'fas fa-handshake'
                    "
                  ></i>
                </button>
                <button
                  v-if="item.Id > 0 && checkAuth(325657)"
                  type="button"
                  title="Creditor Notes"
                  class="btn"
                  style="
                    background-color: #ffa201;
                    margin-right: 16px !important;
                  "
                  @click="openCreditorNotes(item)"
                  :disabled="item.CreditorStatusId == 18"
                >
                  <i class="mdi mdi-file-document"></i>
                </button>
                <button
                  v-if="item.Id > 0 && checkAuth(10030)"
                  type="button"
                  title="e-Fax"
                  class="btn"
                  style="
                    background-color: #6c757d;
                    margin-right: 16px !important;
                  "
                  @click="openSendFax(item)"
                  :disabled="item.CreditorStatusId == 18"
                >
                  <i class="mdi mdi-fax"></i>
                </button>
                <button
                  v-if="checkAuth(3654) || checkAuth(34498)"
                  type="button"
                  title="Edit"
                  :disabled="
                    ((isNewRowActive || item.isEditing) &&
                      (v$.$invalid || myInvalid)) ||
                    saving ||
                    (!checkAuth(6356) &&
                      checkAuth(55004) &&
                      item.CreditorStatusId == 18)
                  "
                  class="btn"
                  style="
                    background-color: #24d171;
                    margin-right: 16px !important;
                  "
                  v-on:click.prevent.stop="
                    () => {
                      resetEdit(item);
                      if (!item.isEditing) {
                        selectedRow = Object.assign({}, item);
                      }
                      if (item.isEditing) {
                        handleUpdate(item);
                      }
                      if (!item.isEditing || !selectedRow.isEditing) {
                        item.isEditing = !item.isEditing;
                        this.ccnSuggestBoxOpen = false;
                        this.suggestBoxOpen = false;
                      }
                      isNewRowActive = false;
                    }
                  "
                >
                  <i v-show="!item.isEditing" class="ri-edit-line"></i>
                  <i v-show="item.isEditing" class="fas fa-save"></i>
                </button>
                <button
                  v-if="checkAuth(8264)"
                  type="button"
                  title="Delete"
                  class="btn"
                  style="
                    background-color: #ed3030;
                    margin-right: 0px !important;
                  "
                  :disabled="
                    ((isNewRowActive || item.isEditing) &&
                      (v$.$invalid || myInvalid)) ||
                    saving
                  "
                  v-on:click.prevent.stop="() => handleDelete(item)"
                >
                  <i class="fe-trash-2"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <span style="font-weight: bold">
                  Count: {{ sortedCreditors.length }}
                </span>
              </td>
              <td></td>
              <td>
                <span style="font-weight: bold">
                  {{ formatMoney(totalBalance) }}
                </span>
              </td>
              <td>
                <span v-if="totalCurrCrBalance > 0" style="font-weight: bold">
                  {{ formatMoney(totalCurrCrBalance) }}
                </span>
              </td>
              <td colspan="6"></td>
              <td>
                <span style="font-weight: bold">
                  {{ formatMoney(totalMonthlyPayment) }}
                </span>
              </td>
              <td v-if="hasTermSettlementStatus" colspan="2"></td>
              <td v-if="checkAuth(10048)" colspan="2"></td>

              <td colspan="11"></td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isSendFaxPopupOpen">
        <div
          style="position: relative; margin: 2% auto"
          class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <SendFax
            @closeSendFaxPopup="closeSendFaxPopup"
            :creditorName="faxPopupCreditorName"
            :accountNumber="faxPopupAccountNumber"
            :ssnValue="customerSsn"
            :dateOfBirth="customerDob"
            :customerId="customerId"
            :creditorId="faxPopupCreditorId"
          ></SendFax>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isCreditorNotePopupOpen">
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-4 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <CreditorNotesPopup
            @closeCreditorNotePopup="closeCreditorNotePopup"
            @updateCreditorNote="updateCreditorNote"
            :settlementDetailId="settlementDetailId"
            :creditorId="creditorId"
            :note="creditorNote"
          ></CreditorNotesPopup>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="backgroundLock"
        v-if="isPaymentsPopupOpen"
        style="-webkit-box-align: center; align-items: center; display: flex"
      >
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-4 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <ShowPayments
            @closeShowPayments="closePaymentsPopup"
            :creditorId="creditorId"
            :isDpg="isDpg"
          ></ShowPayments>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isVoidPopupOpen">
        <div
          style="position: relative; margin: 1% auto"
          class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        >
          <VoidSettlementsPopUp
            :creditorId="selectedCreditor.Id"
            :customerId="customerId"
            :officeId="officeId"
            :creditorName="selectedCreditor.Name"
            :creditorAcctNumber="selectedCreditor.AccountNumber"
            :paymentProcessorId="paymentProcessorId"
            @closeVoidSettlementsPopUp="closeVoidSettlementsPopUp"
          ></VoidSettlementsPopUp>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="backgroundLock"
        v-if="isInstaTouchPopup"
        style="-webkit-box-align: center; align-items: center; display: flex"
      >
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-5 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <InstaTouchPopup
                  :customerId="customerId"
                  :customerSsn="customerSsn"
                  :customerFirstName="customerFirstName"
                  :customerLastName="customerLastName"
                  :customerAddressLine1="customerAddressLine1"
                  :customerAddressLine2="customerAddressLine2"
                  :customerCity="customerCity"
                  :customerStateId="customerStateId"
                  :customerZip="customerZip"
                  :customerDob="customerDob"
            @closeInstaTouchPopup="closeInstaTouchPopup"
            @updateCardNumberList="updateCardNumberList"
          ></InstaTouchPopup
                  >
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="backgroundLock"
        v-if="isAccountNumberPopup && accountNumberPopupData.creditorId > 0"
        style="-webkit-box-align: center; align-items: center; display: flex"
      >
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <AccountNumbersPopup
            @closeAccountNumberPopup="closeAccountNumberPopup"
            @accountNumberSelected="accountNumberSelected"
            :cardNumberList="cardNumberList"
            :creditorName="accountNumberPopupData.creditorName"
            :accountNumber="accountNumberPopupData.accountNumber"
            :selectedCreditorId="accountNumberPopupData.creditorId"
          ></AccountNumbersPopup>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import types from "./types";
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";
import { mapState } from "vuex";
import deepCopy from "../../../../helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import SendFax from "@/components/SendFax.vue";
import SuggestBox from "@/components/SuggestBox.vue";
import CreditorNotesPopup from "./CreditorNotesPopup.vue";
import { DatePicker } from "v-calendar";
import ShowPayments from "./ShowPayments.vue";
import { diffDays } from "@fullcalendar/common";
import VoidSettlementsPopUp from "@/views/agent/EditClient/DraftChanges/VoidSettlementsPopUp.vue";
import ClientCreditorsInfoPopup from "./ClientCreditorsInfoPopup";
import ClientCreditorsHoverPopup from "./ClientCreditorsHoverPopup";
import InstaTouchPopup from "./InstaTouchPopup";
import AccountNumbersPopup from "./AccountNumbersPopup";
const tempAccountList = [
  { BankName: "Truist", CardNumber: "2342sdf432" },
  { BankName: "Bank Of America", CardNumber: "546456sdff243" },
];
export default {
  name: "ClientCreditors",
  mixins: [formatMixin, utilitiesMixin],
  components: {
    AccountNumbersPopup,
    SendFax,
    SuggestBox,
    CreditorNotesPopup,
    DatePicker,
    ShowPayments,
    VoidSettlementsPopUp,
    ClientCreditorsInfoPopup,
    ClientCreditorsHoverPopup,
    InstaTouchPopup,
  },

  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    officeId: {
      type: Number,
      default: () => 0,
    },
    isDpg: {
      type: Boolean,
      Default: false,
    },
    paymentProcessorId: {
      type: Number,
      default: () => 0,
    },
    customerSsn: {
      type: String,
      default: () => "",
    },
    customerFirstName: {
      type: String,
      default: () => "",
    },
    customerLastName: {
      type: String,
      default: () => "",
    },
    customerAddressLine1: {
      type: String,
      default: () => "",
    },
    customerAddressLine2: {
      type: String,
      default: () => "",
    },
    customerCity: {
      type: String,
      default: () => "",
    },
    customerStateId: {
      type: Number,
      default: () => 0,
    },
    customerZip: {
      type: String,
      default: () => "",
    },
    customerDob: {
      type: String,
      default: () => "",
    },
    customerStatusId: {
      type: Number,
      default: () => 0,
    },
    customerName: {
      type: String,
      default: () => "",
    },
    blockMakingChange: {
      Type: Boolean,
      Default: false,
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
    feePercentage: {
      Type: Number,
      Default: null,
    },
    enrolledDate: {
      type: String,
      default: () => "",
    },
  },

  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  data() {
    return {
      accountNumberPopupData:{
        creditorId:0,
        creditorName:"",
        accountNumber:""
      },
      windowHeight: 0,
      infoHoverPanelHeight: 320,
      infoHoverPanelPosition: "top:45px; right:60px;",
      hoveredCreditorsId: 0,
      clickedCreditorId: 0,
      clickedDateAdded: "",
      clickedCreditorName: "",
      isInfoPopUp: false,
      sortByBalance: null,
      datepickerConfig: {
        start: "01/01/2000",
        end: "12/31/2100",
      },
      loggedUserId: 0,
      isVoidPopupOpen: false,
      settlementDetailId: 0,
      creditorId: 0,
      creditorNote: "",
      isCreditorNotePopupOpen: false,
      suggestBoxOpen: false,
      ccnSuggestBoxOpen: false,
      faxPopupCreditorName: "",
      faxPopupCreditorId: 0,
      faxPopupAccountNumber: "",
      isSendFaxPopupOpen: false,
      editSpinner: false,
      creditors: [],
      initRow: deepCopy(this.initRowFnc()),
      v$: useValidate(),
      selectedRow: deepCopy(this.initRowFnc()),
      myInvalid: false,
      saving: false,
      dispositions: [],
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      isLoading: false,
      isNewRowActive: false,
      ignoreOutsideClick: false,
      hasTermSettlementStatus: false,
      isPaymentsPopupOpen: false,
      selectedCreditor: {
        Id: 0,
        Name: "",
        AccountNumber: "",
      },
      canEdit: false,
      canOnlyEditAcc: false,
      consecutiveDraftCheckCount: 3,
      creditorChangedList: [],
      creditorInfoReady: false,
      cardNumberList: [],
      instaTouchEnabled: false,
      isInstaTouchPopup: false,
      isAccountNumberPopup: false,
      CardNumberListBackUp:[],
    };
  },
  computed: mapState({
    creditorStatuses: (state) => state.customerCreditors.creditorStatuses,
    limitedStatuses: (state) => state.globals.restrictedCreditorStatuses,
    customerStatuses: (state) => state.globals.customerStatuses,
    suggestCreditorNames: (state) => state.globals.suggestCreditorNames,
    accountTypes: (state) => state.customerCreditors.accountTypes,
    accountHolders: (state) => state.customerCreditors.accountHolders,
    sortedCreditors() {
      let sortedList;

      if (this.sortByBalance == null) {
        sortedList = this.creditors.sort((x, y) =>
          x.Name.localeCompare(y.Name)
        );
      } else if (this.sortByBalance) {
        sortedList = this.creditors.sort(
          (x, y) =>
            parseFloat(x.OriginalBalance) - parseFloat(y.OriginalBalance)
        );
      } else {
        sortedList = this.creditors.sort(
          (x, y) =>
            parseFloat(y.OriginalBalance) - parseFloat(x.OriginalBalance)
        );
      }

      let withdrawnStatusList = sortedList.filter(
        (x) => x.CreditorStatusId == 4
      );
      let blockedStatusList = sortedList.filter(
        (x) => x.CreditorStatusId == 18
      );
      let otherStatusesList = sortedList.filter(
        (x) => x.CreditorStatusId != 18 && x.CreditorStatusId != 4
      );

      let finalList = [];
      if (otherStatusesList.length > 0) {
        for (let item of otherStatusesList) {
          finalList.push(item);
        }
      }
      if (withdrawnStatusList.length > 0) {
        for (let item of withdrawnStatusList) {
          finalList.push(item);
        }
      }

      if (blockedStatusList.length > 0) {
        for (let item of blockedStatusList) {
          finalList.push(item);
        }
      }

      return finalList;
    },
    negotiators: (state) => state.globals.negotiators,
    filteredNegotiators() {
      return this.negotiators
        ? this.negotiators.filter(x=> !x.IsOnOneNegotiator)
        : [];
    },
    totalBalance() {
      let total = 0;
      let disabledCreditorStatusList = this.getDisabledCreditorStatus();
      let activeCreditors = this.creditors.filter(
        (x) => !disabledCreditorStatusList.includes(x.CreditorStatusId)
      );
      for (let item of activeCreditors) {
        total += parseFloat(item.OriginalBalance);
      }
      return total;
    },

    totalCurrCrBalance() {
      let total = 0;
      let disabledCreditorStatusList = this.getDisabledCreditorStatus();
      let activeCreditors = this.creditors.filter(
        (x) => !disabledCreditorStatusList.includes(x.CreditorStatusId)
      );
      for (let item of activeCreditors) {
        total += parseFloat(item.CurrentCreditorBalance);
      }
      return total;
    },

    totalMonthlyPayment() {
      let total = 0;
      let disabledCreditorStatusList = this.getDisabledCreditorStatus();
      let activeCreditors = this.creditors.filter(
        (x) => !disabledCreditorStatusList.includes(x.CreditorStatusId)
      );
      for (let item of activeCreditors) {
        total += parseFloat(item.MonthlyPayment);
      }
      return total;
    },
    blockedCreditorInfo: (state) => state.globals.blockedCreditorInfo,
    blockedCreditorList() {
      return this.blockedCreditorInfo
        ? this.blockedCreditorInfo.BlockedCreditors
        : [];
    },
  }),
  unmounted() {
    this.$Emitter.off("refreshCreditorGrid");
  },
  async mounted() {
    this.canEdit = this.checkAuth(3654);
    this.canOnlyEditAcc = this.checkAuth(34498);
    this.initRow.CustomerId = this.customerId;

    this.$Emitter.on("refreshCreditorGrid", async (customerId) => {
      if (this.customerId == customerId) {
        this.isLoading = true;
        await this.getCreditors();
        this.updateCustomerTotalDebt();
        this.isLoading = false;
      }
    });

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo && userInfo.userId > 0) {
      this.loggedUserId = userInfo.userId;
    }

    if (this.enrolledDate != null && this.enrolledDate.length > 1) {
      //enrolled client , pull api for modified creditors.

      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_CREDITOR_INFO, {
        Id: this.customerId,
      });

      if (result && result.Data) {
        this.creditorChangedList = deepCopy(result.Data);
      }
    }
    this.creditorInfoReady = true;
    window.addEventListener("resize", this.onResize);
    this.onResize();

  },
  beforeMount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    async updateCardNumberList(cardList){
      if(cardList && cardList.length > 0){
        let tmpList = deepCopy(cardList);
        this.cardNumberList = await this.openCardNumbers(tmpList);
        this.filterUsedCardNumbers();
      }

    },
    accountNumberPopupOpen(item) {
      this.accountNumberPopupData.creditorId = item.Id;
      this.accountNumberPopupData.creditorName = item.Name;
      this.accountNumberPopupData.accountNumber = item.AccountNumber;
      this.isAccountNumberPopup = true;
    },
    CanUpdateAccountNumber() {
      let rtn = false;
      if (this.checkAuth(3654) || this.checkAuth(34498)) {
        rtn = true;
      }
      return rtn;
    },
    async PrepareInstaTouch() {

      if(this.checkAuth(78214)) {
        let err, result; //Load InstaTouchEnabled.
        [err, result] = await this.$store.dispatch(
                types.GET_INSTATOUCH_API_SETTING
        );
        if (result) {
          if (result.Data) {
            this.instaTouchEnabled = result.Data.InstaTouchInfoEnabled
                    ? result.Data.InstaTouchInfoEnabled
                    : false;
          }
        }


        //Load Credit Card List.
        err = null;
        result = null;

        [err, result] = await this.$store.dispatch(types.GET_INSTATOUCH_CARD_NUMBERS, {
          CustomerId: this.customerId,
        });

        if (result && result.Data) {
          this.cardNumberList = await this.openCardNumbers(result.Data);
          this.filterUsedCardNumbers();
        }
      }
    },
    async openCardNumbers(cardList){
      let rtn=[];
      for(let i=0;i<cardList.length;i++){
        let objTemp = cardList[i];
        objTemp.CardNumber= await this.AES256_GCM_decrypt(objTemp.CardNumber);
        rtn.push(objTemp);
      }
      this.CardNumberListBackUp= rtn;
      return rtn;
    },
    filterUsedCardNumbers(){
      this.cardNumberList = deepCopy(this.CardNumberListBackUp);
      this.cardNumberList = this.cardNumberList.filter(x=>!(this.creditors.some(y=>y.AccountNumber == x.CardNumber)));

    },
    openInfoPopup(creditorId, dateAdded, creditorName) {
      this.clickedCreditorId = creditorId;
      this.clickedDateAdded = dateAdded;
      this.clickedCreditorName = creditorName;
      this.isInfoPopUp = true;
    },
    closeInfoPopup() {
      this.clickedCreditorId = 0;
      this.clickedDateAdded = "";
      this.clickedCreditorName = "";
      this.isInfoPopUp = false;
    },
    CreditorModifiedListAfterEnrollment(creditorId) {
      let rtn = [];
      if (this.creditorChangedList == null) return rtn;
      if (this.creditorChangedList.length > 0) {
        rtn = deepCopy(
          this.creditorChangedList.filter((x) => x.CreditorId == creditorId)
        );
        if (rtn == null) rtn = [];
      }
      return rtn;
    },

    async creditorsInfoButtonHover(itemId) {
      let positioning =
        this.windowHeight - event.clientY > this.infoHoverPanelHeight
          ? "top:" + event.clientY + "px; "
          : "bottom:" + (this.windowHeight - event.clientY) + "px;";

      this.infoHoverPanelPosition = positioning + " right:320px;";
      this.hoveredCreditorsId = itemId;
    },
    hoverPopupLeave() {
      this.hoveredCreditorsId = 0;
      this.hoveredDispositionId = 0;
    },

    onResize() {
      this.windowHeight = window.innerHeight;
    },

    getStatusStyle(percentage) {
      return {
        width: `${percentage}%`,
      };
    },

    isLimitedStatus(statusId) {
      let rtn = false;
      // Can update creditors - statuses limited to Active, Withdrawn and Blocked
      if (this.checkAuth(3444)) {
        if (this.limitedStatuses && this.limitedStatuses.length > 0) {
          rtn = this.limitedStatuses.some((x) => x.Id == statusId);
        }
      } else {
        rtn = true;
      }
      return rtn;
    },
    async checkConsecutiveDraft() {
      let rtn = true; 
      let err, result;

      let payload = {
        CustomerId: this.customerId,
        Months: this.consecutiveDraftCheckCount,
      };
      [err, result] = await this.$store.dispatch(
        globalTypes.CHECK_CONSECUTIVE_DRAFTS,
        payload
      );

      if (result) {
        rtn = result.IsConsecutiveDrafts;
      }

      return rtn;
    },
    toggleSortOrder() {
      this.sortByBalance = !this.sortByBalance;
    },
    openVoidSettlementsPopUp(item) {
      this.selectedCreditor.Name = item.Name;
      this.selectedCreditor.AccountNumber = item.AccountNumber;
      this.selectedCreditor.Id = item.Id;
      this.isVoidPopupOpen = true;
    },
    async closeVoidSettlementsPopUp(refreshCreditor) {
      this.isVoidPopupOpen = false;
      if (refreshCreditor == true) {
        let err, result;

        [err, result] = await this.$store.dispatch(
          types.VOID_CREDITOR_SETTLEMENT,
          {
            Id: this.selectedCreditor.Id,
            CreditorName: this.selectedCreditor.Name,
            CreditorAccountNumber: this.selectedCreditor.AccountNumber,
          }
        );
        if (result) {
          await this.getCreditors();
          this.updateCustomerTotalDebt();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },

    showLawSuitIndicator() {
      const hasLawSuit = this.creditors.some((x) => x.IsLawSuit);
      const hasEgAttorney = this.creditors.some((x) => x.EgAttorney);

      if (
        hasLawSuit == true &&
        hasEgAttorney == true &&
        (this.checkAuth(53345) || this.checkAuth(54332))
      ) {
        return false;
      } else if (hasLawSuit) {
        return true;
      }
    },

    showEgAttorneyIndicator() {
      const hasEgAttorney = this.creditors.some((x) => x.EgAttorney);
      const hasLawSuit = this.creditors.some((x) => x.IsLawSuit);

      if (
        hasEgAttorney == true &&
        hasLawSuit == true &&
        (this.checkAuth(53345) || this.checkAuth(54332))
      ) {
        return false;
      } else if (
        hasEgAttorney &&
        (this.checkAuth(53345) || this.checkAuth(54332))
      ) {
        return true;
      }
      return;
    },

    showEGandLawIndicator() {
      const hasEgAttorney = this.creditors.some((x) => x.EgAttorney);
      const hasLawSuit = this.creditors.some((x) => x.IsLawSuit);

      if (
        hasEgAttorney == true &&
        hasLawSuit == true &&
        (this.checkAuth(53345) || this.checkAuth(54332))
      ) {
        return true;
      }
      return;
    },

    showSettledIndicator() {
      return this.creditors.filter((x) => x.CreditorStatusId == 2).length > 0;
    },
    showSummonsIndicator() {
      return (
        this.creditors.filter(
          (x) =>
            x.CreditorStatusId == 5 ||
            x.CreditorStatusId == 8 ||
            x.CreditorStatusId == 11 ||
            x.CreditorStatusId == 9 ||
            x.CreditorStatusId == 6
        ).length > 0
      );
    },

    show1099CIndicator() {
      return this.creditors.filter((x) => x.CreditorStatusId == 10).length > 0;
    },

    settlementVoidedIndicator() {
      return this.creditors.filter((x) => x.CreditorStatusId == 19).length > 0;
    },
    closePaymentsPopup() {
      this.isPaymentsPopupOpen = false;
    },
    closeAccountNumberPopup() {
      this.isAccountNumberPopup = false;
    },
    async accountNumberSelected(data){
      if(data){
        let indx = this.creditors.findIndex(
                (x) => x.Id ==  data.CreditorId
        );
        if(indx>-1){
          this.creditors[indx].AccountNumber = data.AccountNumber;
          let dataToSave = Object.assign({}, this.creditors[indx]);
          delete dataToSave.isEditing;
          dataToSave.OriginalBalance = parseFloat(dataToSave.OriginalBalance);
          dataToSave.MonthlyPayment = parseFloat(dataToSave.MonthlyPayment);
          dataToSave.CurrentCreditorBalance = parseFloat(
                  dataToSave.CurrentCreditorBalance
          );
          this.isLoading=true;
          let err, result;
          [err, result] = await this.$store.dispatch(
                  types.UPDATE_CREDITOR,
                  dataToSave
          );
          this.isLoading=false;
          if(result){
            if(result.Data){
              this.creditors[indx] = result.Data;
              this.creditors.push();
              this.$swal("Success!", "Creditor Account Number Updated Successfully", "success");
            }

          }
          else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      }


    },
    closeInstaTouchPopup() {
      this.isInstaTouchPopup = false;
    },

    showPaymentSetupPopup(item) {
      if (item.SettlementStatusId == 3) {
        this.creditorId = item.Id;
        this.isPaymentsPopupOpen = true;
      }
    },
    getLastPaymentDay(date) {
      let rtn = "";
      if (typeof date != "undefined" && date) {
        let diffTime = Math.abs(new Date() - new Date(date));

        let diffDays = Math.floor(diffTime / (1000 * 3600 * 24));

        rtn = isNaN(diffDays) ? "" : " - " + diffDays + "d";
      }
      return rtn;
    },
    getFees(item) {
      let rtn = 0;
      if (item) {
        if (item.Fee) {
          rtn = parseFloat(item.Fee);
        } else {
          if (this.feePercentage) {
            rtn = parseFloat(
              (parseFloat(item.OriginalBalance) *
                parseFloat(this.feePercentage)) /
                100
            );
          } else {
            rtn = 0;
          }
        }
      }
      return this.formatMoney(rtn);
    },
    getSettlementPercentage(item) {
      let rtn = 0;
      if (item) {
        rtn = this.calculatePercentage(
          item.Offer,
          item.OriginalBalance
        ).toFixed(2);
      }

      return isNaN(rtn) ? 0 + "%" : rtn + "%";
    },
    getSuggestBoxPosition(id) {
      let rtn = "";
      if (id < 4) {
        rtn = "position:absolute; top:50px; left:15px; z-index:1";
      } else {
        rtn = "position:absolute; bottom:45px; left:15px; z-index:1";
      }
      return rtn;
    },

    closeCreditorNotePopup() {
      this.isCreditorNotePopupOpen = false;
    },
    updateCreditorNote(data) {
      if (data) {
        let indx = this.creditors.findIndex((x) => x.Id == data.CreditorId);
        if (indx > -1) {
          this.creditors[indx].Note = data.Note;
        }
      }
      this.isCreditorNotePopupOpen = false;
    },
    // openCreditorNotes(item) {
    //   this.settlementDetailId = item.SettlementId;
    //   this.creditorNote = item.Note;
    //   this.creditorId = item.Id;
    //   this.isCreditorNotePopupOpen = true;
    // },
    openCreditorNotes(item) {
      this.$Emitter.emit("viewNotePanel", {
        CustomerId: item.CustomerId,
        CreditorId: item.Id,
      });
    },
    suggestBoxBlur(name) {
      if (!this.suggestCreditorNames.includes(name) && this.checkAuth(55004)) {
        name = "";
      }
    },
    checkBlockedCreditorNames(row) {
      let creditorName = "";
      if (row.Name && row.Name != "") {
        creditorName = row.Name.toLowerCase();
      }

      if (
        this.blockedCreditorList.includes(creditorName) &&
        !this.checkAuth(6356)
      ) {
        row.Name = "";
        this.$swal(
          "Warning",
          "This creditor name is in the blocked creditor list.",
          "warning"
        );
      }
    },

    assignCreditorName(value) {
      this.selectedRow.Name = value;
      this.suggestBoxOpen = false;
    },

    closeSuggestBoxPopup() {
      this.suggestBoxOpen = false;
    },
    openSuggestBox() {
      this.suggestBoxOpen = true;
    },

    openCCNSuggestBox() {
      this.ccnSuggestBoxOpen = true;
    },
    closeCCNSuggestBoxPopup() {
      this.ccnSuggestBoxOpen = false;
    },
    assignCCN(value) {
      this.selectedRow.CurrentCreditorName = value;
      this.ccnSuggestBoxOpen = false;
    },

    openSendFax(item) {
      this.isSendFaxPopupOpen = true;

      this.faxPopupCreditorId = item.Id;
      if (item.CurrentCreditorName == null || item.CurrentCreditorName == "") {
        this.faxPopupCreditorName = item.Name;
        this.faxPopupAccountNumber = item.AccountNumber;
      } else {
        this.faxPopupCreditorName = item.CurrentCreditorName;
        this.faxPopupAccountNumber = item.CurrentCreditorAccountNumber;
      }
    },
    closeSendFaxPopup() {
      this.isSendFaxPopupOpen = false;
    },
    async watcherRefresh() {
      this.isLoading = true;
      if (this.customerId > 0) {
        await Promise.all([
          this.$store.dispatch(types.GET_ACCOUNTTYPES),
          this.$store.dispatch(types.GET_CREDITORSTATUSES),
          this.$store.dispatch(types.GET_ACCOUNTHOLDERS),
          this.$store.dispatch(globalTypes.GET_NEGOTIATORS),
          this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
          this.$store.dispatch(globalTypes.GET_SUGGEST_CREDITOR_PATTERN),
          this.$store.dispatch(globalTypes.GET_ALL_CREDITOR_CONTACT),
          this.$store.dispatch(
            globalTypes.GET_BLOCKED_CREDITORS_AND_CREDIT_TYPES
          ),
          this.$store.dispatch(globalTypes.GET_RESTRICTED_CREDITOR_STATUSES),
        ]);
      }
      await this.getCreditors();
      this.isLoading = false;
      this.hasTermSettlementStatus =
        this.sortedCreditors.filter((x) => x.TermSettlement.IsTermSettlement)
          .length > 0;
      await this.PrepareInstaTouch();
    },
    async getCreditors() {
      this.isLoading = true;
      try {
        let err, result;
        [err, result] = await this.$store.dispatch(types.GET_CREDITORS, {
          CustomerId: this.customerId,
        });

        if (result && result.Data) {
          this.creditors = deepCopy(result.Data);
        }
      } catch {
        // do nothing
      }
      this.isLoading = false;
    },
    initRowFnc() {
      return {
        Id: 0,
        Name: "",
        NegotiatorId: null,
        CustomerId: 0,
        Comments: "",
        AccountNumber: "",
        OriginalBalance: 0,
        CurrentBalance: 0,
        CreditorStatusId: 0,
        AccountHolderId: 0,
        NegotiationPhase: { Id: 0, Name: "" },
        DateAdded: "",
        IsPOA: false,
        DateUpdated: "",
        IsLawSuit: false,
        IsSettlementSetup: false,
        IsSettlementApproved: false,
        SettlementStatusId: 0,
        SettlementRequestDate: "",
        CurrentCreditorName: "",
        CurrentCreditorBalance: 0,
        CurrentCreditorAccountNumber: "",
        SettlementRequestedUser: {
          Id: 0,
          Name: "",
        },
        AccountTypeId: 0,
        MonthlyPayment: 0,
        CreatedUserId: 0,
        SettledUserId: 0,
        Delinquent3Years: false,
        LastPaymentDate: null,
        isEditing: false,
        PoaSentDate: null,
        PoaAutomation: null,
      };
    },

    emptyTheObjectsOnUnmount() {
      this.myInvalid = null;
      this.saving = null;
      this.dispositions = null;
      this.isLoading = null;
      this.isNewRowActive = null;
      this.ignoreOutsideClick = null;
      this.hasTermSettlementStatus = null;
      (this.defaultSortDirection = null), (this.sortIcon = null);
      this.sortIconSize = null;
      this.selectedRow = null;
      // v$: useValidate(),
    },

    async proceedSync(customerId) {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_CREDITORS, {
        CustomerId: customerId,
      });

      if (result && result.Data) {
        this.creditors = deepCopy(result.Data);

        let total = 0;
        let disabledCreditorStatusList = this.getDisabledCreditorStatus();

        let activeCreditors = this.creditors.filter(
          (x) => !disabledCreditorStatusList.includes(x.CreditorStatusId)
        );

        for (let item of activeCreditors) {
          total += parseFloat(item.OriginalBalance);
        }

        await this.$emit(
          "updateTotalCreditorAfterSync",
          total,
          activeCreditors.length
        );
      }
    },
    OpenInstaTouchPopUp(){
      this.isInstaTouchPopup = true;
    },
    async settleCreditor(item) {
      if (item.Id) {
        if (item.SettlementStatusId == 0) {
          let continueSubmit = false;
          if (this.checkAuth(572950)) {
            let consecutiveDraftValid = await this.checkConsecutiveDraft();
            if (consecutiveDraftValid == false) {
              await this.$swal({
                title: "Consecutive Drafts Check",
                text:
                  "Customer does not have " +
                  this.consecutiveDraftCheckCount +
                  " consecutive drafts. Do you want to move forward with this settlement request?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              }).then(async (dialog) => {
                if (dialog.value) {
                  continueSubmit = true;
                } else {
                  continueSubmit = false;
                }
              });
              if (!continueSubmit) return;
            }
          }
          if (!continueSubmit) {
            await this.$swal({
              title: item.Name,
              text: "Are you sure to submit this creditor for settlement?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            }).then(async (dialog) => {
              if (dialog.value) {
                continueSubmit = true;
              } else {
                continueSubmit = false;
              }
            });
            if (!continueSubmit) return;
          }
          if (continueSubmit) {
            let err, result;
            [err, result] = await this.$store.dispatch(types.SETTLE_CREDITOR, {
              CreditorId: item.Id,
              IsClientNotified: true,
            });
            if (result) {
              this.$swal("Success!", "Settlement requested.", "success");
              await this.getCreditors();
              this.updateCustomerTotalDebt();
              if (result.Data && result.Data.SettlementId > 0) {
                this.$Emitter.emit("viewNegotiatorTask", {
                  taskId: result.Data.SettlementId,
                  customerName: this.customerName,
                  negotiatorId: result.Data.SettlementRequestedUser.Id,
                });
              }
              if (this.checkAuth(3994)) {
                this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
                  CustomerId: this.customerId,
                  UserActivityTypeId: 13,
                  AdditionalNotes: "Created a settlement offer",
                });
              }
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        } else if (item.SettlementStatusId == 3) {
          if (this.isDpg) {
            this.$swal({
              title: item.Name,
              text: "Are you sure to void this settlement? This action will not deactivate the scheduled payment and fees.",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            }).then(async (dialog) => {
              if (dialog.value) {
                let err, result;
                [err, result] = await this.$store.dispatch(
                  types.VOID_CREDITOR_SETTLEMENT,
                  {
                    Id: item.Id,
                    CreditorName: item.Name,
                    CreditorAccountNumber: item.AccountNumber,
                  }
                );
                if (result) {
                  this.$swal("Success!", "Settlement voided.", "success");
                  await this.getCreditors();
                  this.updateCustomerTotalDebt();
                } else {
                  let errMsg = this.getApiErrorMessage(err);
                  this.$swal("Error!", errMsg, "error");
                }
              }
            });
          } else {
            this.openVoidSettlementsPopUp(item);
          }
        } else if (item.SettlementStatusId == 1) {
          this.isLoading = true;
          this.$Emitter.emit("viewNegotiatorTask", {
            taskId: item.SettlementId,
            customerName: this.customerName,
            negotiatorId:
              item.NegotiatorId > 0 ? item.NegotiatorId : this.loggedUserId,
          });
          await this.sleep(1000);
          this.isLoading = false;
        }
      }
    },
    canEditOriginalBalance() {
      let rtn = true;
      if (this.customerStatusId > 0 && !this.checkAuth(32982)) {
        let isSoldStatus = false;
        let indx = this.customerStatuses.findIndex(
          (x) => x.Id == this.customerStatusId
        );
        if (this.customerStatuses[indx]) {
          isSoldStatus = this.customerStatuses[indx].IsSold;
        }

        rtn = !isSoldStatus;
      }

      return rtn;
    },
    canEditCurrentCreditorBalance() {
      let rtn = true;
      if (this.customerStatusId > 0 && !this.checkAuth(32992)) {
        let isSoldStatus = false;
        let indx = this.customerStatuses.findIndex(
          (x) => x.Id == this.customerStatusId
        );
        if (this.customerStatuses[indx]) {
          isSoldStatus = this.customerStatuses[indx].IsSold;
        }

        rtn = !isSoldStatus;
      }

      return rtn;
    },
    getCreditorStatusNameById(id) {
      let rtn = "";
      let item = this.creditorStatuses.filter((item) => item.Id == id);
      if (item.length > 0) {
        rtn = item[0].Name;
      }
      return rtn;
    },
    getNegotiatorNameById(id) {
      let rtn = "";
      let item = this.negotiators.filter((item) => item.Id == id);
      if (item.length > 0) {
        rtn = item[0].Name;
      }
      return rtn;
    },

    getAcctTypeNameById(id) {
      let rtn = "";
      let item = this.accountTypes.filter((item) => item.Id == id);
      if (item.length > 0) {
        rtn = item[0].Name;
      }
      return rtn;
    },
    getAcctHolderNameById(id) {
      let rtn = "";
      let item = this.accountHolders.filter((item) => item.Id == id);
      if (item.length > 0) {
        rtn = item[0].Name;
      }
      return rtn;
    },
    startNewRow() {
      if (this.myInvalid) return;
      this.resetEdit(null);
      this.isNewRowActive = true;
      this.selectedRow = Object.assign({}, this.initRow);
      this.v$.selectedRow.Name.$touch();
      this.v$.selectedRow.OriginalBalance.$touch();
      this.v$.selectedRow.AccountHolderId.$touch();
    },
    resetEdit(obj) {
      if (this.myInvalid) return;

      for (let item of this.creditors) {
        if (item !== obj) item.isEditing = false;
      }
    },
    resetEnter({ target }) {
      this.resetEdit(null);
    },
    resetAllValues() {
      this.isNewRowActive = false;
      this.resetEdit(null);
      this.selectedRow = Object.assign({}, this.initRow);
      this.saving = false;
    },
    cancelOperation(creditorId) {
      this.selectedRow.isEditing = false;
      this.selectedRow.Id = creditorId; //temp
    },
    handleDelete(item) {
      this.saving = true;
      this.isNewRowActive = false;
      this.selectedRow = Object.assign({}, item);
      let rowIndex = this.creditors.indexOf(this.selectedRow);
      this.resetEdit(null);
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let dataToDelete = {
              Id: this.selectedRow.Id,
            };
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.DELETE_CREDITOR,
              dataToDelete
            );

            if (result) {
              this.creditors.splice(rowIndex, 1);

              this.resetAllValues();

              this.$swal("Deleted!", result.Message, "success");
              if (rowIndex == -1) {
                await this.getCreditors();
              }
              this.updateCustomerTotalDebt();
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.saving = false;
    },

    async handleUpdate(item) {
      if (
        this.checkAuth(55004) &&
        !this.suggestCreditorNames.includes(this.selectedRow.Name)
      ) {
        this.$swal(
          "Error!",
          "You're not authorized to update a creditor out of the approved list.",
          "error"
        );
        return;
      }

      if (
        !this.checkAuth(6356) &&
        this.blockedCreditorList.includes(this.selectedRow.Name)
      ) {
        this.$swal(
          "Error!",
          "You're not authorized to add a creditor in the blocked creditor list.",
          "error"
        );
        return;
      }
      this.editSpinner = true;

      let comp1 = Object.assign({}, this.selectedRow);
      delete comp1.isEditing;
      let comp2 = Object.assign({}, item);
      delete comp2.isEditing;

      if (this.selectedRow.AccountNumber.length > 50) {
        this.selectedRow.AccountNumber = this.selectedRow.AccountNumber.slice(
          0,
          50
        );
      }
      if (this.selectedRow.CurrentCreditorAccountNumber.length > 50) {
        this.selectedRow.CurrentCreditorAccountNumber =
          this.selectedRow.CurrentCreditorAccountNumber.slice(0, 50);
      }

      if (!(JSON.stringify(comp1) == JSON.stringify(comp2))) {
        this.selectedRow.isEditing = true;
        this.saving = true;
        this.v$.selectedRow.Name.$touch();
        this.v$.selectedRow.OriginalBalance.$touch();
        this.v$.selectedRow.AccountHolderId.$touch();

        let dataToSave = Object.assign({}, this.selectedRow);
        delete dataToSave.isEditing;
        dataToSave.OriginalBalance = parseFloat(dataToSave.OriginalBalance);
        dataToSave.MonthlyPayment = parseFloat(dataToSave.MonthlyPayment);
        dataToSave.CurrentCreditorBalance = parseFloat(
          dataToSave.CurrentCreditorBalance
        );
        if (
          this.selectedRow.Name == "" ||
          this.selectedRow.OriginalBalance <= 0 ||
          this.selectedRow.AccountHolderId == 0
        ) {
          let mandatorymsg =
            "Creditor Name ,Original Balance and Account Holder fields are mandatory!";

          this.$swal({
            title: "Warning !",
            text: mandatorymsg,
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#1abc9c",
            confirmButtonText: "OK",
          }).then(async (dialog) => {
            if (dialog.value) {
              this.ignoreOutsideClick = true;
            }
          });
          return;
        }

        let err, result;
        [err, result] = await this.$store.dispatch(
          types.UPDATE_CREDITOR,
          dataToSave
        );
        if (result) {
          let indx = this.creditors.findIndex((x) => x.Id == item.Id);

          if (indx > -1) {
            let oldCreditorData = deepCopy(this.creditors[indx]);
            this.creditors[indx] = result.Data;
            this.creditors.push();
            if (oldCreditorData.Name != this.selectedRow.Name) {
              this.$emit("updateBlueBoxforNameChange");
            }
            if (
              oldCreditorData.OriginalBalance !=
                this.selectedRow.OriginalBalance ||
              oldCreditorData.CreditorStatusId !=
                this.selectedRow.CreditorStatusId
            ) {
              this.updateCustomerTotalDebt();
            }

            if (comp1.CreditorStatusId != comp2.CreditorStatusId) {
              if (this.checkAuth(3994)) {
                this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
                  CustomerId: this.customerId,
                  CreditorId: item.Id,
                  UserActivityTypeId: 8,
                  AdditionalNotes: "Updated Creditor Status",
                });
              }
            } else {
              if (this.checkAuth(3994)) {
                this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
                  CustomerId: this.customerId,
                  CreditorId: item.Id,
                  UserActivityTypeId: 18,
                  AdditionalNotes:
                    "Updated Creditor Information ( " + comp2.Name + " )",
                });
              }
            }

            this.$Emitter.emit("updateNegotiatorTask", result.Data);
            if (this.enrolledDate != null && this.enrolledDate.length > 1) {
              let errChange, resultChange;
              [errChange, resultChange] = await this.$store.dispatch(
                types.GET_CREDITOR_INFO,
                {
                  Id: this.customerId,
                }
              );

              if (resultChange && resultChange.Data) {
                this.creditorChangedList = deepCopy(resultChange.Data);
              }
            }
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.saving = false;

      this.selectedRow.isEditing = false;
      this.editSpinner = false;
      this.filterUsedCardNumbers();
    },

    async outsideOfNewRow() {
      if (!this.isNewRowActive) {
        return;
      }
      if (this.ignoreOutsideClick) {
        this.ignoreOutsideClick = false;
        return;
      }
      if (this.saving) return;

      if (JSON.stringify(this.selectedRow) == JSON.stringify(this.initRow)) {
        this.selectedRow = Object.assign({}, this.initRow);
        this.isNewRowActive = false;
      } else {
        if (
          this.selectedRow.Name != "" &&
          this.selectedRow.OriginalBalance > 0 &&
          this.selectedRow.AccountHolderId != 0
        ) {
          //save

          if (
            !this.checkAuth(6356) &&
            this.blockedCreditorList.includes(this.selectedRow.Name)
          ) {
            return;
          }

          this.saving = true;

          let dataToSave = Object.assign({}, this.selectedRow);

          delete dataToSave.isEditing;
          dataToSave.OriginalBalance = parseFloat(dataToSave.OriginalBalance);
          dataToSave.MonthlyPayment = parseFloat(dataToSave.MonthlyPayment);
          dataToSave.CurrentCreditorBalance = parseFloat(
            dataToSave.CurrentCreditorBalance
          );
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.INSERT_CREDITOR,
            dataToSave
          );
          if (result) {
            this.selectedRow = result.Data;
            this.creditors.push(this.selectedRow);
            this.selectedRow = Object.assign({}, this.initRow);
            this.isNewRowActive = false;
            this.updateCustomerTotalDebt();
            this.$Emitter.emit("updateNegotiatorTask", result.Data);
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
          this.saving = false;
          this.isNewRowActive = false;
        } else {
          let mandatorymsg =
            "Creditor Name ,Original Balance and Account Holder fields are mandatory!";

          this.$swal({
            title: "Warning !",

            text: mandatorymsg,

            type: "warning",

            showCancelButton: false,

            confirmButtonColor: "#1abc9c",

            confirmButtonText: "OK",
          }).then(async (dialog) => {
            if (dialog.value) {
              this.ignoreOutsideClick = true;
            }
          });
        }
      }
      this.filterUsedCardNumbers();
    },
    async handleNewRowSave() {
      if (JSON.stringify(this.selectedRow) != JSON.stringify(this.initRow)) {
        if (
          this.checkAuth(55004) &&
          !this.suggestCreditorNames.includes(this.selectedRow.Name)
        ) {
          this.$swal(
            "Error!",
            "You're not authorized to add a creditor out of the approved list.",
            "error"
          );
          return;
        }

        if (
          !this.checkAuth(6356) &&
          this.blockedCreditorList.includes(this.selectedRow.Name)
        ) {
          this.$swal(
            "Error!",
            "You're not authorized to add a creditor in the blocked creditor list.",
            "error"
          );
          return;
        }

        if (this.selectedRow.AccountNumber.length > 50) {
          this.selectedRow.AccountNumber = this.selectedRow.AccountNumber.slice(
            0,
            50
          );
        }
        if (this.selectedRow.CurrentCreditorAccountNumber.length > 50) {
          this.selectedRow.CurrentCreditorAccountNumber =
            this.selectedRow.CurrentCreditorAccountNumber.slice(0, 50);
        }
        if (
          !(
            this.selectedRow.Name != "" &&
            this.selectedRow.OriginalBalance > 0 &&
            this.selectedRow.AccountHolderId != 0
          )
        ) {
          let mandatorymsg =
            "Creditor Name ,Original Balance and Account Holder fields are mandatory!";

          this.$swal({
            title: "Warning !",
            text: mandatorymsg,
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#1abc9c",
            confirmButtonText: "OK",
          }).then(async (dialog) => {
            if (dialog.value) {
              this.ignoreOutsideClick = true;
            }
          });
          return;
        }

        this.saving = true;
        let dataToSave = Object.assign({}, this.selectedRow);

        delete dataToSave.isEditing;
        dataToSave.OriginalBalance = parseFloat(dataToSave.OriginalBalance);
        dataToSave.MonthlyPayment = parseFloat(dataToSave.MonthlyPayment);
        dataToSave.CurrentCreditorBalance = parseFloat(
          dataToSave.CurrentCreditorBalance
        );

        let err, result;
        [err, result] = await this.$store.dispatch(
          types.INSERT_CREDITOR,
          dataToSave
        );
        if (result) {
          this.selectedRow = result.Data;
          this.creditors.push(this.selectedRow);
          this.selectedRow = Object.assign({}, this.initRow);
          this.isNewRowActive = false;
          this.updateCustomerTotalDebt();
          if (this.enrolledDate != null && this.enrolledDate.length > 1) {
            let errChange, resultChange;
            [errChange, resultChange] = await this.$store.dispatch(
              types.GET_CREDITOR_INFO,
              {
                Id: this.customerId,
              }
            );

            if (resultChange && resultChange.Data) {
              this.creditorChangedList = deepCopy(resultChange.Data);
            }
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        this.isNewRowActive = false;
      }
      this.saving = false;
      this.filterUsedCardNumbers();
    },
    handleCancelNewRow() {
      this.selectedRow = Object.assign({}, this.initRow);
      this.isNewRowActive = false;
      this.ccnSuggestBoxOpen = false;
      this.suggestBoxOpen = false;
    },
    openFax() {
      this.$swal({
        title: "eFax",

        text: "Please contact Global/Zenith to enable eFax access.",

        type: "warning",

        showCancelButton: false,

        confirmButtonColor: "#1abc9c",

        confirmButtonText: "OK",
      });
    },
    updateCustomerTotalDebt() {
      let total = 0;

      let disabledCreditorStatusList = this.getDisabledCreditorStatus();
      let activeCreditors = this.creditors.filter(
        (x) => !disabledCreditorStatusList.includes(x.CreditorStatusId)
      );

      for (let item of activeCreditors) {
        total += parseFloat(item.OriginalBalance);
      }

      this.$emit("updateTotalCreditorAmount", total);
      this.$emit("updateTotalCreditorCount", activeCreditors.length);
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
        OriginalBalance: {
          hasValue(val) {
            return val > 0;
          },
        },
        AccountHolderId: {
          accountSelected(val) {
            return val > 0;
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.has-error {
  border: 2px solid red !important;
}
.stickyColumns {
  background-color: #fafafa;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
}
.stickyColumnsLeft,
.stickyColumnsRight {
  background-color: #fafafa;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}
.stickyColumnsRight {
  left: 3%;
}

.bothConditions {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 100%;
  display: block;
  background: rgb(241, 191, 191);
  background: linear-gradient(
    338deg,
    rgba(241, 191, 191, 1) 49%,
    rgba(99, 210, 148, 1) 49%
  );
}
.highlighted-row {
  text-decoration: line-through;
  text-decoration-color: black;
}
th {
  background: white;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 9;
}
.thPosition {
  position: relative;
  z-index: 8;
}
input:read-only {
  opacity: 0.65;
}

.status-container {
  width: 100%;
  position: relative;
  height: 15px;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 1px rgb(241, 241, 241) solid;
}

.tabledit-span {
  display: block;
  width: 100%;
}

.status-bar {
  height: 100%;
  background-color: #8fefb9;
  display: inline-block;
  white-space: nowrap;
  padding: 2px 0px;
  box-sizing: border-box;
}

.percentage-label {
  background: rgba(255, 255, 255, 0.479);
  border-radius: 3px;
  font-size: 10px;
  padding-right: 2px;
  padding-left: 2px;
}
.suggest-box {
  position: absolute;
  left: calc(100% + 10px);
  top: 10px;
  z-index: 999;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.suggest-box::after {
  content: "";
  position: absolute;
  top: 10px;
  left: -20px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}
.hoverPanel {
  width: 600px;
  /*height:  dynamically set by last5DispoHoverPanelHeight  */
  position: fixed;
  /*top,right:  dynamically set by last5DispoHoverPanelPosition */
  z-index: 999;
  background: #ffffff;
  border: 1px solid #3bafda;
}
</style>
