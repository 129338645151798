<!--
 * @file ClearedSettlementFeeReport.vue
 * @description This component is created for displaying 100% Cleared Settlement Fee Report.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 * 
 * @section Usage
 * This component can be used to display 100% Cleared Settlement Fee Report.
 -->

<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="filteredClearedSettlementList"
      reportName="ClearedSettlementFeeReport"
      :recordCount="filteredClearedSettlementList.length"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
      areDatesDisabled
    >
      <template #dateOptions>
        <option v-for="item in dateOptions" :key="item.key" :value="item.key">
          {{ item.label }}
        </option>
      </template>
    </ReportSummary>
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredClearedSettlementList"
        :paginated="isPaginated && filteredClearedSettlementList.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          v-for="(_filter, key) in filters"
          :field="key"
          :label="startCase(key)"
          :key="key"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table"
                v-model="filters[key]"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              v-if="key === 'CustomerId'"
              @click="viewCustomer(props.row)"
              :class="
                formatMixin.getTagReportHyperLinkClass(props.row.CustomerId)
              "
            >
              <span>
                {{ props.row.CustomerId }}
              </span>
            </span>
            <span v-else>
              {{ formatValue(props.row[key], key) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row)"
            class="btn btn-info waves-effect waves-light ml-3"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredClearedSettlementList.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { dateFormat } from "@/helpers/dateHelpers";
import fMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { startCase } from "lodash";
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ReportSummary from "./ReportSummary.vue";
import types from "./types";

const utilMixin = defineComponent(utilitiesMixin).methods;
const formatMixin = defineComponent(fMixin).methods;
const emitter = inject("emitter");

const store = useStore();

const setPayload = async (value) => {
  loadingSpinner.value = true;

  const { Option, StartDate, EndDate } = value;
  const format = "YYYY-MM-DD";

  const currentDate = dateFormat({ format });
  const payload = {
    StartDate:
      Option === "today"
        ? currentDate
        : dateFormat({ date: new Date(StartDate), format }),
    EndDate:
      Option === "today"
        ? currentDate
        : dateFormat({ date: new Date(EndDate), format }),
  };

  await store.dispatch(types.GET_CLEARED_SETTLEMENT_FEE_REPORT, payload);
  loadingSpinner.value = false;
};

const filters = ref({
  CustomerId: "",
  CreditorId: "",
  CreditorName: "",
  CurrentCreditorName: "",
  AccountNumber: "",
  Office: "",
  AssignedSalesAgent: "",
  PolicyGroup: "",
  CustomerStatus: "",
  ScheduledFeeTotal: "",
  SettlementDate: "",
  LastSettlementFeeScheduledDate: "",
  LastSettlementFeeClearedDate: "",
});

const dateOptions = [
  {
    key: "today",
    label: "Today",
  },
  {
    key: "customDateRange",
    label: "Custom Date Range",
  },
];

const reportLoading = ref(false);
const loadingSpinner = ref(false);
const isPaginated = ref(true);
const isPaginationSimple = ref(false);
const paginationPosition = ref("both");
const reportsPanelHeight = ref(300);
const perPage = ref(200);

const clearedSettlementList = computed(
  () => store.state.reports.clearedSettlementFeeReportList
);

const filteredClearedSettlementList = computed(() =>
  clearedSettlementList.value.length > 0
    ? clearedSettlementList.value.filter((item) => {
        return Object.keys(filters.value).every((key) =>
          utilMixin.filterString(item[key], filters.value[key])
        );
      })
    : []
);

const clearReport = () => {
  store.dispatch(types.RESET_REPORTS);
};

const setPagination = (data) => {
  isPaginated.value = data;
};

const viewCustomer = (customer) => {
  emitter.emit("viewCustomer", {
    customerId: customer.CustomerId.split("-")[1],
    customerName: `${customer.CustomerName}`,
  });
};

const formatValue = (value, key) => {
  if (value) {
    switch (key) {
      case "ScheduledFeeTotal":
        return formatMixin.formatMoney(value);

      default:
        return value;
    }
  }
  return "";
};

onMounted(() => {
  reportsPanelHeight.value = utilMixin.setReportGridHeight(
    window.innerHeight,
    document.getElementById("reportsSummary")
  );

  utilMixin.fixStickyHeaders();
});
</script>
