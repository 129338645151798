<template>
  <div
    class="col-md-12 newPanelWrapper noMargin smp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 100px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="monevoPopupHeader">
      <label style="font-size: 15px">Equifax</label>
      <!-- <label style="font-size: 15px">{{ getActivePanelLabel() }}</label> -->
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <div class="col-md-12 row">
      <perfect-scrollbar
        style="
          width: 100%;
          height: auto;
          max-height: 600px;
          overflow-x: hidden !important;
          margin-top: 0;
          padding-right: 10px;
        "
      >
        <div class="col-md-12 row" v-if="panels[0].IsActive">
          <div :class="getColClass()" v-if="ditEnabled">
            <div class="d-flex align-items-center">
              <label class="generalLabel mt-2 flex-fill">DIT Logs:</label>
              
              <button
              v-if="isUserValid()"
                @click="changePanel(2)"
                type="button"
                class="btn btn-xs btn-success "
              >
                New Validation
              </button>
           
            </div>
           
            <perfect-scrollbar
              style="
                width: 100%;
                height: auto;
                max-height: 450px;
                overflow-x: hidden !important;
                margin-top: 0;
              "
            >
              <section
                class="datagrid-table"
                style="border-radius: 0 !important"
              >
                <o-table
                  :data="ditLogs"
                  :default-sort-direction="'asc'"
                  :striped="true"
                  :hoverable="true"
                >
                  <o-table-column
                    field="RequestedBy"
                    label="Request By"
                    width="40%"
                    sortable
                    v-slot="props"
                    >{{ props.row.RequestedBy }}</o-table-column
                  >
                  <o-table-column
                    field="AuthDate"
                    label="Log Date"
                    width="30%"
                    sortable
                    v-slot="props"
                    >{{ formatGridDate(props.row.AuthDate) }}</o-table-column
                  >
                  <o-table-column
                    field="Status"
                    label="Status"
                    width="30%"
                    v-slot="props"
                    sortable
                  >
                    {{ props.row.Status }}
                    <i
                      v-if="
                        props.row.Status &&
                        props.row.Status.toLowerCase() == 'pending'
                      "
                      @click="showQuestions(props.row)"
                      title="Show questions"
                      class="fas fa-question-circle mt-1 ml-2"
                      style="color: #3bafda; cursor: pointer; font-size: 20px"
                    ></i>
                    <i
                      v-if="
                        props.row.Status &&
                        props.row.Status.toLowerCase() == 'complete'
                      "
                      @click="requestCreditReportForDit(props.row)"
                      title="Show questions"
                      class="mdi mdi-file-check-outline mt-1"
                      style="color: #159a80; cursor: pointer; font-size: 20px"
                    ></i>
                  </o-table-column>
                </o-table>
                <section
                  v-if="ditLogs.length == 0"
                  class="section"
                  style="text-align: center"
                >
                  <div class="content has-text-grey has-text-centered mb-4">
                    <p class="noMargin">
                      <i
                        style="font-size: 24px; color: #939393"
                        class="ri-question-line"
                      ></i>
                    </p>
                    <p class="noMargin">Nothing here.</p>
                  </div>
                </section>
              </section>
            </perfect-scrollbar>
          </div>

          <div :class="getColClass()" v-if="mfaEnabled">
            <div class="d-flex align-items-center">
            <label class="generalLabel mt-2 flex-fill">MFA Logs:</label>
      
              <button
              v-if="mfaEnabled && (!ditEnabled || ditDenyLogExists())&&  isUserValid()"
                @click="changePanel(6)"
                type="button"
                class="btn btn-xs  btn-success"
              >
                New MFA Request
              </button>
          
          </div>
            <perfect-scrollbar
              style="
                width: 100%;
                height: auto;
                max-height: 450px;
                overflow-x: hidden !important;
                margin-top: 0;
              "
            >
              <section
                class="datagrid-table"
                style="border-radius: 0 !important"
              >
                <o-table
                  :data="mfaLogs"
                  :default-sort-direction="'asc'"
                  :striped="true"
                  :hoverable="true"
                >
                  <o-table-column
                    field="RequestedBy"
                    label="Request By"
                    width="40%"
                    sortable
                    v-slot="props"
                    >{{ props.row.RequestedBy }}</o-table-column
                  >
                  <o-table-column
                    field="AuthDate"
                    label="Log Date"
                    width="30%"
                    sortable
                    v-slot="props"
                    >{{ formatGridDate(props.row.AuthDate) }}</o-table-column
                  >
                  <o-table-column
                    field="Status"
                    label="Status"
                    width="30%"
                    v-slot="props"
                    sortable
                  >
                    {{ props.row.Status }}
                    <!-- <i
                      v-if="
                        props.row.Status &&
                        props.row.Status.toLowerCase() != 'valid' &&
                        props.row.Status.toLowerCase() != 'sent' &&
                        props.row.SessionId &&
                        props.row.SessionId != ''
                      "
                      @click="openMfaRequestPanel(props.row)"
                      title="Continue to validate"
                      class="fas fa-question-circle mt-1 ml-2"
                      style="color: #3bafda; cursor: pointer; font-size: 20px"
                    ></i> -->
                    <i
                      v-if="
                        props.row.Status &&
                        props.row.Status.toLowerCase() == 'sent'
                      "
                      @click="openMfaRequestPanel(props.row)"
                      title="Continue to validate"
                      class="fas fa-question-circle mt-1 ml-2"
                      style="color: #3bafda; cursor: pointer; font-size: 20px"
                    ></i>
                    <i
                      v-if="
                        props.row.Status &&
                        props.row.Status.toLowerCase() == 'valid'
                      "
                      @click="requestCreditReportForMfa(props.row)"
                      title="Show questions"
                      class="mdi mdi-file-check-outline mt-1"
                      style="color: #159a80; cursor: pointer; font-size: 20px"
                    ></i>
                  </o-table-column>
                </o-table>
                <section
                  v-if="mfaLogs.length == 0"
                  class="section"
                  style="text-align: center"
                >
                  <div class="content has-text-grey has-text-centered mb-4">
                    <p class="noMargin">
                      <i
                        style="font-size: 24px; color: #939393"
                        class="ri-question-line"
                      ></i>
                    </p>
                    <p class="noMargin">Nothing here.</p>
                  </div>
                </section>
              </section>
            </perfect-scrollbar>
          </div>

          <div v-if="!ditEnabled && !mfaEnabled && !apiInfoEnabled" class="col-md-12 text-center">
            <label style="font-size: 20px; color: #939393; margin-top: 20px">
              No Equifax Product Available
            </label>
          </div>

          <div
            class="row col-md-12"
            style="display: flex; justify-content: space-evenly"
          >
            
          <div v-if="ditEnabled && !isUserValid()">
              <button
                @click="changePanel(2)"
                type="button"
                class="btn btn-success mt-2"
              >
                New Validation
              </button>
            </div>

            <div v-if="mfaEnabled && (!ditEnabled || ditDenyLogExists()) && !isUserValid()">
              <button
                @click="changePanel(6)"
                type="button"
                class="btn btn-success mt-2"
              >
                New MFA Request
              </button>
            </div>
          <button
                v-if="isUserValid()"
                @click="requestOneview"
                type="button"
                class="btn btn-success mt-2"
              >
                Request Credit Report
              </button>
          </div>
         
        </div>
        <div class="col-md-12" v-if="panels[1].IsActive">
          <div class="row" v-if="customerInfo != null">
            <div class="col-md-4">
              <label class="generalLabel">First Name</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="First Name"
                v-model="customerInfo.FirstName"
                :class="v$.customerInfo.FirstName.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.FirstName.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Middle Name</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="Middle Initial"
                v-model="customerInfo.MiddleName"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Last Name</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="Last Name"
                v-model="customerInfo.LastName"
                :class="v$.customerInfo.LastName.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.LastName.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Date of Birth</label>
              <DatePicker
                mode="date"
                :locale="'en-US'"
                v-model="customerInfo.DateOfBirth"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Date of Birth"
                    :class="
                      v$.customerInfo.DateOfBirth.$error ? 'has-error' : ''
                    "
                    @blur="v$.customerInfo.DateOfBirth.$touch"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-md-4">
              <label class="generalLabel">SSN</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="SSN"
                v-myMask="{ mask: ['999-99-9999'] }"
                v-model="customerInfo.Ssn"
                :class="v$.customerInfo.Ssn.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.Ssn.$touch"
              />
            </div>

            <div class="col-md-4">
              <label class="generalLabel">Address Line1</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="Line1"
                v-model="customerInfo.AddressLine1"
                :class="v$.customerInfo.AddressLine1.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.AddressLine1.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Address Line2</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="Line2"
                v-model="customerInfo.AddressLine2"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Zip Code</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                v-myMask="{ mask: ['99999'] }"
                placeholder="Zip Code"
                v-model="customerInfo.Zip"
                :class="v$.customerInfo.Zip.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.Zip.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">City</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="City"
                v-model="customerInfo.City"
                :class="v$.customerInfo.City.$error ? 'has-error' : ''"
                @blur="v$.customerInfo.City.$touch"
              />
            </div>
            <div class="col-md-4">
              <label class="generalLabel">State</label>
              <select
                v-model="customerInfo.StateId"
                id="State"
                class="generalSelectBox form-control"
              >
                <option v-for="item in states" :key="item.Id" :value="item.Id">
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="generalLabel">Reference Id</label>
              <input
                type="text"
                class="form-control"
                autocomplete="none"
                placeholder="First Name"
                v-model="tempReferenceId"
              />
            </div>
            <div class="col-md-12 text-center">
              <button
                @click="sendDitRequest()"
                :disabled="v$.$invalid"
                type="button"
                class="btn btn-success mt-2"
              >
                Send Validation Request
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[2].IsActive">
          <div class="row" v-if="questions != null && questions.length > 0">
            <div
              class="col-md-12"
              v-for="item in questions"
              :key="item.questionId"
            >
              <label class="generalLabel w-100">{{ item.questionText }}</label>
              <div
                v-for="c in item.choices"
                :key="c.choiceId"
                class="col-md-12"
              >
                <div class="d-flex align-items-center">
                  <input
                    class="mr-1"
                    type="radio"
                    :value="c.choiceId"
                    :name="'answer-' + item.questionId"
                    v-model="item.answers"
                  />
                  <label class="noMargin">{{ c.choiceText }}</label>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <button
                :disabled="!isValidationRequestEnabled()"
                @click="sendValidationRequest"
                type="button"
                class="btn btn-success mt-2"
              >
                Validate
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[3].IsActive">
          <div class="row">
            <div class="col-md-12 text-center">
              <label class="w-100 generalLabel"
                >Digital identity verification failed.</label
              >
              <button
                v-if="mfaEnabled"
                @click="requestMFA"
                type="button"
                class="btn btn-success mt-2"
              >
                Additional Authentication
              </button>
              <button
                @click="changePanel(2)"
                type="button"
                class="btn btn-success mt-2 ml-2"
              >
                New Validation
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[4].IsActive">
          <div class="row">
            <div class="col-md-12 text-center">
              <label class="w-100 generalLabel"
                >Digital identity verified successfully.</label
              >
              <button
                v-if="apiInfoEnabled"
                @click="requestOneview"
                type="button"
                class="btn btn-success mt-2"
              >
                Request Credit Report
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[5].IsActive">
          <div class="row">
            <div class="col-md-12 text-center">
              <label class="w-100 generalLabel"
                >Please select your validation method.</label
              >
              <div class="col-md-12 pl-2 form-check form-switch">
                <div
                  class="form-check form-check-inline mt-3"
                  v-if="customerInfo.Email != null && customerInfo.Email != ''"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    id="mfaRadio"
                    name="mfaEnabledRadio"
                    :value="0"
                    v-model="mfaSelection"
                  />
                  <label class="form-check-label" for="mfaRadio">Email</label>
                </div>
                <div
                  class="form-check form-check-inline"
                  v-if="
                    customerInfo.PhoneNumber != null &&
                    customerInfo.PhoneNumber != ''
                  "
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    id="mfaRadio2"
                    name="mfaEnabledRadio"
                    :value="1"
                    v-model="mfaSelection"
                  />
                  <label class="form-check-label" for="mfaRadio2">Phone</label>
                </div>

                <label
                  v-if="!customerInfo.Email && !customerInfo.PhoneNumber"
                  class="w-100 generalLabel"
                  >Email or Phone not registered.</label
                >

                <div class="col-md-12 mt-3">
                  <button
                    @click="sendMfaRequest()"
                    :disabled="mfaSelection == null"
                    class="btn btn-success mt-2"
                  >
                    Send Authentication
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[6].IsActive">
          <div class="row">
            <div class="col-md-12 text-center">
              <label class="w-100 generalLabel"
                >A verification link has been sent to the customer using the
                selected method. Once the customer opens the link, please click
                the "Validate" button to proceed.</label
              >
              <div class="col-md-12 pl-2 form-check form-switch">
                <div class="col-md-12 mt-3">
                  <button @click="validateMFA()" class="btn btn-success mt-2">
                    Validate MFA
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[7].IsActive">
          <div class="row">
            <div class="col-md-12 text-center">
              <label class="w-100 generalLabel"
                >Multi-factor authentication verified successfully.</label
              >
              <button
                v-if="apiInfoEnabled"
                @click="requestOneview"
                type="button"
                class="btn btn-success mt-2"
              >
                Request Credit Report
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="panels[8].IsActive || (apiInfoEnabled && !ditEnabled && !mfaEnabled)">
          <div class="row" v-if="customerInfo" >
            <div class="col-md-4">
              <label for="requestFor" class="generalLabel">Request For</label>
              <select
                id="requestFor"
                class="generalSelectBox form-control"
                v-model="selectedProfile"
              >
                <option value="customer">
                  {{ customerInfo.FirstName + " " + customerInfo.LastName }}
                </option>
                <option v-if="coCustomerInfo" value="coCustomer">
                  {{ coCustomerInfo.FirstName + " " + coCustomerInfo.LastName }}
                </option>
                <option v-if="coCustomerInfo" value="joint">Joint</option>
              </select>
            </div>

            <template
              v-if="selectedProfile == 'customer' || selectedProfile == 'joint'"
            >
              <div class="col-md-12">
                <h5>Customer Information</h5>
              </div>
              <div class="col-md-4">
                <label class="generalLabel">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="First Name"
                  v-model="customerInfo.FirstName"
                  :class="v$.customerInfo.FirstName.$error ? 'has-error' : ''"
                  @blur="v$.customerInfo.FirstName.$touch"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Middle Name</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="Middle Initial"
                  v-model="customerInfo.MiddleName"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="Last Name"
                  v-model="customerInfo.LastName"
                  :class="v$.customerInfo.LastName.$error ? 'has-error' : ''"
                  @blur="v$.customerInfo.LastName.$touch"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Date of Birth</label>
                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="customerInfo.DateOfBirth"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Date of Birth"
                      :class="
                        v$.customerInfo.DateOfBirth.$error ? 'has-error' : ''
                      "
                      @blur="v$.customerInfo.DateOfBirth.$touch"
                    />
                  </template>
                </DatePicker>
              </div>
              <div class="col-md-4">
                <label class="generalLabel">SSN</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="SSN"
                  v-myMask="{ mask: ['999-99-9999'] }"
                  v-model="customerInfo.Ssn"
                  :class="v$.customerInfo.Ssn.$error ? 'has-error' : ''"
                  @blur="v$.customerInfo.Ssn.$touch"
                />
              </div>

              <div class="col-md-4">
                <label class="generalLabel">Address Line1</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="Line1"
                  v-model="customerInfo.AddressLine1"
                  :class="
                    v$.customerInfo.AddressLine1.$error ? 'has-error' : ''
                  "
                  @blur="v$.customerInfo.AddressLine1.$touch"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Address Line2</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="Line2"
                  v-model="customerInfo.AddressLine2"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Zip Code</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  v-myMask="{ mask: ['99999'] }"
                  placeholder="Zip Code"
                  v-model="customerInfo.Zip"
                  :class="v$.customerInfo.Zip.$error ? 'has-error' : ''"
                  @blur="v$.customerInfo.Zip.$touch"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">City</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="City"
                  v-model="customerInfo.City"
                  :class="v$.customerInfo.City.$error ? 'has-error' : ''"
                  @blur="v$.customerInfo.City.$touch"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">State</label>
                <select
                  v-model="customerInfo.StateId"
                  id="State"
                  class="generalSelectBox form-control"
                >
                  <option
                    v-for="item in states"
                    :key="item.Id"
                    :value="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </div>
            </template>

            <template
              v-if="
                (selectedProfile == 'coCustomer' || selectedProfile == 'joint') && coCustomerInfo
              "
            >
              <div class="col-md-12">
                <h5>Co-Customer Information</h5>
              </div>
              <div class="col-md-4">
                <label class="generalLabel">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="First Name"
                  v-model="coCustomerInfo.FirstName"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Middle Name</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="Middle Initial"
                  v-model="coCustomerInfo.MiddleName"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="Last Name"
                  v-model="coCustomerInfo.LastName"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Date of Birth</label>
                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="coCustomerInfo.DateOfBirth"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Date of Birth"
                    />
                  </template>
                </DatePicker>
              </div>
              <div class="col-md-4">
                <label class="generalLabel">SSN</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="SSN"
                  v-myMask="{ mask: ['999-99-9999'] }"
                  v-model="coCustomerInfo.Ssn"
                />
              </div>

              <div class="col-md-4">
                <label class="generalLabel">Address Line1</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="Line1"
                  v-model="coCustomerInfo.AddressLine1"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Address Line2</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="Line2"
                  v-model="coCustomerInfo.AddressLine2"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">Zip Code</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  v-myMask="{ mask: ['99999'] }"
                  placeholder="Zip Code"
                  v-model="coCustomerInfo.Zip"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">City</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="none"
                  placeholder="City"
                  v-model="coCustomerInfo.City"
                />
              </div>
              <div class="col-md-4">
                <label class="generalLabel">State</label>
                <select
                  v-model="coCustomerInfo.StateId"
                  id="State"
                  class="generalSelectBox form-control"
                >
                  <option
                    v-for="item in states"
                    :key="item.Id"
                    :value="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </div>
            </template>

            <div class="col-md-12 text-center">
              <button
                @click="sendRequest()"
                :disabled="v$.$invalid || isLoading"
                type="button"
                class="btn btn-success mt-2"
              >
                Request
              </button>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import { DatePicker } from "v-calendar";
import deepCopy from "@/helpers/deepCopy";
import { mapState } from "vuex";
import types from "./types";
import globalTypes from "@/store/types";
export default {
  name: "EquifaxPopup",
  components: { DatePicker },
  mixins: [formatMixin, utilitiesMixin],

  props: {
    data: {
      Type: Object,
      Defult: null,
    },
    coCustomerData: {
      Type: Object,
      Defult: null,
    },
  },
  computed: mapState({
    states: (state) => state.globals.states,
  }),
  data() {
    return {
      apiInfoEnabled: false,
      selectedProfile: "customer",
      ditEnabled: false,
      mfaEnabled: false,
      mfaSelection: null,
      isLoading: false,
      v$: useValidate(),
      customerInfo: null,
      coCustomerInfo: null,
      tempReferenceId: "test-0053",
      equifaxResponseObj: {
        CreditPullEquifaxAuthId: 0,
        Status: "",
        CorrelationId: 0,
        QuestionnaireId: 0,
        AuthenticationId: "",
      },
      panels: [
        { Id: 1, Name: "DitLogs", Label: "DIT Logs", IsActive: true },
        {
          Id: 2,
          Name: "DitRequest",
          Label: "New Validation",
          IsActive: false,
        },
        { Id: 3, Name: "Questions", Label: "Questions", IsActive: false },
        {
          Id: 4,
          Name: "DitMfaValidation",
          Label: "DIT MFA Validation",
          IsActive: false,
        },
        {
          Id: 5,
          Name: "Oneview",
          Label: "Request Credit Report",
          IsActive: false,
        },
        {
          Id: 6,
          Name: "MFARequestPanel",
          Label: "MFA Request Panel",
          IsActive: false,
        },
        {
          Id: 7,
          Name: "MfaValidationPanel",
          Label: "MFA Validation Panel",
          IsActive: false,
        },
        {
          Id: 8,
          Name: "MfaValidationSuccessPanel",
          Label: "MFA Validation Success",
          IsActive: false,
        },
        {
          Id: 9,
          Name: "RequestCreditReportPanel",
          Label: "Request Credit Report",
          IsActive: false,
        },
      ],
      ditLogs: [],
      questions: [],
      mfaLogs: [],
      mfaEquifaxAuthId: 0,
      mfaEquifaxSessionId: 0,
    };
  },
  unmounted() {},
  async mounted() {
    if (this.data) {
      await this.getApiSettings();
      this.customerInfo = this.data;
      if (this.customerInfo.CustomerId > 0) {
        this.isLoading = true;
        try {
          await this.$store.dispatch(globalTypes.GET_STATES);
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.GET_EQUIFAX_DIT_LOGS,
            { CustomerId: this.customerInfo.CustomerId }
          );

          if (result && result.Data) {
            this.ditLogs = deepCopy(result.Data);
          }
        } catch {
          //
        }
        try {
          let errMfa, resultMfa;
          [errMfa, resultMfa] = await this.$store.dispatch(
            types.GET_EQUIFAX_MFA_LOGS,
            { CustomerId: this.customerInfo.CustomerId }
          );

          if (resultMfa && resultMfa.Data) {
            this.mfaLogs = deepCopy(resultMfa.Data);
            if (this.mfaLogs && this.mfaLogs.length > 0) {
              for (let item of this.mfaLogs) {
                if (item.Status && item.Status.toLowerCase() == "sent") {
                  let secondsPassed = this.getTimeDifference(
                    new Date(),
                    new Date(item.AuthDate)
                  );
                  if (parseInt(secondsPassed) > 315) {
                    item.Status = "expired";
                  }
                }
              }
            }
          }
        } catch {
          //
        }
        this.isLoading = false;
      }
    }
    if (this.coCustomerData) {
      this.coCustomerInfo = this.coCustomerData;
    }
  },
  methods: {
    isUserValid(){

      let rtn= false;
      if(this.ditLogs && this.ditLogs.length>0)
      {
        rtn=this.ditLogs.some(x=> x.Status== "complete");
      }
    
      if(rtn== false && this.mfaLogs && this.mfaLogs.length>0)
      {
        rtn=this.mfaLogs.some(x=> x.Status=="valid");
      }
      return rtn;

    },
    requestOneview() {
      this.changePanel(9);
    },

    async sendRequest() {
      let payload;

      let customerSsn = "";
      if (
        this.customerInfo &&
        this.customerInfo.Ssn &&
        this.customerInfo.Ssn.length > 0
      ) {
        customerSsn = await this.AES256_GCM_ENCRYPT(this.customerInfo.Ssn);
      }
      let coCustomerSsn = "";
      if (
        this.coCustomerInfo &&
        this.coCustomerInfo.Ssn &&
        this.coCustomerInfo.Ssn.length > 0
      ) {
        coCustomerSsn = await this.AES256_GCM_ENCRYPT(this.coCustomerInfo.Ssn);
      }

      if (this.selectedProfile == "joint") {
        payload = {
          CustomerId: this.customerInfo.CustomerId,
          Ssn: customerSsn,
          FirstName: this.customerInfo.FirstName,
          LastName: this.customerInfo.LastName,
          MiddleName: this.customerInfo.MiddleName,
          CustomerDob: this.customerInfo.DateOfBirth,
          Address: {
            Street:
              this.customerInfo.AddressLine1 +
              " " +
              this.customerInfo.AddressLine2,
            City: this.customerInfo.City,
            State: this.customerInfo.StateId,
            Zip: this.customerInfo.Zip,
          },
          CoCustomerId: this.coCustomerInfo
            ? this.coCustomerInfo.CustomerId
            : 0,
          CoCustomerSsn: coCustomerSsn,
          CoCustomerFirstName: this.coCustomerInfo
            ? this.coCustomerInfo.FirstName
            : "",
          CoCustomerLastName: this.coCustomerInfo
            ? this.coCustomerInfo.LastName
            : "",
          CoCustomerMiddleName: this.coCustomerInfo
            ? this.coCustomerInfo.MiddleName
            : "",
          CoCustomerDob: this.coCustomerInfo
            ? this.coCustomerInfo.DateOfBirth
            : "",
          CoCustomerAddress: {
            Street: this.getAddressText(this.coCustomerInfo),
            City: this.coCustomerInfo ? this.coCustomerInfo.City : "",
            State: this.coCustomerInfo ? this.coCustomerInfo.StateId : 0,
            Zip: this.coCustomerInfo ? this.coCustomerInfo.Zip : 0,
          },
          IsCoCustomer: false,
          ServiceId: 6,
        };
      } else if (this.selectedProfile == "customer") {
        payload = {
          CustomerId: this.customerInfo.CustomerId,
          Ssn: customerSsn,
          FirstName: this.customerInfo.FirstName,
          LastName: this.customerInfo.LastName,
          MiddleName: this.customerInfo.MiddleName,
          CustomerDob: this.customerInfo.DateOfBirth,
          Address: {
            Street: this.getAddressText(this.customerInfo),
            City: this.customerInfo.City,
            State: this.customerInfo.StateId,
            Zip: this.customerInfo.Zip,
          },
          IsCoCustomer: false,
          ServiceId: 6,
        };
      } else {
        payload = {
          CustomerId: this.customerInfo.CustomerId,
          Ssn: coCustomerSsn,
          FirstName: this.coCustomerInfo ? this.coCustomerInfo.FirstName : "",
          LastName: this.coCustomerInfo ? this.coCustomerInfo.LastName : "",
          MiddleName: this.coCustomerInfo ? this.coCustomerInfo.MiddleName : "",
          CustomerDob: this.coCustomerInfo
            ? this.coCustomerInfo.DateOfBirth
            : "",
          Address: {
            Street: this.getAddressText(this.coCustomerInfo),
            City: this.coCustomerInfo ? this.coCustomerInfo.City : "",
            State: this.coCustomerInfo ? this.coCustomerInfo.StateId : 0,
            Zip: this.coCustomerInfo ? this.coCustomerInfo.Zip : 0,
          },
          IsCoCustomer: true,
          ServiceId: 6,
        };
      }
      this.isLoading = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.REQUEST_EQUIFAX_CREDIT_REPORT,
        payload
      );
      if (result) {
        this.$swal(
          "Success",
          "Credit pull requested, please check the status from pull history shortly.",
          "success"
        );
        this.closePanel();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isLoading = false;
    },

    getAddressText(data) {
      let rtn = "";
      if (data) {
        if (data.AddressLine1 && data.AddressLine1 != "") {
          rtn = data.AddressLine1;
        }
        if (data.AddressLine2 && data.AddressLine2 != "") {
          rtn += " " + data.AddressLine2;
        }
      }
      return rtn;
    },
    ditDenyLogExists() {
      let rtn = false;

      if (this.ditLogs && this.ditLogs.length > 0) {
        rtn = this.ditLogs.some(
          (x) => x.Status && x.Status.toLowerCase() == "denied"
        );
      }

      return rtn;
    },
    requestCreditReportForDit(row) {
      this.changePanel(5);
    },
    requestCreditReportForMfa(row) {
      this.changePanel(8);
    },
    async getApiSettings() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_EQUIFAX_API_SETTINGS
      );

      if (result) {
        if (result.Data) {
          this.ditEnabled = result.Data.DigitalIdentityTrustInfoEnabled
            ? result.Data.DigitalIdentityTrustInfoEnabled
            : false;
          this.mfaEnabled = result.Data.SecureMfaInfoEnabled
            ? result.Data.SecureMfaInfoEnabled
            : false;
          this.apiInfoEnabled = result.Data.ApiInfoEnabled
            ? result.Data.ApiInfoEnabled
            : false;
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    openMfaRequestPanel(mfaLogItem) {
      let secondsPassedForExpire = this.getTimeDifference(
        new Date(),
        new Date(mfaLogItem.AuthDate)
      );

      if (parseInt(secondsPassedForExpire) > 315) {
        this.$swal("Warning", "Link is expired.", "warning");
        let indx = this.mfaLogs.findIndex(
          (x) => x.CreditPullEquifaxAuthId == mfaLogItem.CreditPullEquifaxAuthId
        );
        if (indx > -1) {
          this.mfaLogs[indx].Status = "expired";
        }

        return;
      }

      this.mfaEquifaxAuthId = 0;
      this.mfaEquifaxSessionId = 0;
      if (mfaLogItem) {
        this.mfaEquifaxAuthId = mfaLogItem.CreditPullEquifaxAuthId;
        this.mfaEquifaxSessionId = mfaLogItem.SessionId;
        if (mfaLogItem.subjectType == "EMAIL") {
          this.mfaSelection = "0";
        } else {
          this.mfaSelection = "1";
        }

        this.changePanel(7);
      }
    },
    async sendMfaRequest() {
      if (this.customerInfo && this.customerInfo.CustomerId > 0) {
        this.isLoading = true;
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.SEND_EQUIFAX_MFA_REQUEST,
          {
            CustomerId: this.customerInfo.CustomerId,
            CreditPullEquifaxAuthId: this.mfaEquifaxAuthId,
            Email: this.mfaSelection == "0" ? this.customerInfo.Email : "",
            Phone:
              this.mfaSelection == "1" ? this.customerInfo.PhoneNumber : "",
          }
        );
        if (result) {
          if (result.Data && result.Data.SessionId) {
            this.mfaEquifaxSessionId = result.Data.SessionId;
            this.mfaEquifaxAuthId = result.Data.CreditPullEquifaxAuthId;
            this.changePanel(7);
          } else {
            this.$swal("Error", "Request failed.", "error");
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
        this.isLoading = false;
      }
    },
    async validateMFA() {
      if (
        this.customerInfo &&
        this.customerInfo.CustomerId > 0 &&
        this.mfaEquifaxAuthId > 0
      ) {
        this.isLoading = true;

        let err, result;
        [err, result] = await this.$store.dispatch(
          types.SEND_EQUIFAX_MFA_VALIDATION_REQUEST,
          {
            CustomerId: this.customerInfo.CustomerId,
            CreditPullEquifaxAuthId: this.mfaEquifaxAuthId,
            Email: this.mfaSelection == "0" ? this.customerInfo.Email : "",
            Phone:
              this.mfaSelection == "1" ? this.customerInfo.PhoneNumber : "",
            SessionId: this.mfaEquifaxSessionId,
          }
        );
        if (result) {
          if (
            result.Data &&
            result.Data.Status &&
            result.Data.Status.toLowerCase() == "valid"
          ) {
            this.changePanel(8);
          } else {
            this.$swal("Error", "Validation failed.", "error");
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
        this.isLoading = false;
      }
    },
    getColClass() {
      if (this.ditEnabled && this.mfaEnabled) {
        return "col-md-6";
      } else if (this.ditEnabled || this.mfaEnabled) {
        return "col-md-12";
      }
      return "d-none";
    },

    getStateShort(stateId) {
      let rtn = "";
      if (this.states && this.states.length > 0) {
        let stateIndex = this.states.findIndex((x) => x.Id == stateId);
        rtn = stateIndex > -1 ? this.states[stateIndex].ShortCode : "";
      }
      return rtn;
    },
    isValidationRequestEnabled() {
      let rtn = false;

      if (this.questions && this.questions.length > 0) {
        let noneAnsweredList = this.questions.filter((x) => x.answers == "");
        if (noneAnsweredList.length == 0) {
          rtn = true;
        }
      }

      return rtn;
    },
    closePanel() {
      this.$emit("closeEquifaxPopup");
    },
    showQuestions(obj) {
      if (obj) {
        this.equifaxResponseObj.CreditPullEquifaxAuthId =
          obj.CreditPullEquifaxAuthId;
        this.equifaxResponseObj.CorrelationId = obj.CorrelationId;
        this.equifaxResponseObj.QuestionnaireId = obj.QuestionnaireId;
        this.equifaxResponseObj.AuthenticationId = obj.AuthenticationId;
        if (obj.Questions && obj.Questions.length > 0) {
          this.questions = deepCopy(obj.Questions);
          for (let item of this.questions) {
            item.answers = "";
          }
          this.changePanel(3);
        }
      }
    },
    changePanel(toId) {
      for (let item of this.panels) {
        item.IsActive = false;
      }
      let toIndex = this.panels.findIndex((x) => x.Id == toId);
      if (toIndex > -1) {
        this.panels[toIndex].IsActive = true;
      }
    },
    async sendDitRequest() {
      let dobFormatted = "";
      if (this.customerInfo.DateOfBirth) {
        dobFormatted = this.formatShortDate(this.customerInfo.DateOfBirth);
      }

      let payload = {
        CustomerId: this.customerInfo.CustomerId,
        ReferenceId:
          this.tempReferenceId != ""
            ? this.tempReferenceId
            : this.customerInfo.CustomerId + "-dit-initiate",
        Ssn: this.customerInfo.Ssn,
        FirstName: this.customerInfo.FirstName,
        LastName: this.customerInfo.LastName,
        MiddleName: this.customerInfo.MiddleName,
        AddressLine1: this.customerInfo.AddressLine1,
        AddressLine2: this.customerInfo.AddressLine2
          ? this.customerInfo.AddressLine2
          : "",
        City: this.customerInfo.City,
        State: this.getStateShort(this.customerInfo.StateId),
        PostalCode: this.customerInfo.Zip,
        Dob: dobFormatted,
      };

      this.isLoading = true;
      try {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.SEND_EQUIFAX_DIT_REQUEST,
          payload
        );
        if (result) {
          if (result.Data) {
            let resultStatus = result.Data.Status
              ? result.Data.Status.toLowerCase()
              : "";
            if (resultStatus == "complete") {
              this.changePanel(5);
            } else if (resultStatus == "pending") {
              this.questions = deepCopy(result.Data.Questions);
              if (
                typeof this.questions != "undefined" &&
                this.questions &&
                this.questions.length > 0
              ) {
                for (let item of this.questions) {
                  item.answers = "";
                }
              }
              this.equifaxResponseObj.CreditPullEquifaxAuthId =
                result.Data.CreditPullEquifaxAuthId;
              this.equifaxResponseObj.Status = result.Data.Status;
              this.equifaxResponseObj.CorrelationId = result.Data.CorrelationId;
              this.equifaxResponseObj.QuestionnaireId =
                result.Data.QuestionnaireId;
              this.equifaxResponseObj.AuthenticationId =
                result.Data.AuthenticationId;
              this.changePanel(3);
            } else if (resultStatus == "denied") {
              this.changePanel(4);
            }
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } catch {
        //
      }
      this.isLoading = false;
    },
    async sendValidationRequest() {
      let questionAnswers = [];
      if (this.questions && this.questions.length > 0) {
        for (let item of this.questions) {
          questionAnswers.push({
            questionId: item.questionId,
            answers: [parseInt(item.answers)],
          });
        }
      }

      let payload = {
        ReferenceId:
          this.tempReferenceId != ""
            ? this.tempReferenceId
            : this.customerInfo.CustomerId + "-dit-kba",
        CustomerId: this.customerInfo.CustomerId,
        CreditPullEquifaxAuthId:
          this.equifaxResponseObj.CreditPullEquifaxAuthId,
        CorrelationId: this.equifaxResponseObj.CorrelationId,
        QuestionnaireId: this.equifaxResponseObj.QuestionnaireId,
        AuthenticationId: this.equifaxResponseObj.AuthenticationId,
        QuestionAnswers: deepCopy(questionAnswers),
      };
      this.isLoading = true;
      try {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.SEND_EQUIFAX_KBA_ANSWERS,
          payload
        );
        if (result) {
          let resultStatus = result.Data.Status
            ? result.Data.Status.toLowerCase()
            : "";
          if (resultStatus == "complete") {
            this.changePanel(5);
          } else if (resultStatus == "denied") {
            this.mfaEquifaxAuthId = result.Data.CreditPullEquifaxAuthId;
            this.changePanel(4);
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } catch {
        //
      }
      this.isLoading = false;
    },
    requestMFA() {
      this.changePanel(6);
    },
    getActivePanelLabel() {
      let rtn = "";
      if (this.panels && this.panels.length > 0) {
        let indx = this.panels.findIndex((x) => x.IsActive == true);
        if (indx > -1) {
          rtn = "- " + this.panels[indx].Label;
        }
      }
      return rtn;
    },
  },

  validations() {
    let validations = {
      customerInfo: {
        FirstName: { required },
        LastName: { required },
        Ssn: { required },
        DateOfBirth: { required },
        City: { required },
        AddressLine1: { required },
        Zip: { required },
      },
      coCustomerInfo: {
        FirstName: { required },
        LastName: { required },
        Ssn: { required },
        DateOfBirth: { required },
        City: { required },
        AddressLine1: { required },
        Zip: { required },
      },
    };

    if (this.selectedProfile == "customer") {
      if (this.customerInfo) {
        return {
          customerInfo: validations.customerInfo,
        };
      }
    } else if (this.selectedProfile == "coCustomer") {
      if (this.coCustomerInfo) {
        return {
          coCustomerInfo: validations.coCustomerInfo,
        };
      }
    } else if (this.selectedProfile == "joint") {
      if (this.customerInfo && this.coCustomerInfo) {
        return {
          customerInfo: validations.customerInfo,
          coCustomerInfo: validations.coCustomerInfo,
        };
      }
    }

    return {};
  },
};
</script>

<style>
.has-error {
  border: 2px solid red !important;
}
.monevoPopupHeader {
  padding-left: 15px;
  margin-top: -10px;
}
.textLabel {
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666;
}
@media screen and (max-height: 730px) {
  .smp-mediaScreen {
    max-height: 600px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 620px) {
  .smp-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 420px) {
  .smp-mediaScreen {
    max-height: 250px !important;
    overflow-y: scroll;
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
.monevoDisclaimerLabel {
  font-size: 0.72em;
  color: #999;
}
.monevoDisclaimerLabel p {
  margin: 5px 0;
  font-size: 0.72em;
  color: #999;
}
</style>
