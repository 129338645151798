<template>
    <form @submit.prevent="save">
        <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-md-8 col-sm-12 form-label" for="dataTemplateInput"> Setting Name
                *</label>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <input id="mapping-name" autocomplete="off" v-model.trim="mappingName" class="form-control"
                    :class="{ 'mapping__field--error': v$.mappingName.$error }" type="text"
                    @blur="v$.mappingName.$touch" />
            </div>

        </div>

        <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-md-8 col-sm-12 form-label" for="dataTemplateInput">Data Template
                *</label>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <select v-model="selectedSchemaId" id="dataTemplateInput-em" class="form-control"
                    :class="{ 'mapping__field--error': v$.selectedSchemaId.$error }" @blur="v$.selectedSchemaId.$touch">
                    <option v-for="item in schemas" :key="item.Id" :value="item.Id">
                        {{ item.Name }}
                    </option>
                </select>
            </div>
        </div>

        <div>
            <h2 class="mapping__title">Fields Mapping</h2>
            <o-field v-if="selectedSchemaId">
                <o-upload :model-value="files" @update:model-value="handleUpdateFiles" drag-drop multiple accept=".csv"
                    aria-labelledby="upload-label">
                    <div class="text-center" style="
                margin: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                ">
                        <i class="fas fa-cloud-upload-alt folderIcon"> </i>
                        <div id="upload-label" class="mt-1">
                            <p>
                                Drop your files here or click to upload. Select multiple at a time.
                            </p>
                        </div>
                    </div>
                </o-upload>
            </o-field>
            <div v-else class="mapping__empty-schema">
                Select the <em>Data Template</em> you want to use...
            </div>

            <div class="mapping__files" v-if="files.length > 0">
                <ul>
                    <li v-for="(file, index) in files" :key="index" class="file-list-item">
                        <span>{{ file.name }}</span>
                        <button type="button" @click.prevent="removeFile(index)" class="btn btn-danger ml-2">
                            Delete
                        </button>
                    </li>
                </ul>
                <div>
                    <button class="btn btn-primary mapping__upload-button" type="button" @click="uploadFiles">
                        Upload
                    </button>
                </div>
            </div>
        </div>
        <!-- Table -->
        <div v-if="schemaFields.length" class="noMargin" :class="{ 'mapping__field-error': v$.fieldMapping.$error }">
            <FieldMappingGrid v-model="fieldMapping" :sourceFields="sourceFields" :targetFields="schemaFields" />
        </div>
        <div class="mapping__create-button-wrapper">
            <button class="btn btn-success" type="submit" :disabled="v$.$invalid">
                {{ currentMapping ? "Save" : "Create" }}
            </button>
        </div>
    </form>
</template>

<script>
import FieldMappingGrid from './FieldMappingGrid.vue';
import types from './types';
import { useVuelidate } from "@vuelidate/core"
import { required, minLength, helpers } from '@vuelidate/validators';
import { mapState } from "vuex";
import utilitiesMixin from '@/mixins/utilitiesMixin';

export default {
    name: "UploadDataSettingsForm",
    components: { FieldMappingGrid },
    mixins: [utilitiesMixin],
    props: {
        currentMapping: {
            type: Object,
            required: false
        }
    },
    emits: ['save'],
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            mappingName: this.currentMapping ? this.currentMapping.SettingName : null,
            selectedSchemaId: this.currentMapping ? this.currentMapping.DataUploadTemplateId : null,
            fieldMapping: this.currentMapping ? this.flattenMappings(this.currentMapping.DataUploadSettingDetailList) : [],
            files: [],
            externalCsvFieldNames: []
        }
    },
    computed: {
        ...mapState({
            schemas: (state) => state.uploadDataSettings.schemas,
            schemaFields: (state) => state.uploadDataSettings.selectedSchemaDetails?.sort((a, b) => a.ColumnName.localeCompare(b.ColumnName))
        }),
        sourceFields() {
            if (this.currentMapping) {
                return this.externalCsvFieldNames.concat(this.currentMapping.DataUploadSettingDetailList.map(file => {
                    return {
                        Name: file.FileName,
                        Columns: file.Columns?.map(col => col.HeaderName)
                    }
                }))
            }

            return this.externalCsvFieldNames
        },
        usedTargetValues() {
            return this.fieldMapping.reduce((usedValues, currentField) => {
                if (currentField.DataUploadTemplateDetailId) {
                    usedValues.add(currentField.DataUploadTemplateDetailId)
                }
                return usedValues
            }, new Set([]))
        },

    },
    validations() {
        return {
            mappingName: { required },
            selectedSchemaId: { required },
            fieldMapping: {
                required,
                minLength: minLength(1),
                $each: helpers.forEach({
                    FileName: {
                        required
                    },
                    HeaderName: {
                        required
                    },
                    DataUploadTemplateDetailId: {
                        required
                    }
                })
            }
        }
    },
    watch: {
        selectedSchemaId: {
            async handler(newSchemaId) {
                const [err, result] = await this.$store.dispatch(types.GET_DATA_UPLOAD_TEMPLATE_DETAIL_BY_ID, newSchemaId)
                if (err) {
                    const status = err?.response?.status;

                    if (status == 401) {
                        this.$swal(
                            "Warning",
                            "You are not authorized to see data upload settings",
                            "warning"
                        );
                    }
                }
            },
            immediate: true
        },
        externalCsvFieldNames: {
            handler() {
                if (this.selectedSchemaId) {
                    this.autoMapFields()
                }
            },
        }
    },
    async mounted() {
        let err, result;
        [err, result] = await this.$store.dispatch(
            types.GET_DATA_TEMPLATE
        );

        if (err) {
            const status = err?.response?.status;

            if (status == 401) {
                this.$swal(
                    "Warning",
                    "You are not authorized to see this settings",
                    "warning"
                );
            }
            this.isLoading = false;
            return;
        }
    },
    methods: {
        async save() {
            const payload = {
                SettingName: this.mappingName,
                DataUploadTemplateId: this.selectedSchemaId,
                IsActive: true,
                Mappings: this.checkMapping(this.fieldMapping)
            }

            this.$emit('save', payload)
        },
        flattenMappings(fieldMappings) {
            return fieldMappings?.flatMap(file => file.Columns ? file.Columns?.map(col => {
                return {
                    DataUploadTemplateDetailId: col.DataUploadTemplateDetailId,
                    FileName: file.FileName,
                    IsMandatory: col.IsMandatory,
                    DefaultValue: col.DefaultValue,
                    HeaderName: col.HeaderName,
                }
            }) : file)
        },
        checkMapping(fieldMappings) {
            return fieldMappings
                .filter((field) => field.DataUploadTemplateDetailId !== null &&
                    field.DataUploadTemplateDetailId !== "null" && field.FileName !== null && field.HeaderName !== null)
        },
        autoMapFields() {
            const usedTargetFields = this.usedTargetValues

            this.sourceFields.forEach(file => file.Columns?.forEach(field => {
                const autoMappedValue = this.schemaFields?.find(target => target.ColumnName.toLowerCase() === field.toLowerCase() && !usedTargetFields.has(target.Id))?.Id ?? null

                if (autoMappedValue) {
                    usedTargetFields.add(autoMappedValue)
                    this.fieldMapping.push({
                        FileName: file.Name,
                        HeaderName: field,
                        DataUploadTemplateDetailId: autoMappedValue,
                        DefaultValue: null,
                        IsMandatory: false,
                        automapped: true
                    })
                }
            })
            )
        },
        handleUpdateFiles(value) {
            if (value.some(file => file.size === 0)) {
                this.$swal("Warning!", "One or more files are empty", "warning")
                // TODO: remove invalid files?
            }
        },
        removeFile(index) {
            this.files = this.files.filter((_, i) => i !== index)
            const [deletedFile] = this.externalCsvFieldNames.splice(index, 1)

            if (deletedFile) {
                this.fieldMapping = this.fieldMapping.filter(field => field.FileName !== deletedFile.Name)
            }
        },
        async uploadFiles() {
            // Validate mappingName
            if (!this.mappingName) {
                this.v$.mappingName.$touch();
            }

            if (this.files.some(file => file.type !== "text/csv")) {
                this.$swal("Warning!", "All files must be CSV")
                return
            }

            if (this.files.some(file => file.size > 50000000)) {
                this.$swal("Warning!", "One or more files are larger than 50MB", "warning")
                return
            }

            if (this.files.some(file => file.size === 0)) {
                this.$swal("Warning!", "One or more files are empty", "warning")
                return
            }

            if (this.files.length > 0) {
                this.isLoading = true;
                try {
                    const toBase64 = (file) =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = (error) => reject(error);
                        });

                    const createDto = async (files) => {
                        const base64Files = await Promise.all(files.map(toBase64));
                        return files.map((file, index) => ({
                            Name: file.name.slice(0, file.name.lastIndexOf(".")),
                            DataFile: base64Files[index],
                        }))
                    };

                    const dto = await createDto(this.files);

                    let [err, result] = await this.$store.dispatch(types.CSV_CONFIGURATION, dto);
                    if (result) {
                        this.externalCsvFieldNames = result.Data
                    } else {
                        let errMsg = this.getApiErrorMessage(err);
                        this.$swal("Error!", errMsg, "error");
                    }
                } catch (e) {
                    console.log(e)
                    this.$swal("Error", "An unexpected error occurred!", "error");
                } finally {
                    this.isLoading = false;
                }
            } else {
                this.$swal("Warning", "No files to upload.", "warning");
            }
        },
    }
}
</script>

<style scoped>
.o-upl {
    position: relative;
    display: inline-flex;
    width: 100%;
}


.mapping__field--error {
    border-color: red;
}

.mapping__errors {
    font-size: 0.8rem;
    color: red;
}

.mapping__name {
    padding-bottom: 1%;
}

.mapping__title {
    padding-top: 2%;
    padding-bottom: 2%;
}

.mapping__upload-button {
    margin-top: 1rem;
    display: block;
    margin-left: auto;
}

.mapping__files {
    padding: 2rem 0;
}

.mapping__create-button-wrapper {
    display: flex;
    padding: 2rem 0;
    justify-content: flex-end;
}

.mapping__empty-schema {
    padding: 3rem;
    margin: 1rem 0;
    text-align: center;
    border: dashed 1px #b5b5b5;
}
</style>
