import api from "@/services/admin/api";
import to from "@/helpers/to";
import types from "./types"
export default {
  state: {
    uploadedFile: {},
    fileUploadSettings: [],
    mappings: [],
    schemas: [],
    memoizedDetails: {},
    selectedSchemaDetails: null,
    dataMigrationReportList: [],
    settingsMigrationReportList:[],
    dataTemplateList:[]
  },
  getters: {
    getSchemaIndexById: (state) => (id) => {
      return state.schemas.findIndex(schema => schema.Id === id)
    },
  },
  actions: {
    // File Upload Page
    PROCESS_CSV: async ({ commit }, payload) => {
      const [err, result] = await to(api.uploadCSVFile(payload));
      if (err) {
        let parsedResponse = null;

        try {
          parsedResponse = JSON.parse(err?.request?.response);
        } catch (parseError) {
          console.error("Failed to parse error response:", parseError);
        }

        return {
          status: err.request.status,
          response: parsedResponse,
        };
      }
      if (result) {
        commit(types.MUTATIONS.SET_FILENAME, result.Data);
      }
    },
    TRIGGER_PIPELINE: async ({ commit }, payload) => {
      const [err, result] = await to(api.triggerPipeline(payload));
      return [err, result];
    },
    GET_FILE_UPLOAD_SETTINGS: async ({ commit }) => {
      const [err, result] = await to(api.getFileUploadSettings());
      if (result) {
        commit(types.SET_FILE_UPLOAD_SETTINGS, result.Data);
      }
    },
    GET_DATA_TEMPLATE: async ({ commit, state },) => {
      if (state.schemas.length > 0) {
        return [null, state.schemas]
      }
      const [err, result] = await to(api.getDataTemplate());
      if (!err) {
        commit(types.MUTATIONS.SET_SCHEMAS, result.Data)
      }
      return [err, result];
    },
    GET_DATA_UPLOAD_SETTING_BY_ID: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getDataUploadSettingById());
      return [err, result];
    },
    GET_DATA_UPLOAD_TEMPLATE_DETAIL_BY_ID: async ({ commit, state }, schemaId) => {
      if(!schemaId) {
        commit(types.MUTATIONS.SET_SELECTED_SCHEMA_DETAILS, [])
        return [null, []]
      }

      if (!state.memoizedDetails[schemaId]) {
        const [err, result] = await to(api.getDataUploadTemplateDetailById(schemaId));
        if (!err && result) {
          commit(types.MUTATIONS.ADD_MEMOIZED_SCHEMA_DETAILS, { schemaId, details: result.Data })
          commit(types.MUTATIONS.SET_SELECTED_SCHEMA_DETAILS, result.Data)
        }
        return [err, result];
      } else {
        commit(types.MUTATIONS.SET_SELECTED_SCHEMA_DETAILS, state.memoizedDetails[schemaId])
        return [null, state.memoizedDetails[schemaId]]
      }
    },
    GET_ALL_SETTINGS: async ({ commit },) => {
      let err, result;
      [err, result] = await to(api.getAllSettings());
      if (!err) {
        commit(types.MUTATIONS.SET_SETTINGS, result.Data)
      }
      return [err, result]
    },
    GET_ALL_SETTINGS_WITHOUT_DETAILS: async ({ commit },) => {
      let err, result;
      [err, result] = await to(api.getAllSettingsWithoutDetails());
      return [err, result]
    },
    GET_DATA_UPLOAD: async ({commit}, payload) => {
      let err, result;
      [err, result] = await to(api.getDataUploadReport(payload));

      if (result) {
        commit(types.SET_DATA_MIGRATION_REPORT, result.Data);
      }

      if (err) {
        const { response } = err;

        if ((response?.status === 404 && response?.data?.Message === 'No record found.') || (response?.status === 500 && response?.data.Message==="An error occured!")) {
          commit(types.SET_DATA_MIGRATION_REPORT, []);
        }
      }
      return [err, result];
    },
    GET_SETTINGS_MIGRATION_REPORT: async ({commit})=>{
      let err, result;
      [err, result] = await to(api.getSettingsMigrationReport());
      if (result) {
        commit(types.SET_SETTINGS_MIGRATION_REPORT, result.Data);
      }
    },
    GET_DATA_TEMPLATE_LIST: async ({commit})=>{
      let err, result;
      [err, result] = await to(api.getDataTemplate());
      if (result) {
        commit(types.SET_DATA_TEMPLATE_LIST, result.Data);
      }
    },
    GET_MIGRATION_REPORT_DETAIL: async (_, payload)=>{
      let err, result;
      [err, result] = await to(api.getMigrationRecordReportDetail(payload));
      return [err, result];
    },
    ADD_DATA_UPLOAD_SETTING: async ({ commit }, data) => {
      const [addErr, addResult] = await to(api.addDataUploadSetting(data));
      if (!addErr && addResult.Data) {
        const id = addResult.Data.Id
        const [err, result] = await to(api.getDataUploadSettingById({ Id: id }))

        if (!err && result.Data) {
          commit(types.MUTATIONS.ADD_SETTING, result.Data)
        }
      }
      return [addErr, addResult]
    },
    CSV_CONFIGURATION: async ({ commit }, data) => {
      let err, result;
      [err, result] = await to(api.csvConfiguration(data));
      return [err, result]
    },      
  },

  mutations: {
    [types.MUTATIONS.SET_FILENAME]: (state, payload) => {
      state.uploadedFile = payload;
    },
    [types.MUTATIONS.SET_SETTINGS]: (state, payload) => {
      state.mappings = payload
    },
    SET_FILE_UPLOAD_SETTINGS: (state, payload) => {
      payload.forEach(setting => {
        setting.DataUploadSettingDetailList = setting.DataUploadSettingDetailList.map(fileInfo => ({ ...fileInfo, file: null }))
      });
      state.fileUploadSettings = payload;
    },
    ADD_FILE_TO_UPLOAD: (state, { settingId, fileName, file }) => {
      const currentSettingIndex = state.fileUploadSettings.findIndex(setting => setting.Id === settingId);
      if (currentSettingIndex >= 0) {
        const fileIndex = state.fileUploadSettings[currentSettingIndex].DataUploadSettingDetailList.findIndex(file => file.FileName === fileName);
        if (fileIndex >= 0) {
          state.fileUploadSettings[currentSettingIndex].DataUploadSettingDetailList[fileIndex].file = file;
        }
      }
    },
    [types.SET_DATA_MIGRATION_REPORT]:(state, payload) => {
      state.dataMigrationReportList = payload;
    },
    [types.SET_SETTINGS_MIGRATION_REPORT]:(state, payload) => {
      state.settingsMigrationReportList = payload;
    },
    [types.SET_DATA_TEMPLATE_LIST]:(state, payload) => {
      state.dataTemplateList = payload;
    },
    [types.MUTATIONS.ADD_SETTING]: (state, payload) => {
      state.mappings = [...state.mappings, payload]
    },
    [types.MUTATIONS.SET_SCHEMAS]: (state, payload) => {
      state.schemas = payload
    },
    [types.MUTATIONS.SET_SELECTED_SCHEMA_DETAILS]: (state, payload) => {
      state.selectedSchemaDetails = payload
    },
    [types.MUTATIONS.ADD_MEMOIZED_SCHEMA_DETAILS]: (state, { schemaId, details }) => {
      state.memoizedDetails[schemaId] = details
    }
  }
}