<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'DosEquitiesReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredDosEquitiesReport"
        :paginated="isPaginated && filteredDosEquitiesReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column field="ClientId" label="Client ID" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClientIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(
                  props.row.ClientId,
                  props.row.FirstName,
                  props.row.LastName
                )
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.ClientId &&
                    props.row.FirstName &&
                    props.row.LastName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.ClientId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.ClientId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FirstName"
          label="First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.FirstName }}
          </template>
        </o-table-column>

        <o-table-column field="LastName" label="Last Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.LastName }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Notes"
          position="centered"
          v-slot="props"
        >
          <div>
            <button
              class="btn waves-effect waves-light ml-3"
              style="background-color: #f7b84b"
              @click="openNotes(props.row.ClientId, props.row.CreditorId)"
              v-if="!props.row.IsSummary"
            >
              <i class="ri-file-edit-line"></i>
            </button>
          </div>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.ClientId,
                props.row.FirstName,
                props.row.LastName
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredDosEquitiesReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "DosEquitiesReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      dosEquitiesReportInitSummary: {
        ClientId: "",
        FirstName: "",
        LastName: "",
        EnrolledDate: "",
      },
      ClientIdFilter: "",
      FirstNameFilter: "",
      LastNameFilter: "",
      EnrolledDateFilter: "",
    };
  },
  computed: mapState({
    dosEquitiesList: (state) => state.reports.dosEquitiesList,
    filteredDosEquitiesReport() {
      let rtn = [];

      let tmpList =
        this.dosEquitiesList.length > 0
          ? this.dosEquitiesList.filter((item) => {
              return (
                this.filterString(item.ClientId, this.ClientIdFilter) &&
                this.filterString(item.FirstName, this.FirstNameFilter) &&
                this.filterString(item.LastName, this.LastNameFilter) &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.EnrolledDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.dosEquitiesReportInitSummary.ClientId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.dosEquitiesReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.dosEquitiesReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      userInfo != null &&
      userInfo.customerIdPrefix != null &&
      userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    openNotes(customerId, creditorId) {
      this.$Emitter.emit("viewNotePanel", {
        CustomerId: customerId,
        CreditorId: creditorId,
      });
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_DOS_EQUITIES_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, firstName, lastName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: firstName + " " + lastName,
      });
    },
  },
};
</script>

<style scoped></style>
