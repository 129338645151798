<template>
  <div class="row admin-page officePage">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 pt-2">
      <button
        class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
        @click="handleAddTemplate()"
      >
        Add New
      </button>

      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="reasonsFilter"
            placeholder="Filter Reasons"
          />
          <div class="col-md-12 mt-2 noPadding">
            <input
              autocomplete="off"
              id="txtShowInactive"
              type="checkbox"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
                vertical-align: text-top;
              "
              v-model="showInactiveItems"
            />

            <label class="generalLabel" style="font-weight: normal"
              >Show Inactive</label
            >
          </div>
        </div>
        <div class="list col-md-12">
          <div
            class="office item row"
            v-for="(item, index) in filteredReasons"
            :key="item.Id"
            :class="{ active: activeItem === item.Id }"
            :disabled="saving"
            @click="() => handleListSelection(item, index)"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-mail-close-fill"></i>
              <span style="margin-left: 8px">{{ item.Reason }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent" style="padding-top: 0">
        <div class="mt-2" style="display: flex; align-items: baseline">
          <div class="col-md-2">
            <label for="ccr-Reasons" class="form-label">Reasons:*</label>
          </div>
          <div class="col-md-8">
            <input
              type="text"
              autocomplete="off"
              class="form-control"
              v-model="selectedRow.Reason"
              id="ccr-Reasons"
              placeholder="Reasons"
              v-on:input="checkLength"
            />
            <p
              class="noPadding noMargin"
              style="color: #ff7979"
              v-if="isMaxLimitReached"
            >
              Maximum 50 characters are allowed!
            </p>
          </div>
        </div>
        <div class="mt-2" style="display: flex; align-items: baseline">
          <div class="col-md-2">
            <label for="ccr-Active" class="form-label">Active:</label>
          </div>
          <div class="col-md-8">
            <input
              autocomplete="none"
              type="checkbox"
              id="ccr-Active"
              :disabled="selectedRow.Id == 0"
              v-model="selectedRow.IsActive"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />
          </div>
        </div>
        <div v-if="checkAuth(52365)" class="col-md-12 noPadding row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <input
              type="checkbox"
              id="cbCommentRequired"
              class="checkbox-input form-control"
              v-model="selectedRow.IsKeepGlobalAccount"
            />
            <label class="form-label"
              >Canceling a customer with this reason will keep the Global
              account open with optional products.</label
            >
          </div>
        </div>
        <div
          class="mt-5 col-md-10"
          style="display: flex; justify-content: flex-end"
        >
          <button
            class="btn btn-success col-md-2"
            @click="handleSave()"
            :disabled="saving || selectedRow.Reason == ''"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin.js";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";

let init = {
  Id: 0,
  CompanyId: 0,
  IsActive: true,
  Reason: "",
  IsKeepGlobalAccount: false,
};

export default {
  name: "CustomerCancelationReasonsManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: {},
  data() {
    return {
      showInactiveItems: false,
      oldValue: JSON.stringify(init),
      selectedRow: deepCopy(init),
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
      activeItem: null,
      isEditing: false,
      reasonsFilter: "",
      saving: false,
      isMaxLimitReached: false,
    };
  },
  methods: {
    checkLength() {
      if (this.selectedRow.Reason.length > 50) {
        this.selectedRow.Reason = this.selectedRow.Reason.slice(0, 50);
        this.isMaxLimitReached = true;
      } else {
        this.isMaxLimitReached = false;
      }
    },
    async handleAddTemplate() {
      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.customerCancelationList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.customerCancelationList[indx] = this.valueToRevert;
        this.customerCancelationList.push();
        this.isCancelledBeforeSave = false;
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }
    },
    async handleListSelection(row) {
      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;
        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }
      this.isEditing = true;

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;
        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleSave() {
      if (this.selectedRow.Reason == "") {
        this.$swal("Warning!", "Reason field is mandatory!", "warning");
      }

      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }

      this.saving = true;

      let err, result, type;
      let isReaonsExists =
        this.customerCancelationList.filter(
          (x) => x.Reason.toLowerCase() == this.selectedRow.Reason.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id != 0) {
        type = types.UPDATE_CUSTOMER_CANCELATION_REASON;
      } else {
        type = types.ADD_CUSTOMER_CANCELATION_REASON;
      }
      if (isReaonsExists && type == types.ADD_CUSTOMER_CANCELATION_REASON) {
        this.saving = false;
        this.$swal(
          "Warning!",
          "This Reasons is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }
      if (type == types.UPDATE_CUSTOMER_CANCELATION_REASON) {
        [err, result] = await this.$store.dispatch(type, {
          ...this.selectedRow,
        });
      } else {
        [err, result] = await this.$store.dispatch(type, {
          Reason: this.selectedRow.Reason,
          IsActive: this.selectedRow.IsActive,
          IsKeepGlobalAccount: this.selectedRow.IsKeepGlobalAccount,
        });
      }

      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.ADD_CUSTOMER_CANCELATION_REASON) {
          this.selectedRow.Id = result.Data.Id;

          this.customerCancelationList.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = result.Data.Id;
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    reset() {
      this.activeItem = null;
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
    },
  },
  computed: mapState({
    customerCancelationList: (state) =>
      state.customerCancelationReasonsManagement.customerCancelationList,

    filteredReasons() {
      // if (this.showInactiveItems == null) {
      //   return this.customerCancelationList;
      // } else {
      return this.customerCancelationList.filter((item) => {
        let reasonMatches =
          item.Reason.toLowerCase().indexOf(this.reasonsFilter.toLowerCase()) >
          -1;
        let isActiveMatches = this.showInactiveItems || item.IsActive;
        return reasonMatches && isActiveMatches;
      });
    },
    // },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_CUSTOMER_CANCELATION_REASON, null),
    ]);
  },
};
</script>

<style></style>
