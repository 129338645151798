<template>
  <div
    v-if="visibleModal"
    class="d-block position-absolute"
  >
    <div
      class="modal fade show d-flex align-items-center justify-content-center"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="d-flex justify-content-between align-items-center px-2">
            <h5>{{ fileName }}</h5>
            <div
              class="upload-file-modal__close-button"
              @click="hideModal"
            >
              <i class="fas fa-window-close" />
            </div>
          </div>
          <div class="modal-body pt-0">
            <slot />
            <div class="d-flex align-items-center">
              <o-field class="col-md-12 mt-2">
                <o-upload
                  v-model="fileToUpload"
                  drag-drop
                  type="file"
                  accept=".csv"
                  hovered-class="upload-file-modal__upload-section--dragging"
                  @input="handleFileChange"
                >
                  <section class="text-center d-flex align-items-center flex-column p-5">
                    <i class="fas fa-cloud-upload-alt folderIcon" />
                    <span class="mt-1">
                      Drop your files here or click to upload.
                    </span>
                  </section>
                </o-upload>
              </o-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" />
  </div>
</template>
<script>

export default {
  name: "UploadFileModal",
  props: {
    fileName: {
      type: String,
      required: true
    }
  },
  emits: ["fileUploaded"],
  data: () => ({
    visibleModal: false,
    fileToUpload: null
  }),
  watch: {
    fileToUpload(newVal, oldVal) {
      if (oldVal != newVal && newVal != null) {
        this.triggerFileUploadedEvent(newVal);
      }
    },
  },
  methods: {
    showModal() {
      this.visibleModal = true;
    },
    hideModal() {
      this.visibleModal = false;
    },
    resetValue() {
      this.fileToUpload = null;
    },
    handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        this.triggerFileUploadedEvent(file);
      }
    },
    triggerFileUploadedEvent(file) {
      this.$emit("fileUploaded", file)
    }
  }
}
</script>
<style lang="css" scoped>
.upload-file-modal__close-button {
  font-size: 30px;
}

.upload-file-modal__close-button:hover {
  color: #ff3860;
  cursor: pointer;
}

.upload-file-modal__upload-section--dragging {
  background-color: rgba(0, 0, 0, .1);
}
</style>