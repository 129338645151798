<template>
  <div class="row admin-page">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        v-if="this.checkAuth(6581)"
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddRole"
      >
        Add New Role
      </button>
      <div class="filter form-group row">
        <div class="col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="roleFilter"
            placeholder="Filter by role"
          />
        </div>
      </div>
      <div class="list col-md-12">
        <div
          class="role item row"
          v-for="(role, index) in filteredRoles"
          :key="role.Id"
          :class="{ active: activeRole === role.Id }"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
          :disabled="saving || deleting"
          @click="() => handleRoleSelection(role, index)"
        >
          <span :class="{ adminActiveMenuItem: activeRole === role.Id }">
            <i class="fas fa-key"></i>
            <span style="margin-left: 8px"> {{ role.Name }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12 noMargin">
      <form
        v-if="isEditing"
        class="form-horizontal col-md-12 float-right panelWrapper"
        role="form"
      >
        <div class="panelContent">
          <div class="form-group row">
            <label
              class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-form-label"
              for="nameinput"
              >Role Name</label
            >
            <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12">
              <input
                autocomplete="off"
                type="text"
                id="nameInput-rm"
                class="form-control"
                v-model="selectedRow.Name"
                placeholder="Enter role name"
                :disabled="!this.checkAuth(6581)"
                :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Name.$touch"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-form-label"
              >Offices</label
            >
            <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12">
              <select
                v-model="selectedRow.OfficeId"
                :disabled="!hasAccessOtherOffices || !this.checkAuth(6581)"
                class="form-control"
              >
                <option :value="null">All Offices</option>
                <option
                  :value="item.Id"
                  v-for="item in sortedOffices"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row" v-if="this.checkAuth(6581)">
            <label class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-form-label"
              >Copy Settings From</label
            >
            <div class="col-xl-7 col-lg-6 col-md-5 col-sm-6">
              <select
                v-model="copySettings"
                id="copySettings"
                class="form-control"
              >
                <option value="">Please Select One</option>
                <option
                  :value="item"
                  v-for="item in sortedRoles"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3">
              <button
                type="button"
                @click="handleCopySettings"
                class="btn btn-info btn-bordered-info waves-effect width-sm waves-light float-right form-control"
              >
                Apply
              </button>
            </div>
          </div>

          <div class="=form-group row">
            <label
              class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-form-label"
              for="nameinput"
              >Filter by Permisson:</label
            >
            <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                v-model="permissionFilter"
                placeholder="Please type a permisson"
              />
            </div>
          </div>

          <perfect-scrollbar
            class="roles-management-scroll-list form-group roles-list row pt-2"
          >
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 noPadding">
              <div
                class="col-md-12 mb-2"
                v-for="module in sortedFilteredPermissions"
                :key="module.Id"
                style="cursor: pointer"
              >
                <div
                  v-show="showModule(module)"
                  class="badge badge-primary moduleNameHeader"
                  style="display: flex; justify-content: space-between"
                  @click="openAndClose(module.Id)"
                >
                  <div>
                    <label
                      style="
                        font-size: 16px;
                        color: white;
                        margin-left: 10px;
                        padding-top: 7px;
                        cursor: pointer;
                      "
                      >{{ module.Name }}
                    </label>
                  </div>
                  <div>
                    <i
                      :class="
                        checkModuleNav(module.Id)
                          ? 'fas fa-angle-up'
                          : 'fas fa-angle-down'
                      "
                      style="
                        font-size: 17px;
                        margin-right: 20px;
                        padding-top: 6px;
                      "
                    ></i>
                  </div>
                </div>

                <div
                  v-show="checkModuleNav(module.Id)"
                  class="row noMargin"
                  style="background-color: #3bb0da23"
                  v-for="mfunc in module.ModuleFunctions"
                  :key="mfunc.Id"
                >
                  <div class="roleChekbox">
                    <input
                      type="checkbox"
                      :id="'chk' + mfunc.Id"
                      class="form-control"
                      style="width: 80%"
                      v-model="formData[mfunc.Id]"
                      :disabled="
                        !this.checkAuth(6581) ||
                        disableIfCantElevate(mfunc.IsElevated)
                      "
                    />
                  </div>
                  <div class="roleLabel flex-fill">
                    <label class="col-form-label">{{ mfunc.Name }}</label>
                  </div>
                  <span  v-if="mfunc.Description" class="authDescriptionIcon" @click="showDescription(mfunc.Description)">
                    <i class="fas fa-info-circle"></i>
                    <span class="arrow-right"></span>
                    <span class="authDescription">{{ mfunc.Description }}</span>
                   
                </span>
                </div>
              </div>
            </div>
          </perfect-scrollbar>

          <div class="form-group row">
            <div class="col-md-12" v-if="this.checkAuth(6581)">
              <button
                type="button"
                @click="handleSave"
                :disabled="v$.$invalid || saving"
                class="btn btn-success btn-bordered-success waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="saving" class="fas fa-spinner mr-1"></i>
                Save
              </button>
              <button
                type="button"
                v-if="selectedRow.Id"
                :disabled="!this.checkAuth(6581)"
                @click="() => handleDelete(selectedRow.id)"
                class="btn btn-danger btn-bordered-danger waves-effect width-md waves-light submit-button float-right"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import _orderBy from "lodash";
import { PERMISSIONS } from "@/constants/Permissions";

const init = {
  Id: 0,
  Name: "",
  ModuleFunctions: [{ Id: 0, Name: "" }],
  OfficeId: null,
};

const permissionList = {
  deleteOffice: false,
  addOffice: false,
  editOffice: false,
  addEmployee: false,
  editEmployee: false,
  deleteEmployee: false,
};

const DEFAULT_ACTIVE_PERMISSIONS = [
  PERMISSIONS.CAN_SEE_CAMPAIGN_INFORMATION
];

export default {
  name: "RolesManagement",
  data() {
    return {
      hasAccessOtherOffices: false,
      moduleNavs: [],
      moduleFunctionsItem: true,
      v$: useValidate(),
      selectedRow: deepCopy(init),
      isEditing: false,
      saving: false,
      copySettings: "",
      roleFilter: "",
      permissionFilter: "",
      activeRole: null,
      oldValue: "",
      deleting: false,
      formData: {},
      isCancelledBeforeSave: false,
      valueToRevert: deepCopy(init),
      userOfficeId: null,
      isElevated: false,
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: mapState({
    officeList: (state) => state.globals.offices,
    sortedOffices() {
      return _orderby.orderBy(
        this.officeList.filter((x) =>
          this.hasAccessOtherOffices ? x : x.Id == this.userOfficeId
        )
      );
    },
    roles: (state) => state.rolesManagement.list,
    sortedRoles() {
      return _orderBy.orderBy(this.roles, "Name", "asc");
    },
    modules: (state) => state.rolesManagement.modules,
    roleById: (state) => state.rolesManagement.roleById,

    filteredPermissions() {
      let filter = this.permissionFilter.toLowerCase();
      return this.modules.map((module) =>
        Object.assign({}, module, {
          ModuleFunctions: module.ModuleFunctions.filter((mf) => {
            return mf.Name.toLowerCase().includes(filter);
          }),
        })
      );
    },
    sortedFilteredPermissions() {
      return _orderBy.orderBy(this.filteredPermissions, "Name", "asc");
    },

    filteredRoles() {
      return _orderBy.orderBy(
        this.roles.filter(
          (item) =>
            item.Name.toLowerCase().indexOf(this.roleFilter.toLowerCase()) > -1
        ),
        "Name",
        "asc"
      );
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_ALL_ROLES).catch((err) => {}),
      this.$store.dispatch(types.GET_MODULES).catch((err) => {}),
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
    ]);
    for (let item of this.modules) {
      this.moduleNavs.push({ ModuleId: item.Id, IsOpen: true });
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null) {
      this.isElevated = userInfo.isElevated;
      if (userInfo.moduleFunctions != null)
        this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();
  },

  methods: {
    setDefaultSelectedValues() {
      DEFAULT_ACTIVE_PERMISSIONS.forEach(defaultPermission => {
        this.formData[defaultPermission] = true;
      });
    },
    showDescription(desc){

      if(desc != "")
      {
        this.$swal("",desc,"info");
      }

    },
    disableIfCantElevate(isfunctionRequiresElevation) {
      let rtn = false;
      if (isfunctionRequiresElevation) {
        if (!this.isElevated) {
          rtn = true;
        }
      }
      return rtn;
    },
    openAndClose(moduleId) {
      let indx = this.moduleNavs.findIndex((x) => x.ModuleId == moduleId);
      if (indx > -1) {
        if (this.moduleNavs[indx].IsOpen) {
          this.moduleNavs[indx].IsOpen = false;
        } else {
          this.moduleNavs[indx].IsOpen = true;
        }
      }
    },
    showModule(moduleItem) {
      let rtn = true;
      if (this.permissionFilter != "" && moduleItem.ModuleFunctions) {
        if (moduleItem.ModuleFunctions.length > 0) {
          let mfList = moduleItem.ModuleFunctions.filter((mf) =>
            mf.Name.toLowerCase().includes(this.permissionFilter.toLowerCase())
          );
          rtn = mfList.length > 0;
        } else {
          rtn = false;
        }
      }
      return rtn;
    },
    checkModuleNav(moduleId) {
      let indx = this.moduleNavs.findIndex((x) => x.ModuleId == moduleId);
      let rtn = true;
      if (indx > -1) {
        rtn = this.moduleNavs[indx].IsOpen;
      }
      return rtn;
    },

    async handleAddRole() {
      let tempArr = [];
      for (let [key, value] of Object.entries(this.formData)) {
        if (value == true || value == "true") tempArr.push(+key);
      }
      let tempSelected = deepCopy(this.selectedRow);
      tempSelected.ModuleFunctions = tempArr.sort();
      let currentValue = JSON.stringify(tempSelected);
      if (this.oldValue != "" && this.oldValue != currentValue) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.reset();
        this.setDefaultSelectedValues();
        this.isEditing = true;
      }
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.roles.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.roles[indx] = this.valueToRevert;
        this.roles.push();

        this.reset();
        this.isEditing = true;
        this.setDefaultSelectedValues();

        this.selectedRow = deepCopy(init);
      }
    },
    async handleSave() {
      if (!this.checkAuth(6581)) {
        return;
      }
      let type = types.POST_ROLE;
      this.saving = true;
      this.permissionFilter = "";
      let tempArr = [];

      let isRoleExists =
        this.roles.filter(
          (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id > 0) {
        type = types.PUT_ROLE;
        isRoleExists =
          this.roles.filter(
            (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
          ).length > 1;
      }
      
      if (isRoleExists) {
        this.saving = false;
        this.$swal.fire(
          "Warning!",
          "This role name is already exists in role list!",
          "warning"
        );
        return;
      }

      for (let [key, value] of Object.entries(this.formData)) {
        if (value == true || value == "true") tempArr.push(+key);
      }

      //on insert - auto check MF: 111911 - Has access to CRM documentation.
      if (this.selectedRow.Id == 0) {
        if (!tempArr.includes(111911)) {
          tempArr.push(111911);
        }
      }

      this.selectedRow.ModuleFunctions = tempArr.sort();

      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        this.saving = false;
        return;
      }

      let err, result, payload;
      payload = {
        Id: this.selectedRow.Id,
        Name: this.selectedRow.Name,
        ModuleFunctions: this.selectedRow.ModuleFunctions,
        OfficeId: this.selectedRow.OfficeId,
      };

      [err, result] = await this.$store.dispatch(type, payload);

      if (result) {
        this.$swal("Success!", result.Message, "success");
        await this.$store.dispatch(globalTypes.GET_ROLES, {
          ForceRefresh: true,
        }),
          (this.selectedRow.Id = result.Data.Id);
        if (type == types.POST_ROLE) {
          this.roles.push(this.selectedRow);
        }

        if (this.selectedRow.ModuleFunctions.length > 0) {
          this.selectedRow.ModuleFunctions =
            this.selectedRow.ModuleFunctions.sort();
        }
        this.oldValue = JSON.stringify(this.selectedRow);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    async handleDelete(id) {
      this.deleting = true;
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (dialog) => {
        if (dialog.value) {
          let rowIndex = this.roles.indexOf(this.selectedRow);

          let err, result;
          [err, result] = await this.$store.dispatch(
            types.DELETE_ROLE,
            this.selectedRow.Id
          );
          if (!err) {
            this.reset();
          }

          if (result) {
            this.roles.splice(rowIndex, 1);
            this.reset();
            this.$swal("Deleted!", result.Message, "success");
            this.oldValue = "";
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      });

      this.deleting = false;
    },
    handleCancel() {
      this.isEditing = false;
    },
    async handleRoleSelection(row, index) {
      let tempArr = [];
      for (let [key, value] of Object.entries(this.formData)) {
        if (value == true || value == "true") tempArr.push(+key);
      }
      let tempSelected = deepCopy(this.selectedRow);
      tempSelected.ModuleFunctions = tempArr.sort();
      let currentValue = JSON.stringify(tempSelected);
      if (
        this.oldValue != "" &&
        this.oldValue != currentValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.reset();
        this.activeRole = row.Id;
        this.selectedRow = row;
        this.isEditing = true;
        if (this.selectedRow.ModuleFunctions.length > 0) {
          this.selectedRow.ModuleFunctions =
            this.selectedRow.ModuleFunctions.sort();
          this.selectedRow.ModuleFunctions =
            this.selectedRow.ModuleFunctions.filter(
              (v, i, a) => a.indexOf(v) === i
            );
        }

        this.oldValue = JSON.stringify(this.selectedRow);

        for (let i of row.ModuleFunctions) {
          this.formData[i] = true;
        }
        this.valueToRevert = deepCopy(this.selectedRow);
      }
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.roles.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.roles[indx] = this.valueToRevert;
        this.roles.push();

        this.reset();
        this.isEditing = true;
        this.activeRole = row.Id;
        this.selectedRow = row;
        if (this.selectedRow.ModuleFunctions.length > 0)
          this.selectedRow.ModuleFunctions =
            this.selectedRow.ModuleFunctions.sort();
        this.oldValue = JSON.stringify(this.selectedRow);

        for (let j of row.ModuleFunctions) {
          this.formData[j] = true;
        }
        this.valueToRevert = deepCopy(this.selectedRow);
      }
      this.isCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleCopySettings() {
      if (this.copySettings.length <= 0) return;
      this.formData = {};
      let temp = Object.assign({}, this.copySettings);

      for (let i of temp.ModuleFunctions) {
        this.formData[i] = "true";
      }
    },
    reset() {
      (this.isEditing = false), (this.activeRole = null);
      this.selectedRow = Object.assign({}, init);
      this.formData = {};
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
      },
    };
  },
};
</script>

<style>
.roles-management-scroll-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
}
.moduleNameHeader {
  padding-top: 10px;
  cursor: pointer !important;
}
.authDescriptionIcon{position: relative; cursor: pointer; padding: 8px 10px; color: #3bafda}
.authDescription{width:auto; min-width: 400px; max-width:400px; height: auto; max-height: 200px; padding:5px 10px;  display:none; position: absolute; top:3px; right:34px; background-color: #5f5f5f; border-radius: 5px; color:#fff; z-index: 999;}
.authDescriptionIcon:hover .authDescription{display: block;}
.arrow-right {
  width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #5f5f5f;
    right: 28px;
    top: 12px;
    position: absolute;
    display: none;
}
.authDescriptionIcon:hover .arrow-right {display: block;}
</style>
