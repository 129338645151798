<template>
  <div class="form-group row" id="reportsMigrationSummary">
    <div class="col-md-12">
      <form class="newPanelWrapper" role="form">
        <div class="row mt-2">
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName == 'MigrationDataSettingsReport'"
          >
            <label class="generalLabel" for="">{{ dateFilterText }}</label>
            <select
              :value="dateCombo"
              placeholder="Select Timeframe"
              class="generalSelectBox"
              style="width: 100% !important"
              @change="$emit('update:dateCombo', $event.target.value)"
            >
              <option v-for="item in dateOptions" :key="item.key" :value="item.key">
                {{ item.label }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="
              reportName === 'MigrationDataSettingsReport' &&
              (dateCombo === 'customDateRange' || dateCombo === 'specificDate')
            "
          >
            <label class="generalLabel">Start Date</label>
            <DatePicker mode="date" :locale="'en-US'" v-model="thirtyDayDate1">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Start Date"
                />
              </template>
            </DatePicker>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="
              reportName == 'MigrationDataSettingsReport' &&
              dateCombo === 'customDateRange'
            "
          >
            <label class="generalLabel">End Date</label>
            <DatePicker mode="date" :locale="'en-US'" v-model="thirtyDayDate2">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="End Date"
                />
              </template>
            </DatePicker>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12"
            v-if="reportName === 'MigrationDataSettingsReport'"
          >
            <label class="generalLabel">Process ID</label>
            <input
              :value="processId"
              type="text"
              v-myMask="{
                rightAlign: false,
              }"
              placeholder="Process ID"
              class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
              @input="filterProcessIdInput"
              @keydown.enter.prevent
            />
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12"
            v-if="reportName === 'MigrationDataSettingsReport'"
          >
            <label class="generalLabel">Template name</label>
            <select
              :value="templateId"
              class="generalSelectBox"
              style="width: 100% !important"
              @change="$emit('update:templateId', $event.target.value)"
            >
              <option :value="0">Please Select</option>
              <option v-for="item in settingDataTemplate" :key="item.Id" :value="item.Id">
                {{ item.TemplateName }}
              </option>
            </select>
          </div>

          <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12" style="margin-top: 28px">
            <div class="row" style="margin-top: -2px">
              <div class="col-md-3" v-if="!hideFilters">
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left col-ml-1"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  :disabled="loadingSpinner"
                  @click="$emit('filterReport')"
                >
                  <i
                    v-if="loadingSpinner"
                    class="spinner-border spinner-border-sm mr-1"
                  ></i>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="form-group row" v-if="exportData && exportData.length > 0">
      <div class="col-md-12">
        <div
          class="col-xl-2 col-lg-2 col-md-12 col-sm-12"
          v-if="
            reportName != 'InboundStatisticsReport' &&
            reportName != 'SnfPayoutReport' &&
            reportName != 'PurchasesClientInformationReport'
          "
        >
          <span class="badge badge-success" style="padding: 10px 16px">
            Total Records: {{ recordCount }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import { mapState } from "vuex";
import { DatePicker } from "v-calendar";

export default {
  name: "ReportMigrationSummary",
  components: { DatePicker },
  props: {
    exportData: {
      default() {
        return [];
      },
    },
    allData: {
      default() {
        return [];
      },
    },
    reportName: {
      type: String,
      default: () => "",
    },
    reportClass: {
      type: String,
      default: () => "",
    },
    recordCount: {
      type: Number,
      default: () => 0,
    },
    loadingSpinner: {
      Type: Boolean,
      Defult: false,
    },
    processId: {
      Type: String,
      default: () => "",
    },
    dateCombo: {
      Type: String,
      default: () => "today",
    },
    templateId: {
      Type: Number,
      default: 0,
    },
    startDate: {
      type: Date,
      required: false,
    },
    finalDate: {
      type: Date,
      required: false,
    },
  },
  emits: [
    "update:processId",
    "update:templateId",
    "update:startDate",
    "update:finalDate",
    "update:dateCombo",
    "filterReport",
  ],
  data() {
    return {
      reportType: "All",
      role: 0,
      config: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showClear: false,
        showClose: true,
        maxDate: new Date(),
      },
      dateFilterText: "Date Option",
      dateOptions: [
        {
          key: "today",
          label: "Today",
        },
        {
          key: "yesterday",
          label: "Yesterday",
        },
        {
          key: "thisWeek",
          label: "This Week",
        },
        {
          key: "thisMonth",
          label: "This Month",
        },
        {
          key: "lastMonth",
          label: "Last Month",
        },
        {
          key: "thisYear",
          label: "This Year",
        },
        {
          key: "specificDate",
          label: "Specific Date",
        },
        {
          key: "customDateRange",
          label: "Custom Date Range",
        },
      ],
      hideFilters: false,
      hideExport: false,
      uri: "data:application/vnd.ms-excel;charset=UTF-8;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
      viewType: "gridView",
    };
  },
  computed: {
    thirtyDayDate1: {
      get() {
        return this.startDate;
      },
      set(value) {
        this.$emit("update:startDate", value);
      },
    },
    thirtyDayDate2: {
      get() {
        return this.finalDate;
      },
      set(value) {
        this.$emit("update:finalDate", value);
      },
    },
    settingNames() {
      return this.settingsMigratioReportList.map((item) => {
        return {
          Id: item.Id,
          SettingName: item.SettingName,
        };
      });
    },
    settingDataTemplate() {
      return this.settingDataTemplateList.map((item) => {
        return {
          Id: item.Id,
          TemplateName: item.Name,
        };
      });
    },
    ...mapState({
      settingsMigratioReportList: (state) =>
        state.uploadDataSettings.settingsMigrationReportList,
      settingDataTemplateList: (state) => state.uploadDataSettings.dataTemplateList,
      roles: (state) => state.globals.roles,
    }),
  },
  methods: {
    filterProcessIdInput(event) {
      const rawInput = event.target.value;
      // Regular expression to allow only letters, numbers, and hyphens
      const validInput = rawInput.replace(/[^a-zA-Z0-9-]/g, "").slice(0, 36);
      event.target.value = validInput;
      this.$emit("update:processId", validInput);
    },
    tableToExcel(table, name) {
      let ctx = {
        worksheet: name || "Worksheet",
        table: table.innerHTML,
      };

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        this.uri + this.base64(this.format(this.template, ctx))
      );
      element.setAttribute("download", name + " _" + new Date().getTime() + ".xls");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      this.$emit("setPagination", true);
    },
    // SNF PAYOUT REPORT EXPORT METHODS
    convertToTable(data, title) {
      if (!data || Object.keys(data).length == 0) {
        return `<table><thead><tr><th colspan="100%">${title}</th></tr></thead><tbody><tr><td>No data available</td></tr></tbody></table>`;
      }

      let headers = Object.keys(data[0]);
      let rows = data
        .map((row) => {
          return `<tr>${headers
            .map((header) => `<td>${row[header]}</td>`)
            .join("")}</tr>`;
        })
        .join("");

      let headerRow = `<tr>${headers
        .map((header) => `<th>${header}</th>`)
        .join("")}</tr>`;

      return `<table><thead><tr><th colspan="${headers.length}">${title}</th></tr>${headerRow}</thead><tbody>${rows}</tbody></table>`;
    },

    tableToExcelSnfPayout(table, name) {
      let uri = "data:application/vnd.ms-excel;base64,";
      let template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
                      <head><meta charset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head>
                      <body><table>{table}</table></body></html>`;
      let base64 = (s) => window.btoa(unescape(encodeURIComponent(s)));
      let format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p]);

      let ctx = { worksheet: name || "Worksheet", table: table };
      let html = format(template, ctx);

      return uri + base64(html);
    },
    refresh() {
      this.$emit("refreshReport");
    },
  },
  mixins: [utilitiesMixin, formatMixin],
};
</script>

<style scoped>
.o-drop__item--active {
  background-color: #ffffff;
  color: #3bafda;
}
</style>
