export default {
  //Upload files page
  PROCESS_CSV: "PROCESS_CSV",
  TRIGGER_PIPELINE: "TRIGGER_PIPELINE",
  GET_DATA_TEMPLATE: "GET_DATA_TEMPLATE",
  GET_DATA_TEMPLATE_LIST: "GET_DATA_TEMPLATE_LIST",
  SET_DATA_TEMPLATE_LIST:"SET_DATA_TEMPLATE_LIST",
  GET_DATA_UPLOAD_SETTING_BY_ID: "GET_DATA_UPLOAD_SETTING_BY_ID",
  GET_DATA_UPLOAD_TEMPLATE_DETAIL_BY_ID: "GET_DATA_UPLOAD_TEMPLATE_DETAIL_BY_ID",
  GET_ALL_SETTINGS: "GET_ALL_SETTINGS",
  GET_ALL_SETTINGS_WITHOUT_DETAILS: "GET_ALL_SETTINGS_WITHOUT_DETAILS",
  ADD_DATA_UPLOAD_SETTING: "ADD_DATA_UPLOAD_SETTING",
  CSV_CONFIGURATION: "CSV_CONFIGURATION",
  GET_ALL_ENTITY: "GET_ALL_ENTITY",
  GET_FILE_UPLOAD_SETTINGS: "GET_FILE_UPLOAD_SETTINGS",
  SET_FILE_UPLOAD_SETTINGS: "SET_FILE_UPLOAD_SETTINGS",
  ADD_FILE_TO_UPLOAD: "ADD_FILE_TO_UPLOAD",
  GET_DATA_UPLOAD: "GET_DATA_UPLOAD",
  SET_DATA_MIGRATION_REPORT: "SET_DATA_MIGRATION_REPORT",
  GET_SETTINGS_MIGRATION_REPORT: "GET_SETTINGS_MIGRATION_REPORT",
  SET_SETTINGS_MIGRATION_REPORT: "SET_SETTINGS_MIGRATION_REPORT",
  GET_MIGRATION_REPORT_DETAIL: "GET_MIGRATION_REPORT_DETAIL",
  MUTATIONS: {
    SET_SETTINGS: "SET_SETTINGS",
    ADD_SETTING: "ADD_SETTING",
    SET_SCHEMAS: "SET_SCHEMAS",
    ADD_MEMOIZED_SCHEMA_DETAILS: "ADD_MEMOIZED_SCHEMA_DETAILS",
    SET_SELECTED_SCHEMA_DETAILS: "SET_SELECTED_SCHEMA_DETAILS",
    SET_FILENAME:"SET_FILENAME"
  }
};