<template>
  <div class="mapping__wrapper table-responsive newPanelWrapper"
    style="box-shadow: 0 0 1px 1px #d1d1d1 !important; padding: 0 !important">
    <table class="datagrid-table mb-0" id="btn-editable-cpg"
      style="width: 100%; border: none !important; background-color: #ffffff">
      <thead class="mapping__header">
        <tr>
          <th>Source</th>
          <th>
            Target
            <o-tooltip :active="anyAutoMappings" variant="info" position="bottom" multiline
              label="Some fields have been mapped automatically.">
              <i v-if="anyAutoMappings" class="ri-question-line"></i>
            </o-tooltip>
          </th>
          <th>Default value</th>
          <th aria-hidden="true"></th>
        </tr>
        <template v-if="value?.length > 0">
          <tr>
            <th colspan="3" class="mapping__filter-toggle-wrapper">
              <span role="switch" class="mapping__filter-toggle" :aria-checked="showFilters" @click="toggleShowFilters">
                {{ showFilters ? "Hide filters" : "Show filters" }}
              </span>
            </th>
          </tr>
          <Transition name="fade" :duration="100">
            <tr v-show="showFilters" aria-label="Filters">
              <th class="mapping__filter-header">
                <input v-model="sourceFilter" type="search" class="form-control">
              </th>
              <th colspan="2" aria-hidden="true"></th>
            </tr>
          </Transition>
        </template>
      </thead>
      <tbody>
        <tr v-for="(field, fieldIndex) in filteredFields" :key="fieldIndex">
          <td>
            <select v-model="field.HeaderName" name="source" id="source-fields" class="form-control generalSelectBox"
              @change="handleSourceChange($event, field)">
              <option :value="null">Select a source</option>
              <optgroup v-for="(file, fileIndex) in sourceFields" :key="fileIndex" :label="file.Name">
                <option v-for="(col, colIndex) in file.Columns" :key="colIndex" :value="col" :data-file="file.Name"
                  :disabled="usedSourceValues.has(`${file.Name}:${col}`)">
                  {{ col }}
                </option>
              </optgroup>
            </select>
          </td>
          <td>
            <select v-model="field.DataUploadTemplateDetailId" class="form-control generalSelectBox"
              :class="{ 'mapping__field--automapped': field.automapped }" @change="removeAutomapped(field)">
              <option v-if="targetFields?.length === 0" :value="null" disabled>
                Select a Data Template to map to...
              </option>
              <option value="null">Select</option>
              <option v-for="targetField in targetFields" :key="targetField.Id" :value="targetField.Id"
                :disabled="usedTargetValues.has(targetField.Id)">
                {{ targetField.ColumnName }}
              </option>
            </select>
          </td>
          <td>
            <select v-if="targetFields?.find(f => f.Id === field.DataUploadTemplateDetailId)?.Type === 'Select'"
              v-model="field.DefaultValue" class="form-control generalSelectBox">
              <option v-for="option in targetFields?.find(f => f.Id === field.DataUploadTemplateDetailId)?.Data"
                :key="option.Id" :value="option.Id">
                {{ option.Value }}
              </option>
            </select>
          </td>
          <td>
            <span class="mdi-set mdi-minus-circle-outline" role="button" type="button" aria-label="Remove field"
              @click="handleRemoveRow(fieldIndex)"></span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="mapping__add-new-row">
          <td class="mapping__add-new-cell" colspan="4">
            <button class="mapping__add-new-button btn" type="button" @click="handleAddNewRow"
              :disabled="value?.length >= targetFields.length">
              <span class="ri-add-circle-line" aria-hidden="true"></span>
              Add a field mapping
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import escapeRegExp from 'lodash.escaperegexp';

export default {
  name: "FieldMappingGrid",
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    targetFields: {
      type: Array,
      required: true
    },
    sourceFields: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      sourceFilter: null,
      showFilters: false,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    usedSourceValues() {
      return this.value.reduce((usedValues, currentField) => {
        if (currentField.FileName && currentField.HeaderName) {
          usedValues.add(`${currentField.FileName}:${currentField.HeaderName}`)
        }
        return usedValues
      }, new Set([]))
    },
    usedTargetValues() {
      return this.value.reduce((usedValues, currentField) => {
        if (currentField.DataUploadTemplateDetailId) {
          usedValues.add(currentField.DataUploadTemplateDetailId)
        }
        return usedValues
      }, new Set([]))
    },
    anyAutoMappings() {
      return this.value.some(field => field.automapped)
    },
    filteredFields() {
      if (!this.sourceFilter) {
        return this.value
      }

      const pattern = escapeRegExp(this.sourceFilter).split(" ").map(word => `(?=.*${word})`).join("")
      const regex = new RegExp(`${pattern}.*`, 'i')

      return this.value.filter(field => {
        return regex.test(`${field.HeaderName} ${field.FileName}`)
      })
    }
  },
  methods: {
    removeAutomapped(field) {
      field.automapped = false
    },
    toggleShowFilters() {
      this.showFilters = !this.showFilters
    },
    handleAddNewRow() {
      this.value.push({
        DataUploadTemplateDetailId: null,
        FileName: null,
        IsMandatory: false,
        DefaultValue: null,
        HeaderName: null,
      })
    },
    handleRemoveRow(index) {
      this.filteredFields.splice(index, 1)
    },
    handleSourceChange(e, field) {
      const el = e.target
      const selected = Array.prototype.find.call(el.options, (o) => o.selected)

      if (selected) {
        field.FileName = selected.dataset.file ?? ""
      } else {
        field.FileName = null
      }
    }
  }
}
</script>

<style scoped>
.mapping__wrapper {
  max-height: 380px;
}

.mapping__header {
  position: sticky;
  top: 0;
  background-color: white;

  &>>>th {
    border: none;
  }
}

.mapping__file-name {
  font-size: 0.7rem;
  color: hsl(0, 0%, 50%);
}

.mapping__field--automapped {
  box-shadow: 0 2px 4px -2px var(--primary-color);
  border: 1px solid var(--primary-color);
}

.mapping__filter-header {
  padding: 8px 15px;
}

.mapping__filter-toggle {
  cursor: pointer;
  color: var(--blue);
  text-decoration: underline;
  font-weight: 400;
  font-size: 0.7rem;
}

.mapping__filter-toggle-wrapper {
  padding: 2px 15px;
}

.mapping__add-new-button {
  cursor: pointer;
  text-align: center;
  padding: 1rem;
  width: 100%;
  color: var(--dark);

  &:disabled {
    cursor: default;
  }
}

.mapping__add-new-row {
  position: sticky;
  bottom: 0;
  padding: 0;
  background-color: var(--bluegray-100) !important;

  &:hover:enabled {
    background-color: var(--bluegray-200) !important;
  }
}

.mapping__add-new-cell {
  padding: 0;
}
</style>