import BrPopup from "@/BrPopup";
import Unsubscribe from "@/components/Unsubscribe/Unsubscribe";
import Home from "@/Home";
import Login from "@/Login";
import ResetPassword from "@/ResetPassword";
import SupportDocLogin from "@/SupportDocLogin";
import ArraySettingsManagement from "@/views/admin/ArraySettingsManagement/ArraySettingsManagement";
import AuditMatrixConfigurations from "@/views/admin/AuditMatrixConfigurations/AuditMatrixConfigurations";
import BlockedNumberManagement from "@/views/admin/BlockedNumberManagement/BlockedNumberManagement";
import CampaignManagement from "@/views/admin/CampaignManagement/CampaignManagement";
import CreditorSettingsManagement from "@/views/admin/CreditorSettingsManagement/CreditorSettingsManagement";
import CSRTaskActionManagement from "@/views/admin/CSRTaskActionManagement/CSRTaskActionManagement";
import CSRTaskTypeManagement from "@/views/admin/CSRTaskTypeManagement/CSRTaskTypeManagement";
import CustomerCancelationReasonsManagement from "@/views/admin/CustomerCancelationReasonsManagement/CustomerCancelationReasonsManagement";
import DashboardConfigManagement from "@/views/admin/DashboardConfigManagement/DashboardConfigManagement";
import DidManagement from "@/views/admin/DidManagement/DidManagement";
import DidPurchaseManagement from "@/views/admin/DidPurchaseManagement/DidPurchaseManagement";
import DispositionManagement from "@/views/admin/DispositionManagement/DispositionManagement";
import DocumentTemplateManagement from "@/views/admin/DocumentTemplateManagement/DocumentTemplateManagement";
import DocumentTypesManagement from "@/views/admin/DocumentTypesManagement/DocumentTypesManagement";
import DocusignDocumentsManagement from "@/views/admin/DocusignManagement/DocusignDocumentsManagement";
import DocusignSettingsManagement from "@/views/admin/DocusignManagement/DocusignSettings";
import DocusignTemplateTypeManagement from "@/views/admin/DocusignManagement/DocusignTemplateTypeManagement";
import DocusignPackageManagement from "@/views/admin/DocusignPackageManagement/DocusignPackageManagement";
import DripEmailManagement from "@/views/admin/DripEmailManagement/DripEmailManagement";
import EmployeeManagement from "@/views/admin/EmployeeManagement/EmployeeManagement";
import GlobalSettingsManagement from "@/views/admin/GlobalSettingsManagement/GlobalSettingsManagement";
import InboundCallRulesManagement from "@/views/admin/InboundCallRulesManagement/InboundCallRulesManagement";
import InboundCallSettingsManagement from "@/views/admin/InboundCallSettingsManagement/InboundCallSettingsManagement";
import LeadDistributionManagement from "@/views/admin/LeadDistributionManagement/LeadDistributionManagement";
import MonthlySalesDashboardConfigManagement from "@/views/admin/MonthlySalesDashboardConfigManagement/MonthlySalesDashboardConfigManagement";
import NegotiationTaskAutomationManagement from "@/views/admin/NegotiationTaskAutomationManagement/NegotiationTaskAutomationManagement";
import OfficeManagement from "@/views/admin/OfficeManagement/OfficeManagement";
import OutboundCallSettingsManagement from "@/views/admin/OutboundCallSettingsManagement/OutboundCallSettingsManagement";
import PauseReasonManagement from "@/views/admin/PauseReasonManagement/PauseReasonManagement";
import PayoutAutomationManagement from "@/views/admin/PayoutAutomationManagement/PayoutAutomationManagement";
import POASettingsManagement from "@/views/admin/POASettingsManagement/POASettingsManagement";
import QuickEmailManagement from "@/views/admin/QuickEmailManagement/QuickEmailManagement";
import QuickNoteManagement from "@/views/admin/QuickNoteManagement/QuickNoteManagement";
import QuickSmsManagement from "@/views/admin/QuickSmsManagement/QuickSmsManagement";
import RolesManagement from "@/views/admin/RolesManagement/RolesManagement";
import ScriptManagement from "@/views/admin/ScriptManagement/ScriptManagement";
import SMSBrandsManagement from "@/views/admin/SMSBrandsManagement/SMSBrandsManagement";
import StatusManagement from "@/views/admin/StatusManagement/StatusManagement";
import TagManagement from "@/views/admin/TagManagement/TagManagement";
import UISettingsManagement from "@/views/admin/UISettingsManagement/UISettingsManagement";
import ReportDataSettings from "@/views/admin/UploadDataSettings/ReportDataSettingsManagement/ReportDataSettings.vue";
import UploadDataSettings from "@/views/admin/UploadDataSettings/UploadDataSettings.vue";
import UploadFile from "@/views/admin/UploadDataSettings/UploadFile.vue";
import VMDropManagement from "@/views/admin/VMDropManagement/VMDropManagement";
import VMRecorderManagement from "@/views/admin/VMRecorderManagement/VMRecorderManagement";
import XChangeManagement from "@/views/admin/XChangeManagement/XChangeManagement";
import Customers from "@/views/agent/ClientGrid/Clients";
import CsrTaskDistro from "@/views/agent/CsrTaskDistro/CsrTaskDistro";
import CsrTickets from "@/views/agent/CsrTickets/CsrTickets";
import Dashboard from "@/views/agent/Dashboard/Dashboard";
import NegotiatorTasks from "@/views/agent/NegotiatorTasks/NegotiatorTasks";
import PendingChanges from "@/views/agent/PendingChanges/PendingChanges";
import ActiveEnrollmentsReport from "@/views/reports/ActiveEnrollmentsReport";
import AgentActivityReport from "@/views/reports/AgentActivityReport";
import AgentDropRateReport from "@/views/reports/AgentDropRateReport";
import AgentPerformanceNewReport from "@/views/reports/AgentPerformanceNewReport";
import AgentPhoneReport from "@/views/reports/AgentPhoneReport";
import AgentPhoneStatusReport from "@/views/reports/AgentPhoneStatusReport";
import AmericanExpressScrubReport from "@/views/reports/AmericanExpressScrubReport";
import ASLegalProductReport from "@/views/reports/ASLegalProductReport";
import CallLogsReport from "@/views/reports/CallLogsReport";
import CallMetricsReport from "@/views/reports/CallMetricsReport";
import CampaignPerformanceReport from "@/views/reports/CampaignPerformanceReport";
import CapitalOneScrubReport from "@/views/reports/CapitalOneScrubReport";
import ClearDraftReport from "@/views/reports/ClearDraftReport";
import ClearedSettlementFeeReport from "@/views/reports/ClearedSettlementFeeReport";
import ContractStatusesReport from "@/views/reports/ContractStatusesReport";
import CreditorReport from "@/views/reports/CreditorReport";
import CreditPullReport from "@/views/reports/CreditPullReport";
import CSRRealtimeTasksReport from "@/views/reports/CSRRealtimeTasksReport.vue";
import CSRTaskContactScheduleReport from "@/views/reports/CSRTaskContactScheduleReport";
import CSRTaskDistroQueueReport from "@/views/reports/CSRTaskDistroQueueReport";
import CSRTaskPerformanceReport from "@/views/reports/CSRTaskPerformanceReport";
import CSRTaskReport from "@/views/reports/CSRTaskReport";
import CsrTaskSummaryReport from "@/views/reports/CsrTaskSummaryReport";
import CustomerDispositionSettingsReport from "@/views/reports/CustomerDispositionSettingsReport";
import CustomerPurchaseEligibilityReport from "@/views/reports/CustomerPurchaseEligibilityReport";
import CustomerStatusReport from "@/views/reports/CustomerStatusReport";
import CustomerTagReport from "@/views/reports/CustomerTagReport";
import DebtBlueSalesCompensationReport from "@/views/reports/DebtBlueSalesCompensationReport";
import DebtChangesPostEnrollmentReport from "@/views/reports/DebtChangesPostEnrollmentReport";
import DiscoverScrubReport from "@/views/reports/DiscoverScrubReport";
import DMBSettlementFeesReport from "@/views/reports/DMBSettlementFeesReport";
import DocumentsAuditReport from "@/views/reports/DocumentsAuditReport";
import DocusignEnvelopeHistoryReport from "@/views/reports/DocusignEnvelopeHistoryReport";
import DosEquitiesReport from "@/views/reports/DosEquitiesReport";
import DroppedCustomersReport from "@/views/reports/DroppedCustomersReport";
import EarlyLoanLogReport from "@/views/reports/EarlyLoanLogReport";
import EnrolledCreditorsReport from "@/views/reports/EnrolledCreditorsReport";
import EnrolledWithoutSmsOptInReport from "@/views/reports/EnrolledWithoutSmsOptInReport";
import EnrollmentReport from "@/views/reports/EnrollmentReport";
import EnrollmentStatusChangeReport from "@/views/reports/EnrollmentStatusChangeReport";
import FirstClearDraftReport from "@/views/reports/FirstClearDraftReport";
import FirstPremierBankScrubReport from "@/views/reports/FirstPremierBankScrubReport";
import FortivaScrubReport from "@/views/reports/FortivaScrubReport";
import GlobalDraftsReport from "@/views/reports/GlobalDraftsReport";
import GlobalPaymentsAndFeesReport from "@/views/reports/GlobalPaymentsAndFeesReport";
import HotLeadReport from "@/views/reports/HotLeadReport";
import InboundStatisticsReport from "@/views/reports/InboundStatisticsReport";
import IncomingVmReport from "@/views/reports/IncomingVmReport";
import LeadAssignReport from "@/views/reports/LeadAssignReport";
import LeadTracEnrollmentsReport from "@/views/reports/LeadTracEnrollmentsReport";
import LiveCallsReport from "@/views/reports/LiveCallsReport";
import MasterScrubReport from "@/views/reports/MasterScrubReport";
import MissedCallReport from "@/views/reports/MissedCallReport";
import NegotiatorAssignReport from "@/views/reports/NegotiatorAssignReport";
import NewLeadReport from "@/views/reports/NewLeadReport";
import NewLineReport from "@/views/reports/NewLineReport";
import NexGenReport from "@/views/reports/NexGenReport";
import NSFCancellationReport from "@/views/reports/NSFCancellationReport";
import NsfGlobalReport from "@/views/reports/NsfGlobalReport";
import NSFReport from "@/views/reports/NSFReport";
import OneMainScrubReport from "@/views/reports/OneMainScrubReport";
import PaymentsAndFeesReport from "@/views/reports/PaymentsAndFeesReport";
import PendingChangesReport from "@/views/reports/PendingChangesReport";
import PendingDispositionReport from "@/views/reports/PendingDispositionReport";
import PRAScrubReport from "@/views/reports/PRAScrubReport";
import PurchasesClientInformationReport from "@/views/reports/PurchasesClientInformationReport";
import PurchasesCreditorInformationReport from "@/views/reports/PurchasesCreditorInformationReport";
import PurchasesCreditorsInformationReport from "@/views/reports/PurchasesCreditorsInformationReport";
import RemindersReport from "@/views/reports/RemindersReport";
import RespondersReport from "@/views/reports/RespondersReport";
import SalesFlowReport from "@/views/reports/SalesFlowReport";
import SettlementAssistReport from "@/views/reports/SettlementAssistReport";
import SettlementCandidatesReport from "@/views/reports/SettlementCandidatesReport";
import SettlementRequestReport from "@/views/reports/SettlementRequestReport";
import SettlementsFeeReport from "@/views/reports/SettlementsFeeReport";
import SettlementsReport from "@/views/reports/SettlementsReport";
import SmsHistoryReport from "@/views/reports/SmsHistoryReport";
import SnfAttorneyActivityReport from "@/views/reports/SnfAttorneyActivityReport";
import SnfDaaEnrollmentReport from "@/views/reports/SnfDaaEnrollmentReport";
import SnfDNCReport from "@/views/reports/SnfDNCReport";
import SnfEGReport from "@/views/reports/SnfEGReport";
import SnfEnrollmentReport from "@/views/reports/SnfEnrollmentReport";
import SnfFirstDraftReport from "@/views/reports/SnfFirstDraftReport";
import SnfPayoutReport from "@/views/reports/SnfPayoutReport";
import SnfPendingCancellationReport from "@/views/reports/SnfPendingCancellationReport";
import SOFIScrubReport from "@/views/reports/SOFIScrubReport";
import UnsettledCreditorReport from "@/views/reports/UnsettledCreditorReport";
import UsBankElanScrubReport from "@/views/reports/UsBankElanScrubReport";
import VoidedSettlementReport from "@/views/reports/VoidedSettlementReport";
import XChangeReport from "@/views/reports/XChangeReport";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    component: Login,
    beforeEnter(to, from, next) {
      if (location.href) {
        if (
          location.href.toLowerCase().includes("unsubscribe") ||
          location.href.toLowerCase().includes("resetpassword") ||
          location.href.toLowerCase().includes("bluerockscreen") ||
          location.href.toLowerCase().includes("supportdocs")
        ) {
          next(location.href);
        } else {
          next();
        }
      } else next();
    },
    name: "login",
    meta: {
      title: "Login",
    },
  },
  {
    path: "/supportdocs",
    component: SupportDocLogin,
    name: "SupportDocs",
    meta: {
      title: "Documentation",
    },
  },
  {
    path: "/unsubscribe",
    component: Unsubscribe,
    name: "Unsubscribe",
    meta: {
      title: "Unsubscribe",
    },
  },
  {
    path: "/ResetPassword",
    component: ResetPassword,
    name: "Reset Password",
    meta: {
      title: "Reset Password",
    },
  },
  {
    path: "/BlueRockScreen",
    component: BrPopup,
    name: "Br Popup",
    meta: {
      title: "BlueRockScreen",
    },
  },
  {
    path: "/",
    component: Home,
    beforeEnter(to, from, next) {
      document.title = getTitle();
      isAuthenticated() ? next() : next("/login");
      history.pushState(null, null, location.href);
      window.onpopstate = () => {
        history.go(1);
      };
    },

    name: "home",
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        component: Dashboard,
        name: "dashboard",
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/customers",
        beforeEnter(to, from, next) {
          next();
          // isAuthorized() ? next() : next('/')
        },
        component: Customers,
        name: "customers",
        meta: {
          title: "Customers",
        },
      },

      {
        path: "/office-management",
        beforeEnter(to, from, next) {
          isAuthorized(1453) ? next() : next("/");
        },
        component: OfficeManagement,
        name: "office",
        meta: {
          title: "Offices",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(921085) ? next() : next("/");
        },
        path: "/ui-settings-management",
        component: UISettingsManagement,
        meta: {
          title: "UI Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(81451) ? next() : next("/");
        },
        path: "/dashboard-config",
        component: DashboardConfigManagement,
        meta: {
          title: "Sales Dashboard Configuration",
        },
      },

      {
        path: "/docusign-package-management",
        beforeEnter(to, from, next) {
          isAuthorized(645643) ? next() : next("/");
        },
        component: DocusignPackageManagement,
        meta: {
          title: "Packages",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(9542) ? next() : next("/");
        },
        path: "/employee-management",
        component: EmployeeManagement,
        meta: {
          title: "Employees",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(1096) ? next() : next("/");
        },
        path: "/disposition-management",
        component: DispositionManagement,
        meta: {
          title: "Dispositions",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(5634) ? next() : next("/");
        },
        path: "/document-template-management",
        component: DocumentTemplateManagement,
        meta: {
          title: "Document Templates",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(5146) ? next() : next("/");
        },
        path: "/quick-email-management",
        component: QuickEmailManagement,
        meta: {
          title: "Quick Emails",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3432) ? next() : next("/");
        },
        path: "/quick-note-management",
        component: QuickNoteManagement,
        meta: {
          title: "Quick Notes",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(966234) ? next() : next("/");
        },
        path: "/customer-purchase-eligibility-report",
        component: CustomerPurchaseEligibilityReport,
        meta: {
          title: "Customer Purchase Eligibility Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54999) ? next() : next("/");
        },
        path: "/dmb-settlement-fees-report",
        component: DMBSettlementFeesReport,
        meta: {
          title: "DMB Settlement Fees Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(1580) ? next() : next("/");
        },
        path: "/quick-sms-management",
        component: QuickSmsManagement,
        meta: {
          title: "Quick SMS",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(6580) || isAuthorized(6581) ? next() : next("/");
        },
        path: "/roles-management",
        component: RolesManagement,
        meta: {
          title: "Roles",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(952) ? next() : next("/");
        },
        path: "/status-management",
        component: StatusManagement,
        meta: {
          title: "Statuses",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(64326) ? next() : next("/");
        },
        path: "/tag-management",
        component: TagManagement,
        meta: {
          title: "Customer Tags",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3590) ? next() : next("/");
        },
        path: "/active-enrollments-report",
        component: ActiveEnrollmentsReport,
        meta: {
          title: "Active Enrollments Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(54993) ? next() : next("/");
        },
        path: "/contract-statuses-report",
        component: ContractStatusesReport,
        meta: {
          title: "Contract Statuses Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(3590) ? next() : next("/");
        },
        path: "/active-enrollments-report",
        component: ActiveEnrollmentsReport,
        meta: {
          title: "Active Enrollments Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(55419) ? next() : next("/");
        },
        path: "/enrolled-without-sms-opt-in-report",
        component: EnrolledWithoutSmsOptInReport,
        meta: {
          title: "Enrolled Without SMS Optin Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3881) ? next() : next("/");
        },
        path: "/global-payments-and-fees-report",
        component: PaymentsAndFeesReport,
        meta: {
          title: "Global Payments and Fees Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3882) ? next() : next("/");
        },
        path: "/global-drafts-report",
        component: GlobalDraftsReport,
        meta: {
          title: "Global Drafts Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(55418) ? next() : next("/");
        },
        path: "/snf-pending-cancellation-report",
        component: SnfPendingCancellationReport,
        meta: {
          title: "SNF Pending Cancellation Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(5819) ? next() : next("/");
        },
        path: "/hot-lead-report",
        component: HotLeadReport,
        meta: {
          title: "Hot Lead Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(5820) ? next() : next("/");
        },
        path: "/xchange-report",
        component: XChangeReport,
        meta: {
          title: "XChange Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(3712) ? next() : next("/");
        },
        path: "/dropped-customers-report",
        component: DroppedCustomersReport,
        meta: {
          title: "Dropped Customers Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(97696) ? next() : next("/");
        },
        path: "/agent-phone-status-report",
        component: AgentPhoneStatusReport,
        meta: {
          title: "Agent Phone Status Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(8913) || isAuthorized(8914) ? next() : next("/");
        },
        path: "/csr-task-action-management",
        component: CSRTaskActionManagement,
        meta: {
          title: "Task Actions",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8913) || isAuthorized(8914) ? next() : next("/");
        },
        path: "/csr-task-type-management",
        component: CSRTaskTypeManagement,
        meta: {
          title: "Task Types",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8543) ? next() : next("/");
        },
        path: "/docusign-settings-management",
        component: DocusignSettingsManagement,
        meta: {
          title: "API Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(67098) ? next() : next("/");
        },
        path: "/a&s-legal-product-report",
        component: ASLegalProductReport,
        meta: {
          title: "A&S Legal Product Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8543) ? next() : next("/");
        },
        path: "/docusign-documents-management",
        component: DocusignDocumentsManagement,
        meta: {
          title: "Documents",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8543) ? next() : next("/");
        },
        path: "/docusign-template-type-management",
        component: DocusignTemplateTypeManagement,
        meta: {
          title: "Template Types",
        },
      },
      {
        path: "/script-management",
        beforeEnter(to, from, next) {
          isAuthorized(1496) ? next() : next("/");
        },
        component: ScriptManagement,
        name: "script",
        meta: {
          title: "Scripts",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3841) ? next() : next("/");
        },
        path: "/vm-drop-management",
        component: VMDropManagement,
        meta: {
          title: "VM Templates",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(1662) ? next() : next("/");
        },
        path: "/pause-reason-management",
        component: PauseReasonManagement,
        meta: {
          title: "Pause Reasons",
        },
      },
      {
        beforeEnter(to, from, next) {
          next();
        },
        path: "/vm-recorder-management",
        component: VMRecorderManagement,
        meta: {
          title: "VM Recorder",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(2774) ? next() : next("/");
        },
        path: "/campaign-management",
        component: CampaignManagement,
        meta: {
          title: "Campaigns",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(1588) ? next() : next("/");
        },
        path: "/drip-email-management",
        component: DripEmailManagement,
        meta: {
          title: "Drip Email",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8361) ? next() : next("/");
        },
        path: "/lead-distro-management",
        component: LeadDistributionManagement,
        meta: {
          title: "Lead Distribution",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(921195) ? next() : next("/");
        },
        path: "/negotiator-task-automation",
        component: NegotiationTaskAutomationManagement,
        meta: {
          title: "Negotiator Task Automation",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(3891) ? next() : next("/");
        },
        path: "/global-settings-management",
        component: GlobalSettingsManagement,
        meta: {
          title: "Global Settings",
        },
      },
      // {
      //   beforeEnter(to, from, next) {
      //     isAuthorized(240) ? next() : next("/");
      //   },
      //   path: "/company-did-management",
      //   component: CompanyDidManagement,
      //   meta: {
      //     title: "DID Settings",
      //   },
      // },
      // {
      //   beforeEnter(to, from, next) {
      //     isAuthorized(3901) ? next() : next("/");
      //   },
      //   path: "/call-settings-management",
      //   component: CallSettingsManagement,
      //   meta: {
      //     title: "Call Settings",
      //   },
      // },
      {
        beforeEnter(to, from, next) {
          isAuthorized(9977) ? next() : next("/");
        },
        path: "/credit-pull-settings-management",
        component: ArraySettingsManagement,
        meta: {
          title: "Credit Pull Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(645644) ? next() : next("/");
        },
        path: "/creditor-settings-management",
        component: CreditorSettingsManagement,
        meta: {
          title: "Creditor Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(7470) ? next() : next("/");
        },
        path: "/negotiator-tasks",
        component: NegotiatorTasks,
        meta: {
          title: "",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(10022) ? next() : next("/");
        },
        path: "/csr-tasks",
        component: CsrTaskDistro,
        meta: {
          title: "",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(12400) ? next() : next("/");
        },
        path: "/pending-changes",
        component: PendingChanges,
        meta: {
          title: "Pending Changes",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(810245) ? next() : next("/");
        },
        path: "/settlement-candidates-report",
        component: SettlementCandidatesReport,
        meta: {
          title: "Settlement Candidates Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(33454) ? next() : next("/");
        },
        path: "/settlements-fee-report",
        component: SettlementsFeeReport,
        meta: {
          title: "Settlements Fee Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(9327) ? next() : next("/");
        },
        path: "/agent-performance-report",
        component: AgentPerformanceNewReport,
        meta: {
          title: "Agent Performance Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54345) ? next() : next("/");
        },
        path: "/campaign-performance-reports",
        component: CampaignPerformanceReport,
        meta: {
          title: "Campaign Performance Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(45324) ? next() : next("/");
        },
        path: "/clear-draft-report",
        component: ClearDraftReport,
        meta: {
          title: "Cleared Drafts Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(97695) ? next() : next("/");
        },
        path: "/creditor-report",
        component: CreditorReport,
        meta: {
          title: "Creditor Search",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(8113) ? next() : next("/");
        },
        path: "/call-logs-reports",
        component: CallLogsReport,
        meta: {
          title: "Call Logs Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8263) ? next() : next("/");
        },
        path: "/csr-task-performance-report",
        component: CSRTaskPerformanceReport,
        meta: {
          title: "CSR Task Performance Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(64325) ? next() : next("/");
        },
        path: "/credit-pull-report",
        component: CreditPullReport,
        meta: {
          title: "Credit Pull Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(7261) ? next() : next("/");
        },
        path: "/csr-realtime-tasks-report",
        component: CSRRealtimeTasksReport,
        meta: {
          title: "CSR Realtime Tasks Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3715) ? next() : next("/");
        },
        path: "/csr-task-report",
        component: CSRTaskReport,
        meta: {
          title: "CSR Task Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(5366) ? next() : next("/");
        },
        path: "/customer-status-reports",
        component: CustomerStatusReport,
        meta: {
          title: "Customer Status Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(61346) ? next() : next("/");
        },
        path: "/customer-tag-report",
        component: CustomerTagReport,
        meta: {
          title: "Customer Tag Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(9713) ? next() : next("/");
        },
        path: "/docusign-envelope-history-report",
        component: DocusignEnvelopeHistoryReport,
        meta: {
          title: "Docusign Envelope History Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3591) ? next() : next("/");
        },
        path: "/enrollment-report",
        component: EnrollmentReport,
        meta: {
          title: "Enrollment Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8272) ? next() : next("/");
        },
        path: "/enrollment-status-change-report",
        component: EnrollmentStatusChangeReport,
        meta: {
          title: "Enrollment Status Change Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(54350) ? next() : next("/");
        },
        path: "/settlements-request-report",
        component: SettlementRequestReport,
        meta: {
          title: "Settlement Request Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(46354) ? next() : next("/");
        },
        path: "/first-clear-draft-report",
        component: FirstClearDraftReport,
        meta: {
          title: "First Cleared Drafts Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(5578) ? next() : next("/");
        },
        path: "/inbound-statistics",
        component: InboundStatisticsReport,
        meta: {
          title: "Inbound Statistics Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(9677) ? next() : next("/");
        },
        path: "/incoming-vm-report",
        component: IncomingVmReport,
        meta: {
          title: "Incoming Vm Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(8162) ? next() : next("/");
        },
        path: "/lead-assign-report",
        component: LeadAssignReport,
        meta: {
          title: "Lead Assign Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(3821) ? next() : next("/");
        },
        path: "/live-calls-reports",
        component: LiveCallsReport,
        meta: {
          title: "Live Calls Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(6180) ? next() : next("/");
        },
        path: "/missed-call-reports",
        component: MissedCallReport,
        meta: {
          title: "Missed Call Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(8275) ? next() : next("/");
        },
        path: "/negotiator-assign-report",
        component: NegotiatorAssignReport,
        meta: {
          title: "Negotiator Assign Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(81401) ? next() : next("/");
        },
        path: "/lead-trac-enrollments-report",
        component: LeadTracEnrollmentsReport,
        meta: {
          title: "Lead Trac Enrollments Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(7452) ? next() : next("/");
        },
        path: "/nsf-report",
        component: NSFReport,
        meta: {
          title: "NSF Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(9547) ? next() : next("/");
        },
        path: "/pending-changes-report",
        component: PendingChangesReport,
        meta: {
          title: "Pending Changes Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(4721) ? next() : next("/");
        },
        path: "/pending-disposition-report",
        component: PendingDispositionReport,
        meta: {
          title: "Pending Disposition Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(6582) ? next() : next("/");
        },
        path: "/reminders-reports",
        component: RemindersReport,
        meta: {
          title: "Reminders Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(33453) ? next() : next("/");
        },
        path: "/settlements-reports",
        component: SettlementsReport,
        meta: {
          title: "Settlements Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(7567) ? next() : next("/");
        },
        path: "/sms-history-reports",
        component: SmsHistoryReport,
        meta: {
          title: "SMS History Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(55002) ? next() : next("/");
        },
        path: "/snf-enrollment-report",
        component: SnfEnrollmentReport,
        meta: {
          title: "Snf Enrollment Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(55003) ? next() : next("/");
        },
        path: "/snf-first-draft-report",
        component: SnfFirstDraftReport,
        meta: {
          title: "Snf First Draft Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(55008) ? next() : next("/");
        },
        path: "/snf-daa-enrollment-report",
        component: SnfDaaEnrollmentReport,
        meta: {
          title: "Snf DAA Enrollment Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(70002) ? next() : next("/");
        },
        path: "/new-line-report",
        component: NewLineReport,
        meta: {
          title: "New Line Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(70006) ? next() : next("/");
        },
        path: "/nex-gen-report",
        component: NexGenReport,
        meta: {
          title: "NexGen Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55005) ? next() : next("/");
        },
        path: "/snf-dnc-report",
        component: SnfDNCReport,
        meta: {
          title: "Snf DNC Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(55006) ? next() : next("/");
        },
        path: "/snf-eg-report",
        component: SnfEGReport,
        meta: {
          title: "Snf EG Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(81547) ? next() : next("/");
        },
        path: "/db-sales-compensation-report",
        component: DebtBlueSalesCompensationReport,
        meta: {
          title: "DB Sales Compensation Report",
        },
      },

      //  Phone Settings

      {
        beforeEnter(to, from, next) {
          isAuthorized(3908) ? next() : next("/");
        },
        path: "/inbound-call-settings-management",
        component: InboundCallSettingsManagement,
        meta: {
          title: "Inbound Call Settings",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(313) ? next() : next("/");
        },
        path: "/report-data-settings",
        component: ReportDataSettings,
        meta: {
          title: "Report Data Settings",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(312) ? next() : next("/");
        },
        path: "/upload-file",
        component: UploadFile,
        meta: {
          title: "Upload File",
        },
      },

      // Data upload
      {
        beforeEnter(to, from, next) {
          isAuthorized(311) ? next() : next("/");
        },
        path: "/upload-data-settings",
        component: UploadDataSettings,
        meta: {
          title: "Upload Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3922) ? next() : next("/");
        },
        path: "/inbound-call-rules-management",
        component: InboundCallRulesManagement,
        meta: {
          title: "Inbound Call Rules",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3917) ? next() : next("/");
        },
        path: "/outbound-call-settings-management",
        component: OutboundCallSettingsManagement,
        meta: {
          title: "Outbound Call Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54984) ? next() : next("/");
        },
        path: "/blocked-number-management",
        component: BlockedNumberManagement,
        meta: {
          title: "Blocked Numbers",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(240) ? next() : next("/");
        },
        path: "/did-management",
        component: DidManagement,
        meta: {
          title: "DIDs",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(273) ? next() : next("/");
        },
        path: "/did-purchase-management",
        component: DidPurchaseManagement,
        meta: {
          title: "DID Purchase",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(200010) ? next() : next("/");
        },
        path: "/csr-tickets",
        component: CsrTickets,
        meta: {
          title: "Tickets Dashboard",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(10032) ? next() : next("/");
        },
        path: "/poa-settings-management",
        component: POASettingsManagement,
        meta: {
          title: "POA Settings",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(81548) ? next() : next("/");
        },
        path: "/monthly-sales-dashboard-config-management",
        component: MonthlySalesDashboardConfigManagement,
        meta: {
          title: "Monthly Sales Dashboard Config",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(1595) ? next() : next("/");
        },
        path: "/xchange-management",
        component: XChangeManagement,
        meta: {
          title: "The XChange",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(3923) ? next() : next("/");
        },
        path: "/sms-brands-management",
        component: SMSBrandsManagement,
        meta: {
          title: "SMS Brands",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(1456) ? next() : next("/");
        },
        path: "/document-types-management",
        component: DocumentTypesManagement,
        meta: {
          title: "Manage Document Type",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55417) ? next() : next("/");
        },
        path: "/agent-phone-report",
        component: AgentPhoneReport,
        meta: {
          title: "Agent Phone Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(54000) ? next() : next("/");
        },
        path: "/fortiva-scrub-report",
        component: FortivaScrubReport,
        meta: {
          title: "Fortiva Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54001) ? next() : next("/");
        },
        path: "/american-express-scrub-report",
        component: AmericanExpressScrubReport,
        meta: {
          title: "American Express Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54002) ? next() : next("/");
        },
        path: "/master-scrubr-report",
        component: MasterScrubReport,
        meta: {
          title: "Master Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54003) ? next() : next("/");
        },
        path: "/discover-scrub-report",
        component: DiscoverScrubReport,
        meta: {
          title: "Discover Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54985) ? next() : next("/");
        },
        path: "/enrolled-creditors-report",
        component: EnrolledCreditorsReport,
        meta: {
          title: "Enrolled Creditors Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54004) ? next() : next("/");
        },
        path: "/one-main-scrub-report",
        component: OneMainScrubReport,
        meta: {
          title: "One Main Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54005) ? next() : next("/");
        },
        path: "/settlement-assist-report",
        component: SettlementAssistReport,
        meta: {
          title: "Settlement Assist Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(8234) ? next() : next("/");
        },
        path: "/csr-task-summary-report",
        component: CsrTaskSummaryReport,
        meta: {
          title: "Csr Task Summary Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(3995) ? next() : next("/");
        },
        path: "/agent-activity-report",
        component: AgentActivityReport,
        meta: {
          title: "Agent Activity Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(5377) ? next() : next("/");
        },
        path: "/customer-disposition-settings-report",
        component: CustomerDispositionSettingsReport,
        meta: {
          title: "Customer Disposition Settings Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3716) ? next() : next("/");
        },
        path: "/csr-task-distro-queue-report",
        component: CSRTaskDistroQueueReport,
        meta: {
          title: "CSR Task Distro Queue Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55021) ? next() : next("/");
        },
        path: "/sales-flow-report",
        component: SalesFlowReport,
        meta: {
          title: "Sales Flow Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(67088) ? next() : next("/");
        },
        path: "/dos-equities-report",
        component: DosEquitiesReport,
        meta: {
          title: "Dos Equities Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(810250) ? next() : next("/");
        },
        path: "/early-loan-log-report",
        component: EarlyLoanLogReport,
        meta: {
          title: "Early Loan Log Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(810247) ? next() : next("/");
        },
        path: "/cleared-settlement-fee-report",
        component: ClearedSettlementFeeReport,
        meta: {
          title: "100% Cleared Settlement Fee Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(48214) ? next() : next("/");
        },
        path: "/call-metrics-report",
        component: CallMetricsReport,
        meta: {
          title: "Call Metrics Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(7453) ? next() : next("/");
        },
        path: "/nsf-cancelation-report",
        component: NSFCancellationReport,
        meta: {
          title: "NSF Cancelation Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(55009) ? next() : next("/");
        },
        path: "/snf-responders-report",
        component: RespondersReport,
        meta: {
          title: "SNF Responders Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55057) ? next() : next("/");
        },
        path: "/debt-changes-post-enrollment-report",
        component: DebtChangesPostEnrollmentReport,
        meta: {
          title: "Enrolled Debt Changes Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(9714) ? next() : next("/");
        },
        path: "/documents-audit-report",
        component: DocumentsAuditReport,
        meta: {
          title: "Documents Audit Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55836) ? next() : next("/");
        },
        path: "/csr-task-contact-schedule-report",
        component: CSRTaskContactScheduleReport,
        meta: {
          title: "CSR Task Contact Trigger Schedule Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(55840) ? next() : next("/");
        },
        path: "/customer-cancelation-reasons-management",
        component: CustomerCancelationReasonsManagement,
        meta: {
          title: "Customer Cancelation Reasons",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(8543) ? next() : next("/");
        },
        path: "/audit-matrix-configurations",
        component: AuditMatrixConfigurations,
        meta: {
          title: "Audit Matrix Configurations",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(7452) ? next() : next("/");
        },
        path: "/nsf-global-report",
        component: NsfGlobalReport,
        meta: {
          title: "NSF Global Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(55010) ? next() : next("/");
        },
        path: "/snf-attorney-activity-report",
        component: SnfAttorneyActivityReport,
        meta: {
          title: "SNF Attorney Activity Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(3594) ? next() : next("/");
        },
        path: "/agent-drop-rate-report",
        component: AgentDropRateReport,
        meta: {
          title: "Agent Drop Rate Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(58089) ? next() : next("/");
        },
        path: "/payout-automation-management",
        component: PayoutAutomationManagement,
        meta: {
          title: "Payout Setup",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(54989) ? next() : next("/");
        },
        path: "/new-lead-report",
        component: NewLeadReport,
        meta: {
          title: "New Lead Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54348) ? next() : next("/");
        },
        path: "/unsettled-creditor-report",
        component: UnsettledCreditorReport,
        meta: {
          title: "Unsettled Creditor Report",
        },
      },

      {
        beforeEnter(to, from, next) {
          isAuthorized(87236) ? next() : next("/");
        },
        path: "/snf-payout-report",
        component: SnfPayoutReport,
        meta: {
          title: "SNF Payout Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54008) ? next() : next("/");
        },
        path: "/usbank-elan-scrub-report",
        component: UsBankElanScrubReport,
        meta: {
          title: "UsBank/Elan Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54006) ? next() : next("/");
        },
        path: "/first-premier-bank-scrub-report",
        component: FirstPremierBankScrubReport,
        meta: {
          title: "First Premier Bank Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54007) ? next() : next("/");
        },
        path: "/capital-one-scrub-report",
        component: CapitalOneScrubReport,
        meta: {
          title: "Capital One Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(5213) ? next() : next("/");
        },
        path: "/voided-settlement-report",
        component: VoidedSettlementReport,
        meta: {
          title: "Voided Settlement Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(83881) ? next() : next("/");
        },
        path: "/dpg-payments-and-fees-report",
        component: GlobalPaymentsAndFeesReport,
        meta: {
          title: "DPG Payments and Fees Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54009) ? next() : next("/");
        },
        path: "/pra-scrub-report",
        component: PRAScrubReport,
        meta: {
          title: "PRA Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54010) ? next() : next("/");
        },
        path: "/sofi-scrub-report",
        component: SOFIScrubReport,
        meta: {
          title: "SOFI Scrub Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54021) ? next() : next("/");
        },
        path: "/purchases-client-information-report",
        component: PurchasesClientInformationReport,
        meta: {
          title: "Purchases - Client Information Report",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54015) ? next() : next("/");
        },
        path: "/purchases-creditors-information-report",
        component: PurchasesCreditorsInformationReport,
        meta: {
          title: "Purchases - Creditors",
        },
      },
      {
        beforeEnter(to, from, next) {
          isAuthorized(54147) ? next() : next("/");
        },
        path: "/purchase-creditor-information-report",
        component: PurchasesCreditorInformationReport,
        meta: {
          title: "Purchases - Creditor Information (General)",
        },
      },
    ],
  },
  { path: "/:catchAll(.*)", component: Login },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function isAuthorized(permissionId) {
  let rtn = false;

  let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

  let arrPermission = userInfo.moduleFunctions.split(",").map(function (item) {
    return parseInt(item, 10);
  });

  if (arrPermission.includes(permissionId)) {
    rtn = true;
  }
  return rtn;
}

function isAuthenticated() {
  let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
  if (!userInfo) return false;
  let expireUTC = sessionStorage.getItem("expireUTC");
  if (expireUTC) {
    let expireLocalDate = new Date(expireUTC);
    let dtNow = new Date();
    if (dtNow > expireLocalDate) return false;
  }

  return true;
}

function getTitle() {
  let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
  if (!userInfo) return "CRM";
  return userInfo.companyName;
}

export default router;
