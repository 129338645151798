<!--
 * @file LoanApplication.vue
 * @description This component is created for displaying the base form for creating an early loan request.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 * 
 * @section Usage
 * This component can be used to display the base form for early loans.

 * @section Props
 * - `title` (String): The string containing the title of the form.
 * - `data` (Object): The Object containing all the customer related data.
 -->
<template>
  <div
    v-click-outside="close"
    class="col-md-12 newPanelWrapper noMargin smp-mediaScreen"
  >
    <div class="header">
      <label>{{ title }}</label>
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="close">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <perfect-scrollbar>
      <div class="col-md-12 row">
        <slot name="defaultFields">
          <div class="col-md-4">
            <label class="generalLabel">First Name</label>
            <input class="form-control" :value="data?.FirstName" disabled />
          </div>

          <div class="col-md-4">
            <label class="generalLabel">Last Name</label>
            <input class="form-control" :value="data?.LastName" disabled />
          </div>

          <div class="col-md-4">
            <label class="generalLabel">Email</label>
            <input class="form-control" :value="data?.Email" disabled />
          </div>

          <slot name="phoneNumber">
            <div class="col-md-4">
              <label class="generalLabel">Phone</label>
              <input
                class="form-control"
                :value="data?.PhoneNumber"
                v-myMask="{ mask: ['(999) 999-9999'] }"
                disabled
              />
            </div>
          </slot>

          <div class="col-md-4">
            <label class="generalLabel">Date of Birth</label>
            <input
              class="form-control"
              :value="dateFormat({ date: data?.DateOfBirth })"
              disabled
            />
          </div>

          <div class="col-md-4">
            <label class="generalLabel">SSN</label>
            <input
              type="text"
              class="form-control"
              v-myMask="{ mask: ['999-99-9999'] }"
              :value="data?.Ssn"
              disabled
            />
          </div>

          <div class="col-md-4">
            <label class="generalLabel">Debt Amount</label>
            <input
              type="text"
              class="form-control"
              :value="format.methods.formatMoney(data?.DebtAmount)"
              disabled
            />
          </div>

          <div class="col-md-4">
            <label class="generalLabel">Annual Income</label>
            <input
              type="text"
              class="form-control"
              :value="format.methods.formatMoney(data?.AnnualIncome)"
              disabled
            />
          </div>

          <div class="col-md-4">
            <label class="generalLabel">Address</label>
            <input class="form-control" :value="data?.Address" disabled />
          </div>

          <div class="col-md-4">
            <label class="generalLabel">City</label>
            <input
              type="text"
              class="form-control"
              :value="data?.City"
              disabled
            />
          </div>

          <div class="col-md-4">
            <label class="generalLabel">State</label>
            <input
              type="text"
              class="form-control"
              :value="data?.State"
              disabled
            />
          </div>

          <div class="col-md-4">
            <label class="generalLabel">Zip</label>
            <input
              type="text"
              class="form-control"
              :value="data?.Zip"
              disabled
            />
          </div>
        </slot>
        <slot name="otherFields"></slot>
      </div>
    </perfect-scrollbar>
    <div class="col-md-12">
      <div class="col-md-12">
        <button
          type="button"
          class="btn btn-success noMargin col-md-12"
          @click="submit()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import formatMixin from "@/mixins/formatMixin";

import { dateFormat } from "@/helpers/dateHelpers";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

const store = useStore();
const customer = computed(() => store.state.clientManagement.currentClient);
const props = defineProps({
  title: {
    type: String,
    default: "Popup title",
  },
  data: {
    type: Object,
    default: () => ({
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      DateOfBirth: "",
      Address: "",
      Ssn: "",
      DebtAmount: "",
      State: "",
      City: "",
      Zip: "",
    }),
  },
});

const emit = defineEmits(["close", "submit"]);

const close = () => emit("close");
const submit = () =>
  emit("submit", {
    LoanData: props.data,
    CampaignId: customer.value.CustomerCampaignDetail.CampaignId,
  });

const format = defineComponent(formatMixin);
</script>

<style scoped>
.has-error {
  border: 2px solid red !important;
}
.header {
  font-size: 15px;
  padding-left: 24px;
  margin-top: -5px;
  padding-bottom: 10px;
}
:deep(.form-control:disabled) {
  background: #f1efef;
}
:deep(.ps) {
  width: 100%;
  max-height: 600px;
  overflow-x: hidden !important;
}
@media screen and (max-height: 730px) {
  .smp-mediaScreen {
    max-height: 600px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 620px) {
  .smp-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 420px) {
  .smp-mediaScreen {
    max-height: 250px !important;
    overflow-y: scroll;
  }
}
</style>
