<template>
    <o-tooltip :active="statusMessage" variant="info" multiline>
        <template #content>
            <span class="sync-status__message">{{ statusMessage }}</span>
            <span v-if="timeSinceLastSync">{{ timeSinceLastSync }}</span>
        </template>
        <span class="sync-status__icon" :class="{
            'ri-checkbox-circle-line sync-status__icon--success': status === 'success',
            'ri-close-circle-line sync-status__icon--failed': status === 'failed',
            'fas fa-spinner sync-status__icon--loading': isSyncing,
            'ri-question-line': status === 'unsynced'
        }">
        </span>
        <span id="last-sync-info" class="sync-status" aria-hidden="true">
            {{ isSyncing ? "Syncing..." : status }}
        </span>
    </o-tooltip>
    <span class="sr-only">
        {{ `Sync status: ${status}.` }}
        {{ lastSyncDatetime ? `Last updated: ${dateFormatter.format(lastSyncDatetime)}.` : "" }}
    </span>
    <o-tooltip label="Sync documents and their metadata from MetaSource." variant="info" multiline :delay="500">
        <button class="sync-button btn" @click="requestSync" :disabled="isSyncing">
            <span class="fe-refresh-cw" aria-hidden="true"></span>
            <span class="sync-button__label">Sync</span>
        </button>
    </o-tooltip>
</template>

<script>
import customerApi from '@/services/client/customerApi';

export default {
    props: {
        customerId: {
            type: String,
            required: true,
        }
    },
    emits: ['synced'],
    data() {
        return {
            status: "unsynced",
            statusMessage: null,
            lastSyncDatetime: null,
            currentTime: Date.now(),
            dateFormatter: new Intl.DateTimeFormat("en-US", { dateStyle: "long", timeStyle: "medium" }),
            relativeTimeFormatter: new Intl.RelativeTimeFormat("en", { style: "short" }),
            currentTimeIntervalId: null,
            operationId: null,
            syncAttempts: 0,
        }
    },
    computed: {
        timeSinceLastSync() {
            if (!this.lastSyncDatetime) {
                return null
            }

            const diff = Math.floor((this.lastSyncDatetime - this.currentTime) / 1000)

            if (diff > -5) {
                return "Just now"
            }

            const hours = Math.ceil(diff / 3600)
            if (hours <= -1) {
                return this.relativeTimeFormatter.format(hours, "hour")
            }

            const minutes = Math.ceil(diff / 60)
            if (minutes <= -1) {
                return this.relativeTimeFormatter.format(minutes, "minute")
            }

            return this.relativeTimeFormatter.format(diff, "second")
        },
        isSyncing() {
            return this.status === 'started' || this.status === 'syncing'
        },
    },
    polling: {
        maxAttempts: 30,
        interval: 2000
    },
    mounted() {
        this.currentTimeIntervalId = setInterval(() => {
            this.currentTime = Date.now()
        }, 1000)
    },
    unmounted() {
        if (this.currentTimeIntervalId) {
            clearInterval(this.currentTimeIntervalId)
        }
    },
    methods: {
        async requestSync() {
            this.status = "started"
            this.statusMessage = null

            try {
                const { data } = await customerApi.syncMetasourceDocuments(this.customerId)

                if (data?.Data?.OperationId) {
                    this.operationId = data.Data.OperationId

                    this.syncStatus()
                }
            } catch (error) {
                this.statusMessage = "An unknown error ocurred."
                this.status = "failed"
                this.lastSyncDatetime = Date.now()
            }
        },
        async syncStatus() {
            if (!this.operationId) {
                this.status = "failed"
                return
            }

            try {
                const res = await customerApi.getMetasourceSyncStatus(this.operationId)

                const status = res?.data?.StatusLog
                this.statusMessage = res?.data?.MessageLog ?? null

                switch (status) {
                    case "Success":
                        this.status = "success"
                        this.$emit("synced")
                        this.syncAttempts = 0
                        this.lastSyncDatetime = Date.now()
                        break
                    case "Error":
                        this.status = "failed"
                        this.syncAttempts = 0
                        this.lastSyncDatetime = Date.now()
                        break
                    default:
                        if (this.syncAttempts < this.$options.polling.maxAttempts) {
                            this.syncAttempts += 1
                            setTimeout(this.syncStatus, this.$options.polling.interval)
                        } else {
                            this.status = "failed"
                            this.syncAttempts = 0
                            this.lastSyncDatetime = Date.now()
                        }
                        break
                }
            } catch (error) {
                this.statusMessage = "An unknown error ocurred."
                this.status = "failed"
                this.syncAttempts = 0
                this.lastSyncDatetime = Date.now()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.sync-button {
    display: flex;
    gap: 0.2rem;
    justify-content: space-between;
    align-items: baseline;
    background-color: hsl(0, 0%, 90%);
    color: #424242;
    font-size: 0.8rem;

}

.sync-button__label {
    margin-left: 0.4rem;
}

.sync-status__icon--loading {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.sync-status__icon--success {
    color: green;
}

.sync-status__icon--failed {
    color: red;
}

.sync-status__icon {
    vertical-align: middle;
    line-height: 1;
}

.sync-status__message {
    display: block;
}

.sync-status {
    margin-left: 0.2rem;
    font-size: 0.7rem;
    text-transform: capitalize;
}
</style>