import koPaths from "./koPaths.json"

const siteRoutes = {
    "/dashboard": koPaths.dashboard,
    "/campaign-management": koPaths.admin.campaigns,
    "/csr-tasks": koPaths.csrTasks,
    "/pending-changes": koPaths.pendingChanges,
    "/csr-tickets": koPaths.tickets,
    "/vm-recorder-management": koPaths.vmRecorder,

    // Company settings
    "/creditor-settings-management": koPaths.admin.creditorSettings,
    "/global-settings-management": koPaths.admin.globalSettings,
    "/lead-distro-management": koPaths.admin.leadDistribution,
    "/negotiator-task-automation": koPaths.admin.negotiatorTaskAutomation,
    "/office-management": koPaths.admin.offices,
    "/payout-automation-management": koPaths.admin.payoutSetup,
    "/poa-settings-management": koPaths.admin.poaSettings,

    // Csr Tasks
    "/csr-task-action-management": koPaths.admin.csrTaskAction,
    "/csr-task-type-management": koPaths.admin.csrTaskTypes,

    // Customer settings
    "/customer-cancelation-reasons-management": koPaths.admin.cancellationReasons,
    "/disposition-management": koPaths.admin.dispositions,
    "/document-template-management": koPaths.admin.documentTemplates,
    "/drip-email-management": koPaths.admin.dripEmails,
    "/quick-email-management": koPaths.admin.quickEmails,
    "/quick-note-management": koPaths.admin.quickNotes,
    "/quick-sms-management": koPaths.admin.quickSms,
    "/status-management": koPaths.admin.statuses,
    "/tag-management": koPaths.admin.tags,
    "/xchange-management": koPaths.admin.xchange,

    // Docusign settings
    "/docusign-settings-management": koPaths.admin.docusign.api,
    "/audit-matrix-configurations": koPaths.admin.docusign.auditMatrix,
    "/docusign-documents-management": koPaths.admin.docusign.documents,
    "/docusign-package-management": koPaths.admin.docusign.packages,
    "/docusign-template-type-management": koPaths.admin.docusign.templateTypes,
    
    // Employee settings
    "/pause-reason-management": koPaths.admin.employees.pauseReason,
    "/roles-management": koPaths.admin.employees.roles,
    "/script-management": koPaths.admin.employees.scripts,
    "/vm-drop-management": koPaths.admin.employees.vmTemplate,
    
    // Phone settings
    "/blocked-number-management": koPaths.admin.phone.blockedNumbers,
    "/did-management": koPaths.admin.phone.dids,
    "/did-purchase-management": koPaths.admin.phone.didPurchases,
    "/inbound-call-rules-management": koPaths.admin.phone.inboundCallRules,
    "/inbound-call-settings-management": koPaths.admin.phone.inboundCallSettings,
    "/outbound-call-settings-management": koPaths.admin.phone.outboundCallSettings,
    "/sms-brands-management": koPaths.admin.phone.smsBrands,

    // Document Types
    "/document-types-management": koPaths.admin.documentTypes,

    // Debt blue specific
    "/monthly-sales-dashboard-config-management": koPaths.admin.monthlySalesDashboard,
    "/dashboard-config": koPaths.admin.salesDashboard,

    //Data upload
    "/upload-data-settings": koPaths.admin.dataUpload.uploadDataSettings,
    "/report-data-settings": koPaths.admin.dataUpload.reportDataSettings,
    "/upload-file": koPaths.admin.dataUpload.fileUpload

};

export default siteRoutes;