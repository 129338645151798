<template>
  <div class="row admin-page officePage">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-md-12 row">
      <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
        <button
          v-if="enableAddNewDocument"
          type="button"
          class="col-md-12 btn btn-primary waves-effect waves-light"
          @click="handleAddNew"
        >
          Add New Document Type
        </button>

        <div class="filter form-group row">
          <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
            <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                placeholder="Filter Document Type"
                v-model="nameFilter"
              />
            </div>
          </div>
          <div class="list col-md-12">
            <div
              class="office item row"
              v-for="(item, index) in filteredNames"
              :key="item.Id"
              :class="{ active: activeItem === item.Id }"
              :style="[
                index % 2 === 0
                  ? { 'background-color': '#F1EFEF' }
                  : { 'background-color': '#FFFFFF' },
              ]"
              @click="() => handleSelection(item)"
            >
              <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
                <i class="ri-settings-5-fill navigationItemIcon"></i>
                <span style="margin-left: 8px">{{
                  item.Name
                }}</span>
              </span>
            </div>
          </div>
          <div
            v-if="isMobileDevice()"
            style="
              border-top: 1px solid rgb(0, 0, 0, 0);
              margin: 20px 0 0 9px;
              padding-bottom: 55px;
            "
            class="col-md-12 scrollToSelectedItem"
          ></div>
        </div>
      </div>
      <div
        class="col-xl-9 col-lg-6 col-md-12 col-sm-12 panelWrapper noMargin"
        v-if="isEditing"
      >
        <div class="panelContent">
          <div class="form-group row">
            <div class="col-md-12 col-xl-6 col-lg-9">
              <label for="nameInput" class="generalLabel"
                >Name:</label
              >
              <input
                autocomplete="none"
                type="text"
                class="form-control"
                id="nameInput"
                v-model="selectedRow.Name"
                :disabled="selectedRow.Id > 0"
                :class="
                  GetTxtBoxClasses(
                    selectedRow.Id,
                    v$.selectedRow.Name.$error
                  )
                "
                @blur="v$.selectedRow.Name.$touch"
                @change="nameInputChanged"
                placeholder="Enter Name"
              />
              <a
                v-if="hasInvalidCharacters(selectedRow.Name)"
                class="ml-2"
                :style="'color:grey'">
                <i :style="'color:#FF6961'" class="fas fa-times-circle"/>
                Invalid character
              </a>
            </div>
          </div>

          <div class="form-group row">  
            <div class="col-md-12 col-xl-6 col-lg-9">
              <label for="toStatus" class="generalLabel">Status:</label>
              <select
                v-model="selectedRow.DocumentTypeStatusId"
                :disabled="selectedRow.Id > 0"
                id="toStatus"
                class="form-control"
                :class="
                  GetTxtBoxClasses(
                    selectedRow.Id,
                    v$.selectedRow.DocumentTypeStatusId.$error
                  )
                "
                @blur="v$.selectedRow.DocumentTypeStatusId.$touch"
              >
              <option value="">Please Select</option>
                <option
                  v-for="item in filteredStatuses"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row" v-if="selectedRow.Id">
            <div class="col-md-12 col-xl-6 col-lg-9">
              <label class="generalLabel"
                >Added On: {{ formatGridDate(selectedRow.CreationDate) }}</label
              >
            </div>
          </div>

          <div class="col-md-6 row noPadding" style="gap: 10px">
            <button
              v-if="selectedRow.Id <= 0"
              type="button"
              class="btn btn-success col-md-5"
              @click="saveDocumentTypeSettings()"
              :disabled="isLoading || v$.$invalid"
              style="width: 100%"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin.js";
import utilitiesMixin from "@/mixins/utilitiesMixin.js";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
const init = {
  Id: 0,
  CreationDate: null,
  Name: "",
  DocumentTypeStatusId: "",
};

export default {
  name: "DocumentTypesManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: {},
  data() {
    return {
      v$: useValidate(),
      isLoading: false,
      selectedRow: Object.assign({}, init),
      outboundCallMethodId: 0,
      oldCallMethodId: 0,
      nameFilter: "",
      names: [],
      statuses: [],
      oldValue: "",
      isEditing: false,
      valueToRevert: deepCopy(init),
      activeItem: null,
      isCancelledBeforeSave: false,
      enableAddNewDocument: false,
    };
  },
  computed: mapState({
    filteredNames() {
      return _orderby.orderBy(
        this.names.filter(
          (x) =>
            x.Name.toLowerCase().indexOf(
              this.nameFilter.toLowerCase()
            ) > -1
        ),
        "Name",
        "asc"
      );
    },
    
    filteredStatuses() {
      return _orderby.orderBy(this.statuses, "Name", "asc");
    },
    
  }),
  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let arrPermission = userInfo.moduleFunctions
      .split(",")
      .map(function (item) {
        return parseInt(item, 10);
      });
      
    if (this.checkAuth(1455)) {
      this.enableAddNewDocument = true;      
    }

    // Api request to get document types
    let err, result;
    [err, result] = await this.$store.dispatch(types.GET_DOCUMENT_TYPES);
    if (result && result.Data) {
      this.names = deepCopy(result.Data);
    } else {
      this.$swal("Warning!", "There aren't document types records to display", "warning");  
    }

    let errStatus, resultStatus;
    [errStatus, resultStatus] = await this.$store.dispatch(types.GET_DOCUMENT_TYPE_STATUS);
    if (resultStatus && resultStatus.Data) {
      this.statuses = deepCopy(resultStatus.Data);
    }

  },
  methods: {
    GetTxtBoxClasses(id, err) {
      let rtn = "";
      if (err) {
        rtn = "has-error ";
      }
      if (id > 0) {
        rtn += "readonlyInput";
      }
      return rtn;
    },
    hasInvalidCharacters(name) {
      const regex = /[<>:\\|?*#$&+,;=@%{}^~[\]]/;
      return regex.test(name);
    },
    async handleSelection(row) {
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = false;
            } else {
              this.isCancelledBeforeSave = true;
              return;
            }
          });
      }
      this.isEditing = true;
      if (!this.isCancelledBeforeSave) {
        let indx = this.names.findIndex((x) => x.Id == row.Id);

        if (indx > -1) {          
          this.selectedRow = deepCopy(this.names[indx]);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = row.Id;
        } else {
          this.selectedRow = deepCopy(init);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = row.Id;
        }
      }
      this.isCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleAddNew() {
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = false;
            } else {
              this.isCancelledBeforeSave = true;
              return;
            }
          });
      }
      this.isEditing = true;
      if (!this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(init);
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = null;
      }
      this.isCancelledBeforeSave = false;
    },
    async saveDocumentTypeSettings() {
      this.isLoading = true;
      if (this.selectedRow.Id == 0) {
        // Add new document type
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.INSERT_DOCUMENT_TYPE,
          {
            Name: this.selectedRow.Name,
            DocumentTypeStatusId: this.selectedRow.DocumentTypeStatusId,
          }
        );
        
        if (result && result.Data) {
          //push to list update selected row
          this.$swal("Success!", result.Message, "success");
          this.selectedRow.Id = result.Data.Id;
          this.selectedRow.CreationDate = result.Data.CreationDate;
          this.selectedRow.Name = result.Data.Name;
          this.selectedRow.DocumentTypeStatusId = result.Data.DocumentTypeStatusId;
          this.names.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = this.selectedRow.Id;
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.isLoading = false;
    },

    reset() {
      this.activeItem = null;
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
      this.isLoading = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { 
          required,
          hasInvalidCharacter(name) {
            const regex = /[<>:\\|?*#$&+,;=@%{}^~[\]]/;
            return !regex.test(name);
          },
        },
        DocumentTypeStatusId: { required },
      },
    };
  },
};
</script>
<style>
.has-error {
  border: 2px solid red !important;
}
</style>
