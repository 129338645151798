export const TAG_TYPES = {
    CHECK_ITEM: 'CheckItem',
    DATE: 'Date',
    DATE_AND_TIME: 'Date and Time',
    EXPENSE: 'Expense',
    MONEY: 'Money',
    NUMBER: 'Number',
    PERCENTAGE: 'Percentage',
    TEXT: 'Text',
    OPTION_BOX: 'Option Box',
    INCOME: 'Income',
}