<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <div class="filter form-group row">
        <button
          class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
          @click="handleAddNew()"
        >
          Add New Settings
        </button>
        <div class="col-md-12 noPadding">
          <input
            type="checkbox"
            class="checkbox-input generalCheckBox"
            v-model="showInactiveItems"
            style="
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
              vertical-align: text-top;
            "
          />

          <label class="generalLabel" style="font-weight: normal"
            >Show Inactive Settings</label
          >
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="settingFilter"
            placeholder="Filter Settings"
          />
        </div>

        <div class="list col-md-12" v-if="!saving">
          <div
            class="office item row"
            v-for="(item, index) in sortedSettings"
            :key="item.Id"
            :class="{ active: activeItem === item.Id }"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
            :disabled="saving"
            @click="() => handleSelection(item, index)"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-settings-4-fill"></i>
              <span style="margin-left: 8px">{{ item.Name }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 55px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent" style="padding-top: 0">
        <div class="form-group row">
          <div class="col-md-12">
            <label for="globalNameInput">Name: *</label>
            <input
              autocomplete="off"
              id="globalNameInput"
              class="form-control"
              type="text"
              v-model="selectedRow.Name"
              placeholder="Enter Name"
              :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Name.$touch"
            />
          </div>
          <div class="col-md-4">
            <label for="ntaPriority">Priority</label>
            <input
              autocomplete="off"
              type="number"
              id="ntaPriority"
              min="1"
              v-model="selectedRow.Data.Priority"
              class="form-control"
              @keypress="keyChange($event)"
              v-on:click.prevent.stop="() => {}"
              placeholder=""
              @input="handlePriorityInput"
            />
          </div>

          <div class="col-md-4">
            <label for="ntaSchedule">Schedule</label>
            <select
              id="ntaSchedule"
              v-model="selectedRow.Data.ScheduleType"
              class="form-control"
            >
              <option :value="1">Daily</option>
              <option :value="2">Weekly</option>
              <option :value="3">Monthly</option>
            </select>
          </div>

          <div class="col-md-12">
            <div class="col-md-12 legend mt-4 pb-2">
              <h4>Customer Settings</h4>
              <div class="form-group row">
                <div
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                >
                  <div class="col-md-12">
                    <label for="ntaCustomerStatuses">Customer Statuses</label>
                    <label class="ml-2" style="font-size: 12px; color: #f1556c"
                      >Leave empty for all enrolled statuses</label
                    >
                    <VueMultiselect
                      id="ntaCustomerStatuses"
                      :options="enrolledStatusList"
                      v-model="selectedRow.Data.CustomerStatuses"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="Please Select"
                      label="Name"
                      track-by="Id"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="ntaMinGlobalBalance">Min Global Balance</label>
                    <input
                      autocomplete="off"
                      id="ntaMinGlobalBalance"
                      class="form-control"
                      type="text"
                      v-model="selectedRow.Data.MinGlobalBalance"
                      v-myMask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                        alias: 'currency',
                        prefix: '$',
                      }"
                    />
                  </div>
                  <div class="col-md-8">
                    <label for="ntaCustomerFiltering">Filter Criteria</label>
                    <label class="ml-2" style="font-size: 12px; color: #f1556c"
                      >Leave empty for all customers</label
                    >
                    <VueMultiselect
                      id="ntaCustomerFiltering"
                      :options="customerSettingsFilterCriteria"
                      v-model="selectedRow.Data.CustomerFiltering"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="Please Select"
                      label="Name"
                      track-by="Id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-12 legend mt-4 pb-2">
              <h4>Creditor Settings</h4>
              <div class="form-group row">
                <div
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                >
                  <div class="col-md-6">
                    <label for="ntaMinDaysDelinquent"
                      >Min Days Delinquent</label
                    >
                    <input
                      autocomplete="off"
                      id="ntaMinDaysDelinquent"
                      v-model="selectedRow.Data.MinDaysDelinquent"
                      class="form-control"
                      type="text"
                      v-myMask="{
                        alias: 'numeric',
                        min: 0,
                        allowMinus: false,
                        rightAlign: false,
                        rightAlign: false,
                      }"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="w-100" for="ntaOriginalBalanceThreshold"
                      >Original Balance Threshold</label
                    >
                    <div class="d-flex align-items-center">
                      <input
                        autocomplete="off"
                        style="width: 80px !important"
                        id="ntaOriginalBalanceThreshold"
                        v-model="selectedRow.Data.OriginalBalanceThreshold"
                        class="form-control"
                        type="text"
                        v-myMask="{
                          alias: 'decimal',
                          max: 100,
                          digits: 0,
                          allowMinus: false,
                          rightAlign: false,
                        }"
                      />
                      <label
                        style="width: 20px !important; margin: 5px 0 0 10px"
                        >%</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="ntaCreditorFiltering">Filter Criteria</label>
                    <select
                      id="ntaCreditorFiltering"
                      v-model="selectedRow.Data.CreditorFiltering"
                      class="form-control"
                      @change="creditorFilteringWarning()"
                    >
                      <option :value="1">Min Balance</option>
                      <option :value="2">Max Balance</option>
                      <option :value="3">Select Creditor</option>
                      <option :value="4">Max First Fee Collection</option>
                      <option :value="5">Max Fee in Shortest Time</option>
                    </select>
                  </div>

                  <div class="col-md-6 noPadding mt-4">
                    <div style="width: 20px; float: left; margin: 0 12px">
                      <input
                        type="checkbox"
                        class="form-control"
                        v-model="selectedRow.Data.ExcludeScrubCreditors"
                        @change="scrubCreditorChange"
                      />
                    </div>
                    <label class="col-form-label"
                      >Exclude Scrub Creditors</label
                    >
                  </div>

                  <div
                    class="col-md-12 mt-2"
                    v-if="selectedRow.Data.CreditorFiltering == 3"
                  >
                    <label for="ntaSelectedCreditors">Creditors:</label>
                    <VueMultiselect
                      id="ntaSelectedCreditors"
                      v-model="selectedRow.Data.SelectedCreditors"
                      :options="filteredCreditorList"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="Please Select"
                      label="Name"
                      track-by="Id"
                    />
                  </div>

                  <div class="col-md-12 mt-2">
                    <label for="ntaCreditorStatus">Creditor Status:</label>
                    <label class="ml-2" style="font-size: 12px; color: #f1556c"
                      >Leave empty to include all active creditor
                      statuses</label
                    >
                    <VueMultiselect
                      id="ntaCreditorStatus"
                      v-model="selectedRow.Data.CreditorStatusIds"
                      :options="filteredCreditorStatusList"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="Please Select"
                      label="Name"
                      track-by="Id"
                    />
                  </div>
                  <div class="col-md-12 mt-2">
                    <label for="ntaCreditorStatus">Account Type:</label>
                    <label class="ml-2" style="font-size: 12px; color: #f1556c"
                      >Leave empty to include all account types</label
                    >
                    <VueMultiselect
                      id="ntaCreditorStatus"
                      v-model="selectedRow.Data.CreditorAccountTypeIds"
                      :options="creditorAccountTypes"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="Please Select"
                      label="Name"
                      track-by="Id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-12 legend mt-4 pb-2">
              <h4>Automation Settings</h4>
              <div class="form-group row">
                <div
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                >
                  <div class="col-md-12">
                    <label for="ntaSelectedCreditors">Roles</label>
                    <VueMultiselect
                      id="ntaSelectedCreditors"
                      v-model="selectedRow.Data.Roles"
                      :options="negotiatorRoles"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="Please Select"
                      label="Name"
                      track-by="Id"
                    />
                  </div>
                  <div class="col-md-12 noPadding">
                    <div style="width: 20px; float: left; margin: 0 12px">
                      <input
                        type="checkbox"
                        class="form-control"
                        v-model="selectedRow.Data.IsCreditorRulesIncluded"
                      />
                    </div>
                    <label class="col-form-label"
                      >Use Creditor Rules Parameters</label
                    >
                  </div>

                  <div class="col-md-12">
                    <div class="row">
                      <div style="width: 20px; margin: 0 12px">
                        <input
                          type="checkbox"
                          class="form-control"
                          v-model="selectedRow.Data.IsCheckforNegativeCashflow"
                          :disabled="!selectedRow.Data.IsCreditorRulesIncluded"
                        />
                      </div>
                      <label class="col-form-label"
                        >Check for Negative Cashflow</label
                      >

                      <div
                        class="row ml-1"
                        v-if="
                          selectedRow.Data.IsCheckforNegativeCashflow == true
                        "
                      >
                        <div style="width: 17px; margin: 0 12px">
                          <input
                            type="checkbox"
                            class="form-control"
                            v-model="
                              selectedRow.Data.IsDisableOngoingSettlements
                            "
                          />
                        </div>
                        <label class="col-form-label" style="font-size: 14px"
                          >Disable Ongoing Settlements</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 noPadding">
                    <div style="width: 20px; float: left; margin: 0 12px">
                      <input
                        autocomplete="off"
                        type="checkbox"
                        class="form-control"
                        v-model="selectedRow.Data.IsActive"
                      />
                    </div>

                    <label class="col-form-label">Active</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-12 mt-2 noPadding"
            style="display: flex; justify-content: flex-end"
          >
            <div class="col-md-3">
              <button
                style="width: 100%"
                class="btn btn-success btn-bordered-success waves-effect"
                id="officeSettingsSaveButton"
                @click="handleSave()"
                :disabled="v$.$invalid || saving"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import types from "./types";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import VueMultiselect from "vue-multiselect";
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
const init = {
  Id: 0,
  Name: "",
  Data: {
    Priority: 1,
    MinGlobalBalance: 0,
    CustomerStatuses: [],
    CustomerFiltering: null,
    MinDaysDelinquent: 0,
    CreditorFiltering: 1,
    ScheduleType: 1,
    SelectedCreditors: [],
    OriginalBalanceThreshold: 0,
    Roles: [],
    IsActive: true,
    IsCreditorRulesIncluded: false,
    IsCheckforNegativeCashflow: false,
    IsDisableOngoingSettlements: false,
    ExcludeScrubCreditors: false,
    CreditorStatusIds: [],
    CreditorAccountTypeIds:[]
  },
};
export default {
  name: "NegotiationTaskAutomationManagement",
  components: { VueMultiselect },
  mixins: [utilitiesMixin, formatMixin],
  data() {
    return {
      creditorStatusList: [],
      creditorAccountTypes:[],
      v$: useValidate(),
      activeItem: null,
      isEditing: false,
      saving: false,
      selectedRow: Object.assign({}, init),
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
      customerSettingsFilterCriteria: [
        // { Id: 2, Name: "All Customers" },
        { Id: 1, Name: "Customer without Negotiator Task" },
        { Id: 3, Name: "Customer without Summons" },
      ],
      settingsList: [],
      creditorList: [],
      oldValue: "",
      settingFilter: "",
      showInactiveItems: false,
      allowedKeyCodes: [35, 42, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57], // *#0-9
    };
  },
  watch: {
    "selectedRow.Data.IsCreditorRulesIncluded"(newValue) {
      if (!newValue) {
        this.selectedRow.Data.IsCheckforNegativeCashflow = false;
        this.selectedRow.Data.IsDisableOngoingSettlements = false;
      }
    },
  },
  computed: mapState({
    sortedSettings() {
      return this.settingsList.length > 0
        ? _orderby.orderBy(
            this.settingsList
              .filter((x) => x.Data.IsActive || this.showInactiveItems)
              .filter(
                (x) =>
                  x.Name.toLowerCase().indexOf(
                    this.settingFilter.toLowerCase()
                  ) > -1
              ),
            "Data.Priority",
            "asc"
          )
        : [];
    },
    allRoles: (state) => state.globals.roles,
    negotiatorRoles() {
      let rtn =
        this.allRoles && this.allRoles.length > 0
          ? this.allRoles.filter((x) => x.ModuleFunctions.includes(1855))
          : [];

      if (rtn.length > 0) {
        rtn = rtn.map((x) => {
          return {
            Id: x.Id,
            Name: x.Name,
          };
        });
      }

      return rtn;
    },
    customerStatuses: (state) => state.globals.customerStatuses,
    enrolledStatusList() {
      let rtn =
        this.customerStatuses && this.customerStatuses.length > 0
          ? this.customerStatuses.filter((x) => x.IsSold == true)
          : [];

      if (rtn.length > 0) {
        rtn = rtn.map((x) => {
          return {
            Id: x.Id,
            Name: x.Name,
          };
        });
      }

      return rtn;
    },
    filteredCreditorList() {
      let rtn = [];
      if (this.creditorList && this.creditorList.length > 0) {
        rtn = this.creditorList;
        if (this.selectedRow && this.selectedRow.Data) {
          rtn = this.creditorList.filter(
            (x) => x.IsScrub != this.selectedRow.Data.ExcludeScrubCreditors
          );
        }
      }

      return rtn;
    },

    filteredCreditorStatusList() {
      let rtn = [];
      if (this.creditorStatusList && this.creditorStatusList.length > 0) {
        rtn = this.creditorStatusList.filter(
          (item) => item.Name != "Blocked" && item.Name != "Withdrawn"
        );
      }
      return rtn;
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.getCreditorNames(),
      this.getCreditorStatus(),
      this.getCreditorAccountTypes(),
    ]);
    await this.sleep(500);
    await this.getSettings();
  },
  methods: {
    creditorFilteringWarning() {
      if (
        this.selectedRow.Data.CreditorFiltering == 4 ||
        this.selectedRow.Data.CreditorFiltering == 5
      ) {
        if (!this.selectedRow.Data.IsCreditorRulesIncluded) {
          this.$swal.fire(
            "Warning!",
            "A Negative Cashflow Check is required for the selected creditor filter criteria.",
            "warning"
          );
          return;
        }
        if (!this.selectedRow.Data.IsCheckforNegativeCashflow) {
          this.$swal.fire(
            "Warning!",
            "A Negative Cashflow Check is required for the selected creditor filter criteria.",
            "warning"
          );
          return;
        }
      }
    },
    async getCreditorStatus() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_CREDITOR_STATUS_FOR_NTAM
      );

      if (result) {
        this.creditorStatusList = result.Data ? deepCopy(result.Data) : [];
      }
    },
     async getCreditorAccountTypes() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_CREDITOR_ACCOUNT_TYPES_FOR_NTA
      );

      if (result) {
        this.creditorAccountTypes = result.Data ? deepCopy(result.Data) : [];
      }
    },
    scrubCreditorChange() {
      if (this.selectedRow && this.selectedRow.Data) {
        this.selectedRow.Data.SelectedCreditors =
          this.selectedRow.Data.SelectedCreditors.filter(
            (x) => x.IsScrub != this.selectedRow.Data.ExcludeScrubCreditors
          );
      }
    },
    handlePriorityInput() {
      if (this.selectedRow.Data.Priority == 0) {
        this.selectedRow.Data.Priority = 1;
      }
    },
    keyChange(event) {
      if (!this.allowedKeyCodes.includes(event.keyCode)) {
        event.preventDefault();
      }
    },
    async getSettings() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_NEGOTIATION_AUTOMATION_SETTINGS
      );

      if (result) {
        this.settingsList = result.Data ? deepCopy(result.Data) : [];
        for (let item of this.settingsList) {
          let parsedData =
            typeof item.Data != "undefined" && item.Data
              ? JSON.parse(item.Data)
              : null;
          if (parsedData) {
            item.Data = parsedData;
            if (item.Data && item.Data.CustomerFiltering) {
              if (item.Data.CustomerFiltering == 2) {
                item.Data.CustomerFiltering = null;
              } else if (item.Data.CustomerFiltering == 1) {
                item.Data.CustomerFiltering = [
                  { Id: 1, Name: "Customer without Negotiator Task" },
                ];
              } else if (item.Data.CustomerFiltering == 3) {
                item.Data.CustomerFiltering = [
                  { Id: 3, Name: "Customer without Summons" },
                ];
              } else if (Array.isArray(item.Data.CustomerFiltering)) {
                item.Data.CustomerFiltering = item.Data.CustomerFiltering.map(
                  (id) => {
                    let match = this.customerSettingsFilterCriteria.find(
                      (x) => x.Id == id
                    );
                    return { Id: id, Name: match.Name };
                  }
                );
              }
            }
            if (
              item.Data &&
              item.Data.CreditorStatusIds &&
              Array.isArray(item.Data.CreditorStatusIds)
            ) {
              item.Data.CreditorStatusIds = item.Data.CreditorStatusIds.map(
                (id) => {
                  return { Id: id, Name: this.getCreditorStatusName(id) };
                }
              );
            }
            if (
              item.Data &&
              item.Data.CreditorAccountTypeIds &&
              Array.isArray(item.Data.CreditorAccountTypeIds)
            ) {
              item.Data.CreditorAccountTypeIds = item.Data.CreditorAccountTypeIds.map(
                (id) => {
                  return { Id: id, Name: this.getCreditorAccountTypeName(id) };
                }
              );
            }
          }
        }
      }
    },
    getCreditorStatusName(id) {
      let rtn = "";
      if (this.filteredCreditorStatusList.length > 0) {
        let indx = this.filteredCreditorStatusList.findIndex((x) => x.Id == id);
        if (indx > -1) {
          rtn = this.filteredCreditorStatusList[indx].Name;
        }
      }
      return rtn;
    },
    getCreditorAccountTypeName(id) {
      let rtn = "";
      if (this.creditorAccountTypes.length > 0) {
        let indx = this.creditorAccountTypes.findIndex((x) => x.Id == id);
        if (indx > -1) {
          rtn = this.creditorAccountTypes[indx].Name;
        }
      }
      return rtn;
    },
    async getCreditorNames() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_CREDITOR_NAMES_FOR_NEGOTIATION_AUTOMATION
      );
      if (result && result.Data && result.Data.length > 0) {
        this.creditorList = result.Data.filter((x) => x.IsDeleted == false).map(
          (x) => {
            return {
              Id: x.Id,
              Name: x.CreditorName,
              IsScrub: x.IsScrub,
            };
          }
        );
      }
    },
    async handleAddNew() {
      let changes = false;
      if (
        this.selectedRow != null &&
        this.oldValue != "" &&
        JSON.stringify(this.selectedRow) != this.oldValue
      ) {
        {
          changes = true;
          await this.$swal
            .fire({
              title: "You have unsaved changes",
              text: "You will lose it if you don't save",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, continue without saving!",
            })
            .then(async (dialog) => {
              if (dialog.value) {
                changes = false;
              } else {
                changes = true;
              }
            });
        }
      }

      if (!changes) {
        this.selectedRow.Id = init.Id;
        this.selectedRow.Name = init.Name;
        this.selectedRow.Data = deepCopy(init.Data);
        this.activeItem = null;
        this.oldValue = JSON.stringify(this.selectedRow);
        this.isEditing = true;
      }
    },
    async handleSelection(item) {
      let changes = false;
      if (
        this.selectedRow != null &&
        this.oldValue != "" &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        {
          changes = true;
          await this.$swal
            .fire({
              title: "You have unsaved changes",
              text: "You will lose it if you don't save",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, continue without saving!",
            })
            .then(async (dialog) => {
              if (dialog.value) {
                changes = false;
              } else {
                changes = true;
              }
            });
        }
      }

      if (!changes) {
        this.activeItem = item.Id;
        this.selectedRow.Id = item.Id;
        this.selectedRow.Name = item.Name;
        this.selectedRow.Data = deepCopy(item.Data);
        this.selectedRow.Data.OriginalBalanceThreshold =
          parseFloat(this.selectedRow.Data.OriginalBalanceThreshold) <= 1
            ? this.selectedRow.Data.OriginalBalanceThreshold * 100
            : this.selectedRow.Data.OriginalBalanceThreshold;
        this.oldValue = JSON.stringify(this.selectedRow);

        this.isEditing = true;
      }
    },
    async handleSave() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
      } else {
        if (
          this.selectedRow.Data.CreditorFiltering == 3 &&
          this.selectedRow.Data.SelectedCreditors.length == 0
        ) {
          this.$swal.fire("Warning!", "Please select a creditor!", "warning");
          return;
        }

        if (
          this.selectedRow.Data.CreditorFiltering == 4 ||
          this.selectedRow.Data.CreditorFiltering == 5
        ) {
          if (!this.selectedRow.Data.IsCreditorRulesIncluded) {
            this.$swal.fire(
              "Warning!",
              "A Negative Cashflow Check is required for the selected creditor filter criteria.",
              "warning"
            );
            return;
          }
          if (!this.selectedRow.Data.IsCheckforNegativeCashflow) {
            this.$swal.fire(
              "Warning!",
              "A Negative Cashflow Check is required for the selected creditor filter criteria.",
              "warning"
            );
            return;
          }
        }

        this.saving = true;
        let data = deepCopy(this.selectedRow.Data);
        if (data.CustomerFiltering && data.CustomerFiltering.length > 0) {
          data.CustomerFiltering = data.CustomerFiltering.map((x) => x.Id);
        }

        if (data.CreditorStatusIds && data.CreditorStatusIds.length > 0) {
          data.CreditorStatusIds = data.CreditorStatusIds.map((x) => x.Id);
        }

        
        if (data.CreditorAccountTypeIds && data.CreditorAccountTypeIds.length > 0) {
          data.CreditorAccountTypeIds = data.CreditorAccountTypeIds.map((x) => x.Id);
        }

        data.Priority = parseInt(data.Priority);
        data.MinGlobalBalance = parseInt(data.MinGlobalBalance);
        data.MinDaysDelinquent = parseInt(data.MinDaysDelinquent);
        data.OriginalBalanceThreshold = parseFloat(
          data.OriginalBalanceThreshold * 0.01
        );
        let serializedData = JSON.stringify(data);

        if (this.selectedRow.Id == 0) {
          //insert
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.INSERT_NEGOTIATON_AUTOMATION_SETTING,
            {
              Name: this.selectedRow.Name,
              Data: serializedData,
            }
          );
          if (result) {
            this.$swal("Success!", result.Message, "success");
            if (result.Data) {
              this.settingsList.push({
                Id: result.Data.Id,
                Name: result.Data.Name,
                Data: result.Data.Data ? JSON.parse(result.Data.Data) : null,
              });
              this.activeItem = result.Data.Id;
              this.selectedRow.Id = result.Data.Id;
              this.selectedRow.Name = result.Data.Name;
              this.oldValue = JSON.stringify(this.selectedRow);
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
          this.saving = false;
        } else {
          //update

          let err, result;
          [err, result] = await this.$store.dispatch(
            types.UPDATE_NEGOTIATON_AUTOMATION_SETTING,
            {
              Id: this.selectedRow.Id,
              Name: this.selectedRow.Name,
              Data: serializedData,
            }
          );
          if (result) {
            this.$swal("Success!", result.Message, "success");
            this.oldValue = JSON.stringify(this.selectedRow);

            let indx = this.settingsList.findIndex(
              (x) => x.Id == this.selectedRow.Id
            );

            if (indx >= 0) {
              this.settingsList[indx].Name = this.selectedRow.Name;
              this.settingsList[indx].Data = this.selectedRow.Data;
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
          this.saving = false;
        }
      }
    },
  },

  validations() {
    return {
      selectedRow: {
        Name: { required },
      },
    };
  },
};
</script>

<style scoped>
.has-error {
  border: 2px solid red !important;
}
</style>
