<template>
  <div
    class="col-md-12 newPanelWrapper noMargin dcp-mediaScreen"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 300px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between">
      <label>Get Full Card Numbers</label>
      <i
        class="fas fa-window-close closeButtonBulk"
        v-on:click.prevent.stop="closePanel"
      ></i>
    </div>

    <div v-if="!isRequestDone">
      <div class="col-md-12 row noPadding mt-2">
        <perfect-scrollbar
          style="
            width: 100%;
            height: auto;
            max-height: 450px;
            overflow-x: hidden !important;
            margin-top: 0;
          "
        >
          <section class="datagrid-table" style="border-radius: 0 !important">
            <o-table
              v-if="logData && logData.length > 0"
              :data="logData"
              :default-sort-direction="'asc'"
              :striped="true"
              :hoverable="true"
            >
              <o-table-column
                field="RequestedBy"
                label="Request By"
                width="40%"
                sortable
                v-slot="props"
                >{{ props.row.RequestedBy }}</o-table-column
              >
              <o-table-column
                field="AuthDate"
                label="Log Date"
                width="30%"
                sortable
                v-slot="props"
                >{{ formatGridDate(props.row.AuthDate) }}</o-table-column
              >
              <o-table-column
                field="Status"
                label="Status"
                width="40%"
                sortable
                v-slot="props"
                >{{ props.row.Status }}</o-table-column
              >
            </o-table>
            <section
              v-if="logData == null || (logData && logData.length == 0)"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4 mt-3">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </section>
        </perfect-scrollbar>
      </div>

      <div class="col-md-12" style="display: flex; justify-content: center">
        <button @click="requestInstaTouch()" class="btn btn-success mt-2">
          Request
        </button>
      </div>
    </div>
    <div class="row" v-if="isRequestDone">
      <div class="col-md-4">
        <label class="generalLabel">First Name</label>
        <input
          type="text"
          class="form-control"
          autocomplete="none"
          placeholder="First Name"
          v-model="customerInfo.FirstName"
          :class="v$.customerInfo.FirstName.$error ? 'has-error' : ''"
          @blur="v$.customerInfo.FirstName.$touch"
        />
      </div>

      <div class="col-md-4">
        <label class="generalLabel">Last Name</label>
        <input
          type="text"
          class="form-control"
          autocomplete="none"
          placeholder="Last Name"
          v-model="customerInfo.LastName"
          :class="v$.customerInfo.LastName.$error ? 'has-error' : ''"
          @blur="v$.customerInfo.LastName.$touch"
        />
      </div>
      <div class="col-md-4">
        <label class="generalLabel">Date of Birth</label>
        <DatePicker
          mode="date"
          :locale="'en-US'"
          v-model="customerInfo.DateOfBirth"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="form-control"
              :value="inputValue"
              v-on="inputEvents"
              placeholder="Date of Birth"
              :class="v$.customerInfo.DateOfBirth.$error ? 'has-error' : ''"
              @blur="v$.customerInfo.DateOfBirth.$touch"
            />
          </template>
        </DatePicker>
      </div>
      <div class="col-md-4">
        <label class="generalLabel">SSN</label>
        <input
          type="text"
          class="form-control"
          autocomplete="none"
          placeholder="SSN"
          v-myMask="{ mask: ['999-99-9999'] }"
          v-model="customerInfo.Ssn"
          :class="v$.customerInfo.Ssn.$error ? 'has-error' : ''"
          @blur="v$.customerInfo.Ssn.$touch"
        />
      </div>

      <div class="col-md-4">
        <label class="generalLabel">Address</label>
        <input
          type="text"
          class="form-control"
          autocomplete="none"
          placeholder="Line1"
          v-model="customerInfo.Address"
          :class="v$.customerInfo.Address.$error ? 'has-error' : ''"
          @blur="v$.customerInfo.Address.$touch"
        />
      </div>

      <div class="col-md-4">
        <label class="generalLabel">Zip Code</label>
        <input
          type="text"
          class="form-control"
          autocomplete="none"
          v-myMask="{ mask: ['99999'] }"
          placeholder="Zip Code"
          v-model="customerInfo.Zip"
          :class="v$.customerInfo.Zip.$error ? 'has-error' : ''"
          @blur="v$.customerInfo.Zip.$touch"
        />
      </div>
      <div class="col-md-4">
        <label class="generalLabel">City</label>
        <input
          type="text"
          class="form-control"
          autocomplete="none"
          placeholder="City"
          v-model="customerInfo.City"
          :class="v$.customerInfo.City.$error ? 'has-error' : ''"
          @blur="v$.customerInfo.City.$touch"
        />
      </div>
      <div class="col-md-4">
        <label class="generalLabel">State</label>
        <select v-model="customerInfo.StateId" id="State" class="form-control">
          <option :value="null">Please select</option>
          <option v-for="item in states" :key="item.Id" :value="item.Id">
            {{ item.Name }}
          </option>
        </select>
      </div>
      <div class="col-md-12 mt-2" style="display: flex">
        <input
          v-model="userConsent"
          type="checkbox"
          class="checkbox-input generalCheckBox"
          style="
            border-radius: 4px !important;
            border: solid 1px #d1d1d1 !important;
          "
        />
        <label class="generalLabel ml-1"
          >I have received consumer consent and authorization to access full
          credit card numbers for the purposes of locating accounts for debt
          settlement.</label
        >
      </div>

      <div class="col-md-12 text-center">
        <button
          @click="sendRequest()"
          :disabled="v$.$invalid || !userConsent"
          type="button"
          class="btn btn-success mt-2"
        >
          Send Request
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { DatePicker } from "v-calendar";

export default {
  name: "InstaTouchPopup",
  components: { DatePicker },
  mixins: [formatMixin, utilitiesMixin],
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    customerSsn: {
      type: String,
      default: () => "",
    },
    customerFirstName: {
      type: String,
      default: () => "",
    },
    customerLastName: {
      type: String,
      default: () => "",
    },
    customerAddressLine1: {
      type: String,
      default: () => "",
    },
    customerAddressLine2: {
      type: String,
      default: () => "",
    },
    customerCity: {
      type: String,
      default: () => "",
    },
    customerStateId: {
      type: Number,
      default: () => 0,
    },
    customerZip: {
      type: String,
      default: () => "",
    },
    customerDob: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      userConsent: false,
      v$: useValidate(),
      isLoading: false,
      isRequestDone: false,
      logData: [],
      customerInfo: {
        Id: 0,
        FirstName: "",
        LastName: "",
        DateOfBirth: "",
        Ssn: "",
        Address: "",
        Zip: "",
        City: "",
        StateId: 0,
      },
    };
  },
  unmounted() {},
  async mounted() {
    await this.$store.dispatch(globalTypes.GET_STATES);
    //Load Customer Info
    let ssnOpen = await this.AES256_GCM_decrypt(this.customerSsn);
    let cstAddr = this.customerAddressLine1;
    if (this.customerAddressLine2 && this.customerAddressLine2 != "") {
      cstAddr = cstAddr + " " + this.customerAddressLine2;
    }
    this.customerInfo.Id = this.customerId;
    this.customerInfo.FirstName = this.customerFirstName;
    this.customerInfo.LastName = this.customerLastName;
    this.customerInfo.DateOfBirth = this.customerDob;
    this.customerInfo.Ssn = ssnOpen;
    this.customerInfo.Address = cstAddr;
    this.customerInfo.Zip = this.customerZip;
    this.customerInfo.City = this.customerCity;
    this.customerInfo.StateId = this.customerStateId;

    //GET LOGS
    let err, result;
    [err, result] = await this.$store.dispatch(types.GET_INSTATOUCH_LOGS, {
      CustomerId: this.customerId,
    });
    if (result && result.Data) {
      this.logData = result.Data;
    }
  },
  computed: mapState({
    states: (state) => state.globals.states,
  }),
  methods: {
    requestInstaTouch() {
      this.isRequestDone = true;
    },
    async sendRequest() {
      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(
        types.REQUEST_INSTATOUCH_CARDS,
        {
          Ssn: this.customerInfo.Ssn,
          FirstName: this.customerInfo.FirstName,
          LastName: this.customerInfo.LastName,
          StreetAddress: this.customerInfo.Address,
          City: this.customerInfo.City,
          ZipCode: this.customerInfo.Zip,
          Dob: this.customerInfo.DateOfBirth,
          State: this.customerInfo.StateId,
          CustomerId: this.customerId,
        }
      );
      if (result) {
        if (result.Data && result.Data.length > 0) {
          this.$emit("updateCardNumberList", result.Data);
          this.$swal("Success", result.Message, "success");
        } else {
          this.$swal("Warning", "No Cards Available!", "warning");
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isLoading = true;
      this.closePanel();
    },
    closePanel() {
      this.$emit("closeInstaTouchPopup");
    },
  },
  validations() {
    return {
      customerInfo: {
        FirstName: { required },
        LastName: { required },
        Ssn: { required },
        DateOfBirth: { required },
        City: { required },
        Address: { required },
        Zip: { required },
      },
    };
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
</style>
