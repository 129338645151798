import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
    state: {},
    actions: {

        GET_DOCUMENT_TYPES: async ({ commit }) => {
            let err, result;
            [err, result] = await to(api.getAllDocumentTypes());
            return [err, result];
        },

        GET_DOCUMENT_TYPE_STATUS: async ({ commit }) => {
            let err, result;
            [err, result] = await to(api.getAllDocumentTypesStatuses());
            return [err, result];
        },        

        INSERT_DOCUMENT_TYPE: async ({ commit }, payload) => {
            let err, result;
            [err, result] = await to(api.insertDocumentType(payload));
            return [err, result];
        },

    },
    mutations: {},
};
