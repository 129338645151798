<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <button
      class="btn-close"
      @click.prevent.stop="closePanel"
      :class="{ 'btn-active': !isLoading }"
      :disabled="isLoading"
    >
      <i class="fas fa-window-close"></i>
    </button>
    <div class="form-group row">
      <div class="col-md-12 mt-3">
        <label class="generalLabel" for="creditorsList"
          >Select Creditors to Include in the Summons & Complaints</label
        >
        <perfect-scrollbar>
          <DataTable
            v-if="availableCreditors.length"
            v-model:selection="selectedCreditors"
            v-model:expandedRows="expandedRows"
            :value="availableCreditors"
            dataKey="Id"
            tableStyle="min-width: auto"
            :loading="isLoading"
          >
            <Column expander style="width: 5rem"></Column>
            <Column field="Name" header="Creditor name"></Column>
            <Column field="AccountNumber" header="Account Number"></Column>
            <Column field="CurrentBalance" header="Curr. Credit Balance">
              <template #body="props">
                {{ formatMoney(props.data.CurrentBalance) }}
              </template>
            </Column>
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <template #expansion="slotProps">
              <div class="creditor-info">
                <div class="info-item">
                  <span class="info-header">Original balance:</span>
                  <span class="info-value">{{
                    formatMoney(slotProps.data.OriginalBalance)
                  }}</span>
                </div>
                <div class="info-item">
                  <span class="info-header">Current balance:</span>
                  <span class="info-value">{{
                    formatMoney(slotProps.data.CurrentBalance)
                  }}</span>
                </div>
                <div class="info-item">
                  <span class="info-header">Date added:</span>
                  <span class="info-value">{{
                    new Date(slotProps.data.DateAdded).toLocaleDateString(
                      "en-US"
                    )
                  }}</span>
                </div>
                <div class="info-item">
                  <span class="info-header">Account holder:</span>
                  <span class="info-value">{{
                    slotProps.data.CreditorAccountHolder?.Name
                  }}</span>
                </div>
              </div>
            </template>
          </DataTable>
          <Tag
            v-else
            severity="danger"
            value="To upload SUMMON AND COMPLAINT document, there must be available creditors "
          ></Tag>
        </perfect-scrollbar>
      </div>
      <div class="col-md-12 d-flex justify-content-end">
        <button
          class="btn btn-success form-control"
          id="Upload"
          :disabled="isLoading || selectedCreditors.length === 0"
          @click="uploadToBlp(documentName)"
        >
          Upload to BLP
          <i v-if="isLoading" class="spinner-border spinner-border-sm mr-1"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import customerApi from "@/services/client/customerApi";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Tag from "primevue/tag";

export default {
  name: "BLPUploadPopup",
  mixins: [utilitiesMixin, formatMixin],
  components: { DataTable, Column, Tag },
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    customerOfficeId: {
      type: Number,
      default: () => 0,
    },
    documentName: {
      type: String,
      default: () => "",
    },
    creditors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    availableCreditors() {
      return this.creditors.filter(
        (creditor) =>
          creditor.CreditorStatusId != 18 && creditor.CreditorStatusId != 4
      );
    },
  },
  data() {
    return {
      isLoading: false,
      selectedCreditors: [],
      expandedRows: {},
    };
  },
  methods: {
    closePanel() {
      this.$emit("closeBLPUploadPopup");
    },
    async uploadToBlp(documentName) {
      try {
        this.isLoading = true;

        if (!documentName) {
          this.$swal("Error!", "The document name cannot be empty", "error");
          return;
        }
        if (this.selectedCreditors.length === 0) {
          this.$swal(
            "Error!",
            "You must select at least one creditor.",
            "error"
          );
          return;
        }

        const CreditorsIds = this.selectedCreditors.map(
          (creditor) => creditor.Id
        );

        const uploadData = {
          CustomerId: this.customerId,
          OfficeId: this.customerOfficeId,
          DocumentName: this.getFileNameWithoutExt(documentName),
          Extension: this.getExtension(documentName),
          CreditorsIds,
          DoctypeName: "Summons and Complaint",
        };
        const result = await customerApi.uploadDocumentToBLP(uploadData);
        this.closePanel();
        this.$swal("Success!", result.Message, "success");
      } catch (error) {
        console.error("Error in upload:", error);
        let errMsg = this.getApiErrorMessage(error);
        this.$swal("Error!", errMsg, "error");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-close {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
  border: none;
  background: transparent;
}

.btn-active:hover {
  color: #ff3860;
}

.creditor-info {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.info-item {
  margin-bottom: 10px;
}

.info-header {
  font-weight: bold;
  margin-right: 10px;
}

.ps {
  max-height: 600px;
}
</style>
