export default {
  GET_CLIENT_CREDITORS_PAYMENT_TYPES:"GET_CLIENT_CREDITORS_PAYMENT_TYPES",
  GET_CREDITORS: "GET_CREDITORS",
  UPDATE_CREDITOR: "UPDATE_CREDITOR",
  INSERT_CREDITOR: "INSERT_CREDITOR",
  DELETE_CREDITOR: "DELETE_CREDITOR",
  GET_CREDITORSTATUSES: "GET_CREDITORSTATUSES",
  SET_CREDITORSTATUSES: "SET_CREDITORSTATUSES",
  GET_ACCOUNTTYPES: "GET_ACCOUNTTYPES",
  SET_ACCOUNTTYPES: "SET_ACCOUNTTYPES",
  GET_ACCOUNTHOLDERS: "GET_ACCOUNTHOLDERS",
  SET_ACCOUNTHOLDERS: "SET_ACCOUNTHOLDERS",
  SETTLE_CREDITOR: "SETTLE_CREDITOR",
  VOID_CREDITOR_SETTLEMENT: "VOID_CREDITOR_SETTLEMENT",
  UPDATE_CREDITOR_NOTE: "UPDATE_CREDITOR_NOTE",
  GET_SETTLEMENT_DETAILS: "GET_SETTLEMENT_DETAILS",
  GET_CREDITOR_INFO:"GET_CREDITOR_INFO",
  GET_CREDITOR_SETTINGS_BY_NAME:"GET_CREDITOR_SETTINGS_BY_NAME",
  GET_INSTATOUCH_API_SETTING:"GET_INSTATOUCH_API_SETTING",
  REQUEST_INSTATOUCH_CARDS:"REQUEST_INSTATOUCH_CARDS",
  GET_INSTATOUCH_CARD_NUMBERS:"GET_INSTATOUCH_CARD_NUMBERS",
  GET_INSTATOUCH_LOGS:"GET_INSTATOUCH_LOGS",
};
