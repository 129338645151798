import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
import customerApi from "@/services/client/customerApi";

export default {
  state: {},
  actions: {
    GET_CREDITOR_ACCOUNT_TYPES_FOR_NTA: async ({ commit }) => {
      let err, result;
      [err, result] = await to(customerApi.getCreditorAccountTypes());
      return [err, result];
    },
    GET_CREDITOR_STATUS_FOR_NTAM: async ({ commit }) => {
      let err, result;
      [err, result] = await to(customerApi.getCreditorStatus());
      return [err, result];
    },

    GET_NEGOTIATION_AUTOMATION_SETTINGS: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getNegotiationAutomationSetting());
      return [err, result];
    },
    INSERT_NEGOTIATON_AUTOMATION_SETTING: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.insertNegotiationAutomationSetting(payload));
      return [err, result];
    },
    UPDATE_NEGOTIATON_AUTOMATION_SETTING: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateNegotiationAutomationSetting(payload));
      return [err, result];
    },
    DELETE_NEGOTIATON_AUTOMATION_SETTING: async ({ commit }, payload) => {
      let err, result;
      //[err, result] = await to(api.deleteCompanyGlobalSettings(payload));
      return [err, result];
    },
    GET_CREDITOR_NAMES_FOR_NEGOTIATION_AUTOMATION: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getCreditorSettings());
      return [err, result];
    },
  },
  mutations: {},
};
