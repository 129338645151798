<template>
  <th>{{ row.FileName }}</th>
  <th>
    <button
      class="fe-upload-cloud btn upload-file__table-button"
      @click="openUploadFileModal"
    />
  </th>
  <th>
    <template v-if="row.file">
      <span>
        {{ row.file.name }} (Size: {{ row.file.size }} bytes)
      </span>
    </template>
  </th>
  <UploadFileModal
    ref="uploadFileModal"
    :file-name="row.FileName"
    @file-uploaded="handleFileUpload"
  >
    <h5 class="upload-file-modal__title">
      Expected column names
    </h5>
    <div class="mx-3">
      <ul>
        <li
          v-for="column of row.Columns"
          :key="column.Id"
        >
          {{ column.HeaderName }}
        </li>
      </ul>
    </div>
  </UploadFileModal>
</template>
<script>
import UploadFileModal from './UploadFileModal.vue';

export default {
  name: "FileUploadTableRow",
  components: {
    UploadFileModal
  },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  emits: ["fileUploaded"],
  methods: {
    handleFileUpload(file) {
      this.checkFile(file);
    },
    openUploadFileModal() {
      const modal = this.$refs.uploadFileModal;

      if (modal) {
        modal.showModal();
      }
    },
    closeUploadFileModal() {
      const modal = this.$refs.uploadFileModal;

      if (modal) {
        modal.hideModal();
      }
    },
    resetUploadFileModalValue() {
      const modal = this.$refs.uploadFileModal;

      if (modal) {
        modal.resetValue();
      }
    },
    async checkFile(file) {
      try {
        const validCSV = this.isCSVFile(file);
        const emptyCSV = await this.isCSVEmpty(file);

        if (!validCSV) {
          this.$swal("Error!", "Please drop a valid CSV file.", "error");
          this.resetUploadFileModalValue();
        } else if (emptyCSV) {
          this.$swal("Error!", "The CSV is empty", "error");
          this.resetUploadFileModalValue();
        } else {
          this.$emit('fileUploaded', file);
          this.closeUploadFileModal();
        }
      } catch (error) {
        this.$swal("Error!", "The CSV couldn't be read", "error");
      }
    },
    isCSVEmpty(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const content = e.target.result;
          const rows = content.split('\n').map(row => row.trim()).filter(row => row.length > 0);

          if (rows.length === 0 || (rows.length === 1 && rows[0].length === 0)) {
            resolve(true);
          } else {
            const columns = rows[0].split(',');
            if (columns.length === 0 || (columns.length === 1 && columns[0].length === 0)) {
              resolve(true);
            } else {
              resolve(false);
            }
          }
        };

        reader.onerror = () => {
          reject('There was an error while reading the file')
        };

        reader.readAsText(file);
      });
    },
    isCSVFile(file) {
      const mimeType = file.type;
      const fileExtension = file.name?.split('.').pop().toLowerCase();
      return mimeType === 'text/csv' || fileExtension === 'csv';
    },
  }
}
</script>
<style lang="css" scoped>
.upload-file__table-button {
  font-size: 1.3rem;
}

.upload-file-modal__title {
  font-weight: bold;
}
</style>