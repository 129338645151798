<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'AgentActivityReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
      @selectedAgentChange="selectedAgentChange"
    />
 
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredAgentActivityReport"
        :paginated="isPaginated && filteredAgentActivityReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
        detailed
        detail-key="CustomerId"
      >
        <o-table-column
          field="CustomerId"
          label="Customer ID"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(
                  props.row.CustomerId,
                  props.row.FirstName + ' ' + props.row.LastName
                )
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId &&
                    props.row.FirstName &&
                    props.row.LastName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FirstName"
          label="First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.FirstName }}
          </template>
        </o-table-column>

        <o-table-column field="LastName" label="Last Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.LastName }}
          </template>
        </o-table-column>

        <o-table-column
          field="StatusName"
          label="Customer Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StatusNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.StatusName }}
          </template>
        </o-table-column>

        <o-table-column field="Event" label="Event" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EventFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <span v-if="props.row.Details && props.row.Details.length > 0">
              {{ props.row.Details[0].Event }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="LogDate" label="Log Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LogDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <span v-if="props.row.Details && props.row.Details.length > 0">
              {{ formatGridDate(props.row.Details[0].LogDate) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="Logs"
          position="centered"
          v-slot="props"
        >
          <button
            @click="openLogsPopup(props.row.CustomerId)"
            class="btn btn-primary waves-effect waves-light"
            v-if="!props.row.IsSummary"
          >
            <i class="fas fa-file-invoice"></i>
          </button>
        </o-table-column>

        <o-table-column
          field=""
          label="Notes"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewNotes(props.row.CustomerId)"
            class="btn btn-warning waves-effect waves-light"
            v-if="!props.row.IsSummary"
          >
            <i class="fas fa-file-alt"></i>
          </button>
        </o-table-column>

        <o-table-column field="" label="Dispositions" v-slot="props">
          <div class="col-md-12">
            <button
              v-if="!props.row.IsSummary"
              type="button"
              title="Dispositions"
              class="btn btn-link waves-effect"
              style="background-color: #8a9197"
              v-on:click.prevent.stop="
                () =>
                  openDispositionPopup(props.row.CustomerId, props.row.StatusId)
              "
              @mouseenter="last5DispositionHover(props.row.CustomerId, $event)"
              @mouseleave="last5Leave()"
            >
              <i class="ri-sound-module-fill"></i>
            </button>
          </div>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.CustomerId,
                props.row.FirstName + props.row.LastName
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
        <template v-slot:detail="props">
          <div
            v-if="props.row.Details"
            class="detailsDiv"
            v-html="createDetailsTable(props.row.Details)"
          ></div>
          <div v-if="!props.row.Details" class="detailsDiv">
            {{ noAvailableMessage }}
          </div>
        </template>
      </o-table>
      <section
        v-if="filteredAgentActivityReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
    <div v-if="dispositionCustomerId > 0" class="backgroundLock">
      <div
        style="position: relative; margin: 10% auto"
        class="col-xl-5 col-lg-8 col-md-12 col-sm-12"
      >
        <div class="reminderClose" @click="closeDispositionPopup()">
          <i class="fas fa-window-close"></i>
        </div>
        <DispositionPanel
          :customerId="dispositionCustomerId"
          :statusId="dispositionStatusId"
          :fromEdit="0"
        >
        </DispositionPanel>
      </div>
    </div>
    <div
      v-if="hoveredDispositionId > 0"
      class="hoverPanel"
      :style="
        last5DispoHoverPanelPosition +
        ' height:' +
        (last5DispoHoverPanelHeight + 2) +
        'px;'
      "
    >
      <Last5Dispositions
        :panelHeight="last5DispoHoverPanelHeight"
        :customerId="hoveredDispositionId"
      >
      </Last5Dispositions>
    </div>
    <div v-if="isClientLogsPopup" class="backgroundLock">
      <div
        style="position: relative; margin: 10% auto"
        class="col-xl-5 col-lg-8 col-md-12 col-sm-12"
      >
        <div class="reminderClose" @click="closeLogsPopup()">
          <i class="fas fa-window-close"></i>
        </div>
        <ClientLogs
          :customerId="logsCustomerId"
          :agentActivityReportLogs="true"
        ></ClientLogs>
      </div>
    </div>
    <div v-if="isAgentLoginLogOpen" class="backgroundLock">
      <div
        style="position: relative; margin: 10% auto"
        class="col-xl-3 col-lg-6 col-md-12 col-sm-12"
      >
        <AgentLoginLogPopup
          :loginLogs="loginLogs"
          :agentName="selectedAgentName"
          @closeAgentLoginLogPopup="closeAgentLoginLogPopup"
        ></AgentLoginLogPopup>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import ReportSummary from "./ReportSummary.vue";
import DispositionPanel from "@/views/agent/Disposition/DispositionPanel";
import Last5Dispositions from "@/components/Last5Dispositions";
import ClientLogs from "@/views/agent/EditClient/ClientLogs";
import AgentLoginLogPopup from "@/components/AgentLoginLogPopup";
export default {
  name: "AgentActivityReport",
  components: {
    ReportSummary,
    DispositionPanel,
    Last5Dispositions,
    ClientLogs,
    AgentLoginLogPopup
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      loginLogs:[],
      isAgentLoginLogOpen:false,
      noAvailableMessage: "No details available.",
      selectedAgentId: 0,
      selectedAgentName: "",
      isClientLogsPopup: false,
      hoveredDate: new Date(),
      dispositionCustomerId: 0,
      dispositionStatusId: 0,
      windowHeight: 0,
      hoveredDispositionId: 0,
      last5DispoHoverPanelPosition: "top:45px; right:45px;",
      last5DispoHoverPanelHeight: 320,
      logsCustomerId: 0,
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      startDate: null,
      endDate: null,
      agents: null,
      recordCount: 0,
      reportLoading: false,
      reportData: [],
      userOfficeId: null,
      hasAccessOtherOffices: false,

      agentActivityReportInitSummary: {
        CustomerId: "",
        FirstName: "",
        LastName: "",
        StatusName: "",
        Event: "",
        LogDate: "",
      },
      CustomerIdFilter: "",
      FirstNameFilter: "",
      LastNameFilter: "",
      StatusNameFilter: "",
      EventFilter: "",
      LogDateFilter: "",
    };
  },
  computed: mapState({
    userActivityList: (state) => state.reports.userActivityList,
    filteredAgentActivityReport() {
      let rtn = [];

      this.loginLogs =   this.userActivityList ?   this.userActivityList.AgentLoginLogInformation : [];
      let tmpList =
      this.userActivityList && this.userActivityList.Report && this.userActivityList.Report.length > 0
          ? this.userActivityList.Report
              .filter((item) => {
                return (
                  this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                  this.filterString(item.FirstName, this.FirstNameFilter) &&
                  this.filterString(item.LastName, this.LastNameFilter) &&
                  this.filterString(item.StatusName, this.StatusNameFilter) &&
                  this.filterString(item.Details[0].Event, this.EventFilter) &&
                  new Date(item.Details[0].LogDate)
                    .toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "numeric",
                      minute: "2-digit",
                    })
                    .indexOf(this.LogDateFilter) > -1
                );
              })
              .sort((a, b) => new Date(a.LogDate) - new Date(b.LogDate))
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.agentActivityReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.agentActivityReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.agentActivityReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      userInfo != null &&
      userInfo.customerIdPrefix != null &&
      userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = userInfo.customerIdPrefix;
    }

    let agent = JSON.parse(
      sessionStorage.getItem("reportFilters-AgentActivityReport")
    );
    if (agent) {
      this.selectedAgentId = agent.Agents;
      this.selectedAgentName = agent.AgentName;
    }

    window.addEventListener("resize", this.onResize);
    this.onResize();
    await this.sleep(500);
    this.fixStickyHeaders();
  },
  beforeMount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    showLoginLogs(){
      this.isAgentLoginLogOpen=true;
    },
    closeAgentLoginLogPopup(){
      this.isAgentLoginLogOpen=false;
    },

    createDetailsTable(data) {
      let html = this.noAvailableMessage;
      if (data && Array.isArray(data) && data.length > 1) {
        // let headerNames = Object.keys(data[0]);
        let headerNames = ["", "Event", "LogDate", "Summary"];
        if (
          headerNames &&
          Array.isArray(headerNames) &&
          headerNames.length > 0
        ) {
          html =
            "<table style='position: relative; z-index: 1; width: 100%;' class='agentActivityDetailTable'>";
          html += "<thead style='background-color:#fafafa'>";
          html += "<tr>";
          for (let hItem of headerNames) {
            if (hItem == "Summary") {
              html +=
                "<th style='border: none; padding: 8px;'>" + hItem + "</th>";
            } else if (hItem == "Event") {
              html +=
                "<th style='border: none; padding: 8px; width: 195px;'>" +
                hItem +
                "</th>";
            } else if (hItem == "LogDate") {
              html +=
                "<th style='border: none; padding: 8px; width: 200px;'>" +
                hItem +
                "</th>";
            } else if (hItem == "") {
              html +=
                "<th style='border: none; padding: 8px; width: 785px;'></th>";
            }
          }
          html += "</tr>";
          html += "</thead>";

          html += "<tbody>";
          data.slice(1).forEach((item) => {
            html += "<tr>";
            headerNames.forEach((c) => {
              if (c == "") {
                html += "<td style='padding: 8px;'></td>";
              } else {
                let cellValue = this.formatDateValue(c, item[c]);
                html +=
                  "<td style='padding: 8px;'>" +
                  (cellValue !== undefined && cellValue !== null
                    ? cellValue
                    : "") +
                  "</td>";
              }
            });
            html += "</tr>";
          });
          html += "</tbody>";
          html += "</table>";
        } else {
          html += "<label class='mr-4'>There are no details.</label>";
        }
      }

      return html;
    },

    formatDateValue(key, value) {
      if (
        key &&
        key.toLowerCase().includes("date") &&
        !isNaN(Date.parse(value))
      ) {
        let date = new Date(value);
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "2-digit",
        });
      }
      return value;
    },

    selectedAgentChange(agentId, agentName) {
      this.selectedAgentId = agentId;
      this.selectedAgentName = agentName;
    },
    openLogsPopup(customerId) {
      this.logsCustomerId = customerId;
      this.isClientLogsPopup = true;
    },

    closeLogsPopup() {
      this.isClientLogsPopup = false;
    },

    viewNotes(customerId) {
      this.$Emitter.emit("viewNotePanel", {
        CustomerId: customerId,
        AgentId: this.selectedAgentId,
        AgentName: this.selectedAgentName,
      });
    },
    openDispositionPopup(customerId, statusId) {
      this.dispositionCustomerId = customerId;
      this.dispositionStatusId = statusId;
    },

    closeDispositionPopup() {
      this.dispositionCustomerId = 0;
      this.dispositionStatusId = 0;
    },

    async last5DispositionHover(itemId, event) {
      if (this.hoveredDispositionId == 0) {
        let date = new Date().getTime();

        let positioning =
          this.windowHeight - event.clientY > this.last5DispoHoverPanelHeight
            ? "top:" + event.clientY + "px; "
            : "bottom:" + (this.windowHeight - event.clientY) + "px;";

        this.hoveredDispositionId = 0;
        this.last5DispoHoverPanelPosition = positioning + " right:35vh;";
        if (parseInt(date - this.hoveredDate) > 500) {
          this.hoveredDispositionId = itemId;
          this.hoveredDate = new Date().getTime();
        }
      }
    },

    onResize() {
      this.windowHeight = window.innerHeight;
    },

    last5Leave() {
      this.hoveredDispositionId = 0;
      this.last5Dispositions = [];
    },

    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_USER_ACTIVITY_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },

    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped>
.reminderClose {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}

.hoverPanel {
  width: 480px;
  position: fixed;
  z-index: 999;
  background: #ffffff;
  border: 1px solid #3bafda;
}
</style>
