<!--
 * @file BaseModal.vue
 * @description This component is created to use a base component for creating modals.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 * 
 * @section Usage
 * This component can be used to display the base structure for a modal.
 *
 * @section Props
 * - `title` (String): The title of the modal.
 -->
<template>
  <div
    class="backgroundLock"
    style="-webkit-box-align: center; align-items: center; display: flex"
  >
    <div
      style="position: relative; margin: 2% auto"
      class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
    >
      <div
        v-click-outside="close"
        class="col-md-12 newPanelWrapper noMargin smp-mediaScreen"
      >
        <div class="header">
          <label>{{ title }}</label>
        </div>
        <div>
          <div class="closeButton" v-on:click.prevent.stop="close">
            <i class="fas fa-window-close"></i>
          </div>
        </div>

        <perfect-scrollbar>
          <slot></slot>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};
</script>
