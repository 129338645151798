<template>
    <DatePicker
        v-model="value"
        :mode="isDateTime ? 'datetime' : 'date'"
        :locale="'en-US'"
        :popover="{ visibility: 'click', placement }"
        >
        <template v-slot="{ inputValue, inputEvents }">
            <input
                autocomplete="none"
                class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                v-bind="$attrs"
                v-on="inputEvents"
            />
        </template>
    </DatePicker>
</template>

<script setup>
import { DatePicker } from "v-calendar";
import { computed } from "vue";

const props = defineProps({
    modelValue: [Date, String, Boolean],
    isDateTime: {
        type: Boolean,
        default: false
    },
    placement: {
        type: String,
        default: 'top'
    },
});

const emit = defineEmits(['update:modelValue'])

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

</script>
