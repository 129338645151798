<template>
  <div class="col-md-12">
    <h2>Data Upload Log</h2>
    <ReportMigrationSummary :reportName="'MigrationDataSettingsReport'" :recordCount="recordCount"
      :loadingSpinner="loadingSpinner" v-model:process-id="processIdFilter" v-model:template-id="templateIdFilter"
      v-model:start-date="startDateFilter" v-model:final-date="finalDateFilter" v-model:date-combo="dateComboFilter"
      @updateLoadingStatus="reportLoading = $event" @filter-report="filterReport" />

    <div class="datagrid-table data-upload-log__table report-wrapper">
      <o-table :data="dataMigrationReportList" :per-page="200" :paginated="true" :pagination-simple="false"
        pagination-position="both" sticky-header>
        <o-table-column field="ProcessId" label="Process Id" sortable>
          <template v-slot="props">
            {{ props.row.ProcessId }}
          </template>
        </o-table-column>

        <o-table-column field="PipelineStatus" label="Process Status" sortable>
          <template v-slot="props">
            {{ getStatusMessage(props.row.PipelineStatus) }}
          </template>
        </o-table-column>

        <o-table-column field="OfficeName" label="Office" sortable>
          <template v-slot="props">
            {{ props.row.OfficeName }}
          </template>
        </o-table-column>

        <o-table-column field="FileName" label="Filename" sortable>
          <template v-slot="props">
            {{ props.row.FileName }}
          </template>
        </o-table-column>

        <o-table-column field="TemplateName" label="Template Name" sortable>
          <template v-slot="props">
            {{ props.row.TemplateName }}
          </template>
        </o-table-column>

        <o-table-column field="UploadDate" label="Finish Date" sortable>
          <template v-slot="props">
            {{ formatShortDate(props.row.FinishDate) }}
          </template>
        </o-table-column>

        <o-table-column label="Actions" position="centered">
          <template v-slot="props">
            <p v-if="!isLoadingRecordReport && props.row.PipelineStatus === 'Success'" class="viewRecordDetail"
              @click="viewReportDetail(props.row.FileName)">
              View Details
            </p>
          </template>
        </o-table-column>
      </o-table>
    </div>
  </div>
</template>
<script>
import types from "../types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import ReportMigrationSummary from "./ReportMigrationSummary.vue";

export default {
  name: "ReportDataSettings",
  components: {
    ReportMigrationSummary,
  },
  mixins: [formatMixin, utilitiesMixin],
  data: () => ({
    processIdFilter: "",
    templateIdFilter: 0,
    startDateFilter: null,
    finalDateFilter: null,
    loadingSpinner: false,
    isLoadingRecordReport: false,
    dateComboFilter: "today",
  }),
  methods: {
    getStatusMessage(data) {
      const status = data.trim().replace(/'/g, "").toLowerCase();
      switch (status) {
        case 'inprogress':
          return 'awaiting';
        case 'queued':
          return 'failed';
        case 'failed':
          return 'failed';
        case 'success':
          return 'completed';
        default:
          return '';
      }
    },
    checkDateFields() {
      if (this.dateComboFilter == "specificDate") {
        if (this.startDateFilter == null) {
          this.$swal("Warning!", "Date fields cannot be left empty.", "warning");
          return false;
        }

        return true;
      }

      if (this.dateComboFilter == "customDateRange") {
        if (!this.startDateFilter || !this.finalDateFilter) {
          this.$swal("Warning!", "Date fields cannot be left empty.", "warning");
          return false;
        }

        if (this.startDateFilter >= this.finalDateFilter) {
          this.startDateFilter = new Date(
            this.finalDateFilter.getFullYear(),
            this.finalDateFilter.getMonth(),
            this.finalDateFilter.getDate(),
            0,
            0,
            0
          );
          this.startDateFilter = this.startDateFilter.addDays(-1);
        }
      }
      return true;
    },
    async filterReport() {
      let err, result;
      if (!this.checkDateFields()) return;

      const payload = {
        StartDate:
          this.dateComboFilter == "specificDate" && this.startDateFilter
            ? new Date(this.startDateFilter).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : this.dateComboFilter == "customDateRange" && this.startDateFilter
            ? new Date(this.startDateFilter).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : null,
        EndDate:
          this.dateComboFilter == "customDateRange" && this.finalDateFilter
            ? new Date(this.finalDateFilter).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : null,
        ProcessId: this.processIdFilter || null,
        TemplateId: Number(this.templateIdFilter) || 0,
        Option: this.dateComboFilter?.toLowerCase() || "",
      };

      try {
        this.loadingSpinner = true;
        [err, result] = await this.$store.dispatch(types.GET_DATA_UPLOAD, payload);
        if (err) {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } catch (error) {
        this.$swal("Error!", "Error!", "error");
      } finally {
        this.loadingSpinner = false; // Stop spinner after processing
      }
      await this.sleep(500);
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "text/csv;charset=utf-8;" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName || "download.csv";
      link.click();
      window.URL.revokeObjectURL(url);
    },

    async viewReportDetail(fileName) {
      let err, result;
      this.isLoadingRecordReport = true;
      const payload = { NameFile: fileName };
      [err, result] = await this.$store
        .dispatch(types.GET_MIGRATION_REPORT_DETAIL, payload)
        .catch((err) => {});

      this.isLoadingRecordReport = false;

      if (result) {
        this.saveByteArray(fileName, result);
        this.$swal("Success!", "Report was successfully generated.", "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
  },
  computed: {
    recordCount() {
      return this.dataMigrationReportList?.length || 0;
    },
    ...mapState({
      dataMigrationReportList: (state) =>
        state.uploadDataSettings.dataMigrationReportList,
    }),
  },
  watch: {
    startDate(newValue, oldValue) {
      if (newValue && this.finalDate) {
        let startDate = new Date(newValue);
        let endDate = new Date(this.finalDate);
        let endDate30DaysAfterStartDate = new Date(startDate);

        if (
          endDate30DaysAfterStartDate &&
          endDate30DaysAfterStartDate != "Invalid Date"
        ) {
          endDate30DaysAfterStartDate.setDate(startDate.getDate() + 30);
          endDate30DaysAfterStartDate.setHours(0, 0, 0, 0);
        }
        if (endDate && endDate != "Invalid Date") {
          endDate.setHours(0, 0, 0, 0);
        }

        if (
          endDate30DaysAfterStartDate &&
          endDate30DaysAfterStartDate != "Invalid Date" &&
          endDate &&
          endDate != "Invalid Date" &&
          endDate > endDate30DaysAfterStartDate
        ) {
          this.finalDate = "";
        }
      }
    },
  },
  async mounted() {
    await Promise.all([
      await this.$store.dispatch(types.GET_SETTINGS_MIGRATION_REPORT),
      await this.$store.dispatch(types.GET_DATA_TEMPLATE_LIST),
    ]);

    const { processId } = this.$route.query;

    if (processId) {
      this.processIdFilter = processId;
      await this.filterReport();
    }
  },
};
</script>

<style lang="css" scoped>
.data-upload-log__table {
  overflow: auto;
}
.viewRecordDetail {
  font-size: 12px;
  color: #005aa4e2;
  cursor: pointer;
}
.viewRecordDetail:hover {
  color: #a3cff3;
}
</style>
